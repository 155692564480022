import fetchQuery, { getQuery } from "./fetchQuery";

const salesServiceEndpoints = {
  contacts: {
    getContact: (params) => `contacts/${params.id}`,
    insertContact: (params) => `contacts`,
  },
  objects: {
    search: (params) =>
      `objects/${params.entityId}/search?query=${params.query || ""}&limit=${
        params.limit || ""
      }`,
  },
  accounts: {
    searchAccount: (params) =>
      `accounts/search?query=${params.query || ""}&limit=${params.limit || ""}`,
  },
  users: {
    updateAgentPolicy: (params) =>
      `users/${params.agentId}/policy/${params.policyId}`,
    updateAgentRole: (params) =>
      `users/${params.agentId}/role/${params.roleId}`,
    getAgents: () => `users`,
    getUsers: () => `users`,
    getUser: (params) => `users/${params.id}`,
    insertUser: () => `users`,
    updateUser: (params) => `users/${params.id}`,
    deleteUser: (params) => `users/${params.id}`,
  },
  policies: {
    getPolicies: () => `policies`,
    getPolicyDefs: () => `policies/definitions`,
    getPolicy: (params) => `policies/${params.id}`,
    updatePolicyName: (params) => `policies/${params.id}`,
    updatePolicyPermissions: (params) => `policies/${params.id}`,
    deletePolicy: (params) => `policies/${params.id}`,
    insertPolicy: () => `policies`,
  },
  entities: {
    getEntity: (params) => `entities/${params.id}`,
    getEntities: () => `entities`,
    getFields: (params) =>
      `entities/${params.entityId}/fields?showArchived=${params.isArchived}`,
    insertEntity: () => `entities`,
    updateEntity: (params) => `entities/${params.id}`,
    deleteEntity: (params) => `entities/${params.id}`,
    insertField: (params) => `entities/${params.entityId}/fields`,
    updateField: (params) => `entities/fields/${params.id}`,
    deleteField: (params) => `entities/fields/${params.id}`,
  },
  recentItems: {
    insertRecentItem: () => `recentitems`,
  },
  roles: {
    getRoles: () => `roles`,
    getRoleList: () => `roles/list`,
    insertRole: () => `roles`,
    updateRole: (params) => `roles/${params.id}`,
    deleteRole: (params) =>
      `roles/${params.id}?transferId=${params.transferId}`,
  },
  groups: {
    getGroups: () => `groups`,
    insertGroup: () => `groups`,
    updateGroup: (params) => `groups/${params.id}`,
    deleteGroup: (params) => `groups/${params.id}`,
  },
  userSettings: {
    getSetting: (params) => `usersettings/${params.key}`,
    updateSetting: (params) => `usersettings`,
  },
  clientSettings: {
    getSetting: (params) => `clientsettings/${params.key}`,
    updateSetting: (params) => `clientsettings`,
  },
};

const salesService = {
  objects: {
    search: (params) => getQuery(salesServiceEndpoints.objects.search(params)),
  },
  accounts: {
    searchAccount: (params) =>
      getQuery(salesServiceEndpoints.accounts.searchAccount(params)),
  },
  contacts: {
    getContact: (params) =>
      getQuery(salesServiceEndpoints.contacts.getContact(params)),
    insertContact: (params) =>
      fetchQuery(
        salesServiceEndpoints.contacts.insertContact(params),
        params,
        "POST",
      ),
  },
  users: {
    getAgents: () => getQuery(salesServiceEndpoints.agents.getAgents()),
    updateAgentRole: (params) =>
      fetchQuery(
        salesServiceEndpoints.agents.updateAgentRole(params),
        null,
        "PATCH",
      ),
    updateAgentPolicy: (params) =>
      fetchQuery(
        salesServiceEndpoints.agents.updateAgentPolicy(params),
        null,
        "PATCH",
      ),
    getUsers: () => getQuery(salesServiceEndpoints.users.getUsers()),
    getUser: (params) => getQuery(salesServiceEndpoints.users.getUser(params)),
    insertUser: (params) =>
      fetchQuery(salesServiceEndpoints.users.insertUser(), params, "POST"),
    updateUser: (params) =>
      fetchQuery(salesServiceEndpoints.users.updateUser(params), params, "PUT"),
    deleteUser: (params) =>
      fetchQuery(
        salesServiceEndpoints.users.deleteUser(params),
        null,
        "DELETE",
      ),
  },
  policies: {
    getPolicies: () => getQuery(salesServiceEndpoints.policies.getPolicies()),
    getPolicy: (params) =>
      getQuery(salesServiceEndpoints.policies.getPolicy(params)),
    getPolicyDefs: () =>
      getQuery(salesServiceEndpoints.policies.getPolicyDefs()),
    updatePolicyName: (params) =>
      fetchQuery(
        salesServiceEndpoints.policies.updatePolicyName(params),
        params,
        "PATCH",
      ),
    updatePolicyPermissions: (params) =>
      fetchQuery(
        salesServiceEndpoints.policies.updatePolicyPermissions(params),
        params,
        "PATCH",
      ),
    deletePolicy: (params) =>
      fetchQuery(
        salesServiceEndpoints.policies.deletePolicy(params),
        null,
        "DELETE",
      ),
    insertPolicy: (params) =>
      fetchQuery(salesServiceEndpoints.policies.insertPolicy(), params, "POST"),
  },
  entities: {
    getEntities: () => getQuery(salesServiceEndpoints.entities.getEntities()),
    getEntity: (params) =>
      getQuery(salesServiceEndpoints.entities.getEntity(params)),
    getFields: (params) =>
      getQuery(salesServiceEndpoints.entities.getFields(params)),
    insertEntity: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.insertEntity(params),
        params,
        "POST",
      ),
    updateEntity: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.updateEntity(params),
        params,
        "PUT",
      ),
    deleteEntity: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.deleteEntity(params),
        null,
        "DELETE",
      ),
    insertField: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.insertField(params),
        params,
        "POST",
      ),
    updateField: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.updateField(params),
        params,
        "PUT",
      ),
    deleteField: (params) =>
      fetchQuery(
        salesServiceEndpoints.entities.deleteField(params),
        null,
        "DELETE",
      ),
  },
  recentItems: {
    insertRecentItem: (params) =>
      fetchQuery(
        salesServiceEndpoints.recentItems.insertRecentItem(),
        params,
        "POST",
      ),
  },
  roles: {
    getRoles: () => getQuery(salesServiceEndpoints.roles.getRoles()),
    getRoleList: () => getQuery(salesServiceEndpoints.roles.getRoleList()),
    insertRole: (params) =>
      fetchQuery(salesServiceEndpoints.roles.insertRole(), params, "POST"),
    updateRole: (params) =>
      fetchQuery(salesServiceEndpoints.roles.updateRole(params), params, "PUT"),
    deleteRole: (params) =>
      fetchQuery(
        salesServiceEndpoints.roles.deleteRole(params),
        params,
        "DELETE",
      ),
  },
  groups: {
    getGroups: () => getQuery(salesServiceEndpoints.groups.getGroups()),
    insertGroup: (params) =>
      fetchQuery(salesServiceEndpoints.groups.insertGroup(), params, "POST"),
    updateGroup: (params) =>
      fetchQuery(
        salesServiceEndpoints.groups.updateGroup(params),
        params,
        "PUT",
      ),
    deleteGroup: (params) =>
      fetchQuery(
        salesServiceEndpoints.groups.deleteGroup(params),
        params,
        "DELETE",
      ),
  },
  userSettings: {
    getSetting: (params) =>
      getQuery(salesServiceEndpoints.userSettings.getSetting(params)),
    updateSetting: (params) =>
      fetchQuery(
        salesServiceEndpoints.userSettings.updateSetting(params),
        params,
        "PUT",
      ),
  },
  clientSettings: {
    getSetting: (params) =>
      getQuery(salesServiceEndpoints.clientSettings.getSetting(params)),
    updateSetting: (params) =>
      fetchQuery(
        salesServiceEndpoints.clientSettings.updateSetting(params),
        params,
        "PUT",
      ),
  },
};

export default salesService;
