import { atomWithStore } from "jotai-zustand";
import { createStore } from "zustand/vanilla";

export const systemStore = createStore(() => ({
  fields: [],
  permissions: {},
  planType: 0,
  clientId: 0,
  userId: 0,
  globalUserId: 0,
}));

export const systemAtom = atomWithStore(systemStore);
