import React from "react";
import { Route, Routes } from "react-router-dom";
import Auth from "../auth/Auth";
import Page from "../layout/Page";
import RoutesLogin from "./RoutesLogin";
import SendConfirmEmail from "../auth/SendConfirmEmail";
import SetPassword from "../auth/SetPassword";
import AuthenticatedRoute from "./AuthenticatedRoute";
//https://reactrouter.com/en/main/upgrading/v6-data
const RoutesRoot = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <AuthenticatedRoute>
            <Page />
          </AuthenticatedRoute>
        }
      ></Route>
      <Route path="auth/*" element={<Auth />}>
        <Route path="login/*" element={<RoutesLogin />}></Route>
        <Route path="send-confirm-email/:userId" element={<SendConfirmEmail />}></Route>
        <Route path="set-password" element={<SetPassword />}></Route>
        <Route path="*" element={<RoutesLogin />}></Route>
      </Route>
      <Route
        path="*"
        element={
          <AuthenticatedRoute>
            <Page />
          </AuthenticatedRoute>
        }
      ></Route>
    </Routes>
  );
};
export default RoutesRoot;
