import React from "react";
import Select from "../../../ui/forms/Select";
import enums, { fieldTypeProps } from "../../../../enums";

const FieldSelect = ({ label = "Field Type", includeSection = false, value, onChange }) => {
  return (
    <Select label={label} value={value} onChange={v => onChange(parseInt(v))}>
      {Array.from(fieldTypeProps).map(([k, v]) => {
        return (
          (includeSection || k !== enums.fieldTypes.Section) && (
            <option value={k} key={k}>
              {v.title}
            </option>
          )
        );
      })}
    </Select>
  );
};

export default FieldSelect;
