import React, { useRef, useState } from "react";
import TextArea from "../forms/TextArea";
import Button from "../buttons/Button";
import Modal from "../../modals/Modal";

const ListBuilderModal = ({ onClose }) => {
  const [items, setItems] = useState([]);
  const el = useRef(null);

  const buttons = [
    <Button key="cancel" outline onClick={() => onClose()}>
      Cancel
    </Button>,
    <Button key="add" onClick={() => onClose(items)}>
      Add
    </Button>,
  ];

  return (
    <Modal icon="add_circle" iconColor="primary" title="Build Add Items" buttons={buttons} onCloseRequest={onClose}>
      <div className="flex flex-col h-full gap-4 overflow-hidden">
        <div className="overflow-hidden h-full">
          <div className="overflow-auto h-full flex flex-col gap-4 px-2 py-4 mx-2 my-2">
            <div>Seperate items on each line. You can also copy and paste your items.</div>
            <TextArea rows="10" value={items} onChange={value => setItems(value)} ref={el}></TextArea>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ListBuilderModal;
