import { useAtom } from "jotai";
import React from "react";
import { contactFilterAtom } from "../../../state/atoms";
import Select from "../../ui/forms/Select";
import Button from "../../ui/buttons/Button";

const ContactsSidebar = ({ onClose }) => {
  const [filter, setFilter] = useAtom(contactFilterAtom);

  // const handleAccountSelect = a => {
  //   let id = null;
  //   if (a?.id !== 0) id = a.id;
  //   setFilter(d => {
  //     d.accountId = id;
  //     d.accountName = a.name;
  //   });
  // };

  const handleSortChange = value => {
    setFilter(d => {
      d.sortBy = value;
    });
  };

  return (
    <div className="flex flex-col gap-4 p-8 w-full">
      <Select onChange={handleSortChange} label="Sort by" selected={filter.sortBy}>
        <option value="recent">Recently Modified</option>
        <option value="newest">Newest</option>
      </Select>

      {/* <ItemPicker
        label="Account"
        selectedItem={{ name: filter.accountName, id: filter.accountId }}
        onSelected={handleAccountSelect}
        stickyItems={[
          { name: "All accounts", id: 0 },
          { name: "No account", id: -1 },
        ]}
        url="accounts/search?query="
      ></ItemPicker> */}

      <div className="md:hidden mt-3 flex items-center justify-end">
        <Button onClick={() => onClose()}>Done</Button>
      </div>
    </div>
  );
};

export default ContactsSidebar;
//                {brands && brands.map(brand => (<option value={brand.id}>{brand.name}</option>) )}
