import React, { useEffect, useRef, useState } from "react";
import TextInput from "../forms/TextInput";
import LinkButton from "../buttons/LinkButton";
import { SortableList } from "../sortable-list/SortableList";
import ListItem from "./ListItem";
import Icon from "../Icon";
import ListBuilderModal from "./ListBuilderModal";

const ListBuilder = ({ items = [], onErrorChange, selected, onSelectionChanged, onChange, getNextId = () => 1, forceUnique = true }) => {
  const [errors, setErrors] = useState([]);

  const [addItemsOpen, setAddItemsOpen] = useState(false);
  const itemsRef = useRef([]);
  const newRef = useRef(null);
  const isNewRef = useRef(false);

  const doChange = items => {
    const errs = findDuplicates(getCopy(items));
    setErrors(errs);
    onChange && onChange(items);
  };

  const getCopy = (list = items) => JSON.parse(JSON.stringify(list));
  const getNewObject = (name, id) => {
    return { name, id };
  };

  const getNewUniqueId = arr => {
    if (!arr || arr.length < 1) return getNextId();
    let id = getNextId();
    let flag = false;
    const isIdMatch = () => arr.findIndex(i => i.id === id) > -1;
    while (!flag) {
      flag = !isIdMatch();
      !flag && id++;
    }
    return id;
  };

  const handleNewItemChange = value => {
    if (value) {
      isNewRef.current = true;
      let result = getCopy();
      result.push(getNewObject(value, getNewUniqueId(items)));
      doChange(result);
    }
  };

  useEffect(() => {
    if (isNewRef.current && items?.length > 0) {
      itemsRef.current[items.length - 1].focus();
      isNewRef.current = false;
    }
  }, [items]);

  useEffect(() => {
    onErrorChange && onErrorChange(errors.length);
  }, [onErrorChange, errors]);

  const handleItemsAddedClosed = text => {
    if (text) {
      let list = [...new Set(text.split("\n").filter(i => i && /\S/.test(i)))];
      let result = getCopy();

      list.forEach(i => result.push(getNewObject(i, getNewUniqueId(result))));
      doChange(result);
    }
    setAddItemsOpen(false);
  };

  const findDuplicates = arr => {
    if (!arr) return [];
    const getCount = name => {
      return arr.filter(i => i.name === name).length;
    };
    const result = arr.filter(i => getCount(i.name) > 1).map(i => i.name);
    return [...new Set(result)];
  };

  const isDupe = item => {
    if (!forceUnique) return false;
    let x = errors.find(i => i === item.name);
    if (x) return true;
    return false;
  };

  function onDragEnd(active, index, items) {
    if (!active) return;
    doChange(items);
  }

  const handleItemChange = (id, value) => {
    const index = items.findIndex(i => i.id === id);
    if (index > -1) {
      let result = getCopy();
      result[index].name = value;
      doChange(result);
    }
  };

  const handleDelete = id => {
    const index = items.findIndex(i => i.id === id);
    if (index < 0) return;
    if (selected?.id === items[index].id) {
      onSelectionChanged && onSelectionChanged(null);
    }
    let result = getCopy();
    result.splice(index, 1);
    doChange(result);
  };

  return (
    <div className="scroll-auto">
      <div>
        <div>
          <div className="p-1">
            <div className="flex items-center w-full mt-2">
              <div className="flex justify-start w-4 "></div>
              <div className="flex flex-col gap-2 pl-2 w-full border-l-4 border-opacity-0 border-primary-500">
                {items && (
                  <SortableList
                    items={items}
                    onChange={onDragEnd}
                    renderItem={(item, active, index) => (
                      <SortableList.Item id={item.id}>
                        <ListItem
                          item={item}
                          dragHandle={
                            <SortableList.DragHandle className={`flex cursor-grab mr-2`}>
                              <Icon icon="drag_indicator" size={18} />
                            </SortableList.DragHandle>
                          }
                          isDupe={isDupe(item)}
                          onDelete={handleDelete}
                          onChange={handleItemChange}
                          onSelectionChanged={() => onSelectionChanged && onSelectionChanged(item)}
                          isSelected={selected?.id === item.id}
                          onEnter={() => newRef.current.focus()}
                          ref={el => (itemsRef.current[index] = el)}></ListItem>
                      </SortableList.Item>
                    )}
                  />
                )}
                <div className="px-6">
                  <TextInput value="" placeholder="Add new item..." onChange={value => handleNewItemChange(value)} ref={newRef} />
                </div>
              </div>
              <div className="flex w-6 justify-end"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center pl-4 pt-2">
        <LinkButton onClick={() => setAddItemsOpen(true)}>Bulk Add</LinkButton>
      </div>

      {addItemsOpen && <ListBuilderModal isOpen={addItemsOpen} onClose={handleItemsAddedClosed}></ListBuilderModal>}
    </div>
  );
};

export default ListBuilder;
