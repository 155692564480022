import { atom } from "jotai";
import { atomWithImmer } from "jotai-immer";
import { splitAtom } from "jotai/utils";
import { STORAGE_KEY } from "../constants";

let tabs = [];
if (localStorage[STORAGE_KEY.TABS]) tabs = JSON.parse(localStorage[STORAGE_KEY.TABS]);
export const tabsAtom = atomWithImmer(tabs);

export const teamPageAtom = atomWithImmer({
  id: null,
  search: "",
  tab: "overview",
});

export const blockerAtom = atom("");
export const userPageAtom = atomWithImmer({ id: null, search: "" });
export const adminAtom = atomWithImmer({ selectedObjectTab: 0, selectedObject: {} });

export const contactsAtom = atom([]);
export const contactAtomsAtom = splitAtom(contactsAtom);
export const contactsTotalAtom = atom(0);
export const contactsSelectedCountAtom = atom(get => get(contactsAtom).filter(c => c.checked).length);

export const contactFilterAtom = atomWithImmer({
  search: "", //null or blank = ignore
  sortBy: "recent", //  recent, newest
  accountId: null, //0 = ignore, -1 = orphan
  accountName: "All Accounts",
});

export const organizationsAtom = atom([]);
export const organizationAtomsAtom = splitAtom(organizationsAtom);
export const organizationFilterAtom = atomWithImmer({
  search: "", //null or blank = ignore
  sortBy: "reset", // last (default), first, recent
  brandId: null, //null = ignore
});

export const ticketsAtom = atom([]);
export const ticketFilterAtom = atomWithImmer({
  search: "", //null or blank = ignore
  sortBy: "recent", // last (default), first, recent
  brandId: null, //null = ignore
});

export const teamTicketsAtom = atom([]);
export const teamTicketFilterAtom = atomWithImmer({
  search: "", //null or blank = ignore
  sortBy: "recent", // last (default), first, recent
  brandId: null, //null = ignore
  teamId: null,
});

export const policiesAtom = atomWithImmer([]);

export const modalError = atom("");

export const modalCreateContactAtom = atomWithImmer({
  visible: false,
  firstName: "",
  lastName: "",
  organziationId: 0,
});

//https://immerjs.github.io/immer/update-patterns/
