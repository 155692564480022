import React, { useState } from "react";
import Divider from "../../../../ui/Divider";
import Icon from "../../../../ui/Icon";
import Button from "../../../../ui/buttons/Button";
import { ReactComponent as NothingSelected } from "../../../../../svg/nothing-selected.svg";
import { useAtom } from "jotai";
import Panel from "../../../../ui/Panel";
import { systemAtom } from "../../../../../state/systemStore";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";
import { closeConfirm, showConfirm } from "../../../../../state/modalStore";
import UserModal from "./UserModal";

const UserDetail = ({ user, users }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [system] = useAtom(systemAtom);
  const deleteMutation = useServiceMutation(salesService.users.deleteUser, QUERY_KEY.USERS);
  const updateMutation = useServiceMutation(salesService.users.updateUser, QUERY_KEY.USERS);

  const handleToggleActivate = () => {
    const handleClose = v => {
      let payload = { ...user };
      payload.active = !user.active;
      if (v) updateMutation.mutate(payload);
      closeConfirm();
    };
    const params = {
      message: user.active ? `Are you sure you would like to deactivate ${user.firstName} ${user.lastName}?.` : `Are you sure you would like to activate ${user.firstName} ${user.lastName}?.`,
      title: user.active ? `Deactivate ${user.firstName} ${user.lastName}` : `Activate ${user.firstName} ${user.lastName}`,
      onClose: handleClose,
      affirmativeColor: "warn",
      iconColor: "primary",
    };
    showConfirm(params);
  };

  const handleDeleteUser = () => {
    const handleClose = v => {
      if (v) deleteMutation.mutate(user);
      closeConfirm();
    };
    const params = {
      message: `Are you sure you would like to delete ${user.firstName} ${user.lastName}?.`,
      title: `Delete ${user.firstName} ${user.lastName}`,
      icon: "delete",
      onClose: handleClose,
      affirmativeColor: "danger",
    };
    showConfirm(params);
  };
  //check if user is self
  //check if confirmed, reinvite

  return (
    <Panel>
      <div className="flex flex-col">
        {user && (
          <div className="flex flex-col px-4 w-full py-4">
            <Divider className="pb-4 px-4">
              <div className="flex items-center gap-4 flex-wrap">
                <Icon className=" text-primary-500  dark:text-secondary-500 dark:bg-opacity-50" icon="user" size={35}></Icon>
                <div className="flex flex-col grow">
                  <div className="flex text-2xl items-center">
                    {user.firstName} {user.lastName}
                  </div>
                </div>
                <div className="flex gap-3 flex-none flex-wrap">
                  {system.userId !== user.id && (
                    <React.Fragment>
                      <Button outline={true} color="danger" onClick={handleDeleteUser}>
                        Delete
                      </Button>
                      <Button outline={true} link color="warn" onClick={handleToggleActivate}>
                        {user.active ? "Deactivate" : "Activate"}
                      </Button>
                      {!user.lastLoginOn && (
                        <Button outline={true} link onClick={handleDeleteUser}>
                          Resend Invitation
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                  <Button onClick={() => setIsEditOpen(true)}>Edit</Button>
                </div>
              </div>
            </Divider>
            <div className="py-4">
              <Divider>Groups</Divider>
              <div>There are no groups to display</div>
            </div>
            <UserModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} user={user} users={users} />
          </div>
        )}
        {!user && (
          <div className="flex justify-center items-center">
            <NothingSelected className="w-1/2" title="No User is Selected" />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default UserDetail;
