import { useAtom } from "jotai";
import React from "react";
import { NavLink } from "react-router-dom";
import { teamPageAtom } from "../../../state/atoms";
import TextInput from "../../ui/forms/TextInput";

const TeamsSidebar = ({ teams }) => {
  const [teamPage, setTeamPage] = useAtom(teamPageAtom);

  const MenuItem = ({ to, children }) => {
    let activeClass = "dark:text-primary-500 text-primary-500 bg-opacity-20 dark:bg-opacity-20 bg-primary-500 dark:bg-primary-500 cursor-default ";
    let normalClass = "text-primary-900 text-opacity-70 hover:text-primary-500 dark:text-gray-500 dark:hover:text-primary-300 border-transparent ";
    let commonClass = "flex px-2 justify-start items-center border-2 border-transparent rounded-lg transition-all ease-in-out duration-150 leading-loose  ";
    return (
      <div className="">
        <NavLink end to={to} className={({ isActive }) => (isActive ? commonClass + activeClass : commonClass + normalClass)}>
          {children}
        </NavLink>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col overflow-hidden h-full relative bg-white dark:bg-gray-950 w-64 ">
        <div className="flex-none dark:text-gray-500 px-4 py-2">
          <div className="font-medium text-xl ">Teams</div>
          <div className="pr-4 my-4">
            <TextInput
              placeholder="Search for teams"
              value={teamPage.search}
              onChange={value =>
                setTeamPage(d => {
                  d.search = value;
                })
              }></TextInput>
          </div>
        </div>
        <div className="flex-1 overflow-auto px-4 text-sm">{teams && teams.filter(t => t.name.toLowerCase().indexOf(teamPage.search) > -1).map(t => <MenuItem to={`/teams/${t.id}`}>{t.name}</MenuItem>)}</div>
      </div>
    </React.Fragment>
  );
};

export default TeamsSidebar;

//https://dribbble.com/shots/16878702/attachments/11941813?mode=media
