import React, { useState } from "react";
import PageHeader from "./PageHeader";
import RoutesPages from "../routes/RoutesPages";
import Drawer from "../ui/Drawer";
import Sidebar from "./Sidebar";
import DrawerNav from "./DrawerNav";
import DrawerTabs from "./DrawerTabs";
import GlobalData from "../Global/GlobalData";
import useGetNavItems from "../../queries/useGetNavItems";

const Page = () => {
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const [drawerTabOpen, setDrawerTabOpen] = useState(false);
  const { navItems, isNavLoading } = useGetNavItems();

  const openMenu = (e) => {
    e.preventDefault();
    setDrawerMenuOpen(true);
  };

  const closeMenu = (e) => {
    setDrawerMenuOpen(false);
  };

  const openTabDrawer = (e) => {
    e.preventDefault();
    setDrawerTabOpen(true);
  };

  const closeTabDrawer = (e) => {
    setDrawerTabOpen(false);
  };

  // const navItems = [
  //   { path: "/", icon: "dashboard", title: "Dashboard", mobile: true },
  //   { path: "leads", icon: "lead", title: "leads", mobile: true },
  //   { path: "opportunities", icon: "oppo", title: "Opportunities", mobile: true },
  //   { path: "contacts", icon: "contact", title: "Contacts", mobile: true },
  //   { path: "accounts", icon: "account", title: "Accounts", mobile: true },
  //   { path: "teams", icon: "team", title: "Teams", mobile: true, childrenActive: true },
  //   { path: "users", icon: "user", title: "Users", mobile: true },
  //   { path: "reports", icon: "reports", title: "Reports", mobile: false },
  //   { path: "setup", icon: "admin", title: "Setup", mobile: false },
  // ];

  return (
    <GlobalData>
      <div className="flex flex-col h-full selection:bg-primary-500 dark:selection:bg-primary-500 selection:bg-opacity-50 dark:selection:bg-opacity-50">
        <Drawer onClose={closeMenu} opened={drawerMenuOpen}>
          <DrawerNav navItems={navItems} onClick={closeMenu} />
        </Drawer>
        <Drawer onClose={closeTabDrawer} opened={drawerTabOpen}>
          <DrawerTabs onClick={closeTabDrawer} />
        </Drawer>

        <div className="flex-none">
          <PageHeader onMenuClick={openMenu} onTabsClick={openTabDrawer} />
        </div>

        <div className="flex flex-1 overflow-hidden h-full">
          <nav className="lg:flex flex-none hidden sticky w-14 dark:bg-gray-950 bg-primary-800 ">
            <Sidebar navItems={navItems} />
          </nav>
          <div className="flex flex-1 overflow-hidden bg-gray-50  dark:bg-gray-950 ">
            <RoutesPages />
          </div>
        </div>
      </div>
    </GlobalData>
  );
};

export default Page;

// swipe https://codepen.io/swingthing/pen/ZBGBJb/
