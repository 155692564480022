import React, { useState } from "react";
import Divider from "../../../../ui/Divider";
import Button from "../../../../ui/buttons/Button";
import DeleteGroupModal from "./DeleteGroupModal";
import EditGroupModal from "./EditGroupModal";
import { ReactComponent as NothingSelected } from "../../../../../svg/nothing-selected.svg";
import HeadingIcon from "../../../../ui/HeadingIcon";
import Panel from "../../../../ui/Panel";

const GroupDetail = ({ group, groups }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <Panel>
      <div className="flex flex-col">
        {group && (
          <div className="flex flex-col px-4 w-full">
            <Divider className="py-4 px-4">
              <div className="flex items-center gap-4">
                <HeadingIcon icon="person_outline" />
                <div className="flex flex-col grow">
                  <div className="flex font-semibold items-center">{group.name}</div>
                  {group.description && <div className="flex text-sm items-center">{group.description}</div>}
                </div>
                <div className="flex gap-3 flex-none">
                  <Button outline={true} color="danger" onClick={() => setIsDeleteOpen(true)}>
                    Delete
                  </Button>
                  <Button onClick={() => setIsEditOpen(true)}>Edit</Button>
                </div>
              </div>
            </Divider>
            <div className="flex flex-col gap-2 px-4 py-4 w-full">
              <div className="flex w-full gap-8 justify-center pt-2">
                <div className="flex flex-col w-full">
                  <Divider>Users</Divider>
                </div>
                <div className="flex flex-col w-full">
                  <Divider>Groups</Divider>
                </div>
                <div className="flex flex-col w-full">
                  <Divider>Roles</Divider>
                </div>
              </div>
            </div>
            <EditGroupModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} group={group} groups={groups} />
            <DeleteGroupModal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} group={group} />
          </div>
        )}
        {!group && (
          <div className="flex justify-center items-center">
            <NothingSelected className="w-1/2" title="No Group is Selected" />
          </div>
        )}
      </div>
    </Panel>
  );
};

export default GroupDetail;
