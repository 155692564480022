import React, { useCallback, useEffect, useRef } from "react";
import Icon from "../ui/Icon";
import { createPortal } from "react-dom";

const OldModal = ({ children, isOpen, handleClose, title, icon, iconColor }) => {
  const nodeRef = useRef(null);
  const icoColor = iconColor || "primary";

  const closeModal = useCallback(
    res => {
      if (isOpen) {
        handleClose(res);
      }
    },
    [handleClose, isOpen]
  );

  useEffect(() => {
    const closeOnEscapeKey = e => (e.key === "Escape" ? closeModal(false) : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [closeModal]);

  //if (!isOpen) return null;
  return createPortal(
    <div className={"fixed inset-0  backdrop-blur-sm  bg-opacity-50 bg-gray-500 flex flex-col items-center justify-center transition-all lg:duration-300 ease-in-out z-20 " + (isOpen ? "opacity-100 pointer-events-auto " : " opacity-0 pointer-events-none")} ref={nodeRef}>
      <div className={"dark:bg-gray-800 flex flex-col h-full w-full lg:w-auto lg:h-auto lg:max-h-[95%] lg:max-w-[95%] px-4 py-4 pb-4 dark:shadow-gray-950 bg-gray-50  shadow-gray-950 lg:shadow-2xl lg:rounded-lg text-gray-800 dark:text-gray-400 lg:p-5 lg:transition-all lg:duration-300 ease-in-out " + (isOpen ? " scale-100 skew-y-0 skew-x-0 blur-none" : "  scale-50 skew-x-12 skew-y-12 blur-3xl")}>
        <div className="flex flex-col h-full ">
          {title && (
            <div className="text-lg font-semibold border-b border-gray-500 border-opacity-20 pb-2 mb-2 flex items-center pl-2 gap-4">
              {icon && <Icon size={30} className={`text-${icoColor}-500`} icon={icon} />}
              {title}
            </div>
          )}

          {isOpen &&
            React.cloneElement(children, {
              requestClose: res => closeModal(res),
            })}
        </div>
      </div>
    </div>,
    document.body
  );
};
export default OldModal;
