import React, { forwardRef, useEffect, useState } from "react";
import BaseInput from "./BaseInput";

const EmailInput = forwardRef(function ({ className, value = "", onChange, label, description, messages, ...rest }, ref) {
  const [innerMessages, setInnerMessages] = useState([]);

  const checkErrors = val => {
    let msgs = [];
    if (val) {
      const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
      if (isEmail) {
        //msgs.push({ message: "This email is valid.", type: "success" });
      } else {
        msgs.push({ message: "Invalid email.", type: "danger" });
      }
    }
    setInnerMessages(msgs);
    return msgs;
  };

  const handleChange = e => {
    let val = e.target?.value;
    const msgs = checkErrors(val);
    onChange && onChange(val, msgs.filter(m => m.type === "danger").length > 0, msgs);
  };

  useEffect(() => {
    checkErrors(value);
  }, [value]);

  return (
    <BaseInput className={className} label={label} description={description} messages={messages ? [...messages, ...innerMessages] : innerMessages} {...rest}>
      <input type="tel" {...rest} value={value} onChange={handleChange} ref={ref} />
    </BaseInput>
  );
});

export default EmailInput;
