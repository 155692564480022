import React, { useEffect, useState } from "react";
import enums from "../../../../enums";
import Checkbox from "../../../ui/forms/CheckBox";
import Spinner from "../../../ui/Spinner";
import Button from "../../../ui/buttons/Button";
import useGetFields from "../../../../queries/useGetFields";
import Divider from "../../../ui/Divider";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { QUERY_KEY } from "../../../../constants";
import Field from "./Field";
import FieldModal from "./FieldModal";
import Panel from "../../../ui/Panel";
import { SortableList } from "../../../ui/sortable-list/SortableList";
import Icon from "../../../ui/Icon";
import { closeConfirm, showConfirm } from "../../../../state/modalStore";

const Fields = ({ entity, entities }) => {
  const { data, loading } = useGetFields(entity.id, true, 0);
  const [fields, setFields] = useState(null);
  const mutation = useServiceMutation(salesService.entities.updateField, QUERY_KEY.ENTITIES);
  const deleteMutation = useServiceMutation(salesService.entities.deleteField, QUERY_KEY.ENTITIES);
  const [selected, setSelected] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  const [fieldModal, setFieldModal] = useState({ isOpen: false, field: null, isNew: true, isSection: false });

  useEffect(() => setFields(data), [data, setFields]);

  function onDragEnd(active, index, items) {
    if (!active) return;
    mutation.mutate({ id: active.id, pos: index });
    setFields(items);
  }

  const handleArchive = field => {
    const handleClose = v => {
      if (v) deleteMutation.mutate(field);
      closeConfirm();
    };
    const params = {
      message: field.trash ? `Are you sure you would like to unarchive ${field.label}?` : `Are you sure you would like to archive ${field.label}?  You will be able to unarchive later.`,
      title: field.trash ? `Unarchive ${field.label}` : `Archive ${field.label}`,
      onClose: handleClose,
      affirmativeColor: "danger",
    };
    showConfirm(params);
  };

  const openField = (isSection, field) => {
    setFieldModal({
      isOpen: true,
      field,
      isNew: field ? false : true,
      isSection: field ? field.fieldType === enums.fieldTypes.Section : isSection,
    });
  };

  const closeField = () => {
    setFieldModal({
      isOpen: false,
      field: null,
      isNew: true,
      isSection: false,
    });
  };

  const handleFieldSelect = f => {
    setSelected(prev => (f?.id === prev?.id ? null : f));
  };

  return (
    <div className="flex justify-center py-8 px-8 dark:pl-0 ">
      <Panel>
        <div className="flex flex-col">
          {loading && (
            <div className="flex items-center justify-center py-8 h-full">
              <div className="h-20 w-20">
                <Spinner></Spinner>
              </div>
            </div>
          )}
          {!loading && fields && (
            <div className=" px-4 py-4">
              <div className="flex flex-col">
                <Divider>
                  <div className="flex items-center gap-3 px-4 pb-2 w-full text-base">
                    <div className="flex items-center text-2xl">{enums.toText(enums.entities, entity)} Fields</div>
                    <div className="flex gap-2 justify-end items-center grow">
                      <div className="flex items-center text-sm font-thin pl-3">
                        <Checkbox value={showArchived} onChange={() => setShowArchived(!showArchived)}>
                          Show archived fields
                        </Checkbox>
                      </div>
                      <Button onClick={() => openField(true)}>Add a Section</Button>
                      <Button onClick={() => openField(false)}>Add a Field</Button>
                    </div>
                  </div>
                </Divider>

                {fields && (
                  <SortableList
                    items={fields}
                    className="flex flex-col justify-center items-center py-4 px-4 w-full"
                    onChange={onDragEnd}
                    renderItem={item => (
                      <SortableList.Item id={item.id} className="w-full flex">
                        <Field
                          field={item}
                          showArchived={showArchived}
                          isSelected={selected?.id === item.id}
                          onArchive={() => handleArchive(item)}
                          onEdit={f => openField(false, f)}
                          onSelect={() => handleFieldSelect(item)}
                          dragHandle={
                            !selected && (
                              <SortableList.DragHandle className={`flex items-center cursor-grab mr-2`}>
                                <Icon icon="drag_indicator" size={18} />
                              </SortableList.DragHandle>
                            )
                          }></Field>
                      </SortableList.Item>
                    )}></SortableList>
                )}
              </div>
            </div>
          )}
        </div>
        {fieldModal.isOpen && <FieldModal isOpen={fieldModal.isOpen} isSection={fieldModal.isSection} isNew={fieldModal.isNew} onClose={closeField} fields={data} field={fieldModal.field} entityId={entity.id} entities={entities} />}
      </Panel>
    </div>
  );
};

export default Fields;
