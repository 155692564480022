import React from "react";
import { fieldTypeProps } from "../../../../enums";

const FieldDetails = ({ field }) => {
  const Line = ({ children }) => <div className="flex items-center">{children}</div>;
  const Label = ({ children }) => <div className="flex pr-2">{children}</div>;
  const Value = ({ children }) => <div className="flex font-semibold">{children}</div>;

  return (
    <>
      {field && (
        <div className="flex flex-col gap-2">
          <Line>
            <Label>API Name:</Label>
            <Value>{field.name}</Value>
          </Line>
          <Line>
            <Label>Field Data Type:</Label>
            <Value>{fieldTypeProps.get(field.fieldType).title}</Value>
          </Line>
          <Line>
            <Label>Custom Field:</Label>
            <Value>{!field.isSystem ? "Yes" : "No"}</Value>
          </Line>
          <Line>
            <Label>Required:</Label>
            <Value>{field.isRequired ? "Yes" : "No"}</Value>
          </Line>
          <Line>
            <Label>Read Only:</Label>
            <Value>{field.isReadonly ? "Yes" : "No"}</Value>
          </Line>
          <Line>
            <Label>Placeholder:</Label>
            <Value>{field.placeholder || "None"}</Value>
          </Line>
          <Line>
            <Label>Description:</Label>
            <Value>{field.description || "None"}</Value>
          </Line>
          {/* {field && JSON.stringify(field)} */}
        </div>
      )}
    </>
  );
};

export default FieldDetails;
