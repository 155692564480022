import React, { useState } from "react";
import { showErrorMessage } from "../../../../../state/modalStore";
import fetchData from "../../../../../services/fetchData";
import Button from "../../../../ui/buttons/Button";

function DeletePolicyModal({ requestClose, policy }) {
  const [loading, setLoading] = useState(false);

  const handleClose = result => {
    setLoading(false);
    requestClose(result);
  };

  const handleDelete = () => {
    setLoading(true);
    fetchData(`policies/${policy.id}`, null, "DELETE")
      .then(() => handleClose(policy))
      .catch(() => {
        handleClose();
        showErrorMessage("There was an error deleting your policy.  Please make sure that it is not being used by an user.");
      });
  };

  return (
    <div className="flex flex-col h-full gap-4 overflow-hidden w-96">
      <div className="overflow-hidden h-full">
        <div className="overflow-auto h-full flex flex-col gap-4 px-2 py-2 mx-2 my-2">{policy && <div>Are you sure you would like to delete {policy.name}?</div>}</div>
      </div>

      <div className="flex items-center justify-center lg:justify-end m-4">
        <div className="flex-none">
          <Button color="primary" outline={true} onClick={() => handleClose()}>
            Cancel
          </Button>
        </div>
        <div className="flex-none pl-4">
          <Button loading={loading} loadingText="Deleting..." color="danger" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
}
export default DeletePolicyModal;
