import React from "react";
import ConfirmModal from "./ConfirmModal";
import { COLOR_TYPE } from "../../constants";

const DeleteModal = ({ isOpen, onClose, children, title }) => {
  return (
    <ConfirmModal isOpen={isOpen} title={title} affirmativeColor={COLOR_TYPE.DANGER} negativeColor={COLOR_TYPE.SECONDARY} icon="delete" iconColor={COLOR_TYPE.DANGER} onClose={onClose} loadingText="Deleting..." affirmativeText="Delete" negativeText="Cancel">
      {children}
    </ConfirmModal>
  );
};

export default DeleteModal;
