import React, { useState } from "react";
import { Outlet, Route, Routes, useNavigate, Navigate } from "react-router-dom";
import Accounts from "../auth/login/Accounts";
import Email from "../auth/login/Email";
import ForgotPassword from "../auth/login/ForgotPassword";
import Inactive from "../auth/login/Inactive";
import New2faPhone from "../auth/login/New2faPhone";
import Password from "../auth/login/Password";
import Verify2fa from "../auth/login/Verify2fa";
import { STORAGE_KEY } from "../../constants";

const Login = () => (
  <React.Fragment>
    <Outlet />
  </React.Fragment>
);

const RoutesLogin = () => {
  let navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState(null);
  const [response, setResponse] = useState(null);

  const handleGetAccounts = a => {
    setAccounts(a);
    if (a.length === 1) {
      setAccount(a[0]);
      if (!checkConfirm(a[0])) {
        navigate(`/auth/send-confirm-email/${a[0].userId}`);
        return;
      }
      navigate(`/auth/login/password`);
    } else {
      navigate(`/auth/login/accounts`);
    }
  };

  const handleGetAccount = a => {
    setAccount(a);
    if (!checkConfirm(a)) {
      navigate(`/auth/send-confirm-email/${a.userId}`);
      return;
    }
    navigate(`/auth/login/password`);
  };

  const checkConfirm = a => a.confirmed && !a.isNew;

  const handleResult = data => {
    if (data) {
      setResponse(data);
      switch (data.result) {
        case 0:
          localStorage.setItem(STORAGE_KEY.ACCESSTOKEN, data.accessToken);
          localStorage.setItem(STORAGE_KEY.DATACENTER, data.dataCenter);
          navigate(`/`);
          break;
        case 2:
          navigate(`/auth/login/verify-code`);
          break;
        case 4:
          navigate(`/auth/login/inactive`);
          break;
        case 5:
          navigate(`/auth/login/setup-phone`);
          break;
        default:
      }
    }
  };

  return (
    <Routes>
      <Route path="" element={<Login />}>
        <Route index element={<Email accountsHandler={handleGetAccounts} />}></Route>
        <Route path="accounts" element={accounts && accounts.length > 0 ? <Accounts accounts={accounts} accountHandler={handleGetAccount} /> : <Navigate to="/auth/login" replace />}></Route>
        <Route path="password" element={account ? <Password resultHandler={handleResult} account={account} /> : <Navigate to="/auth/login" replace />}></Route>
        <Route path="inactive" element={<Inactive />}></Route>
        <Route path="verify-code" element={response ? <Verify2fa response={response} resultHandler={handleResult} /> : <Navigate to="/auth/login" replace />}></Route>
        <Route path="setup-phone" element={response ? <New2faPhone response={response} resultHandler={handleResult} /> : <Navigate to="/auth/login" replace />}></Route>
        <Route path="forgot-password" element={<ForgotPassword />}></Route>
        <Route path="*" element={<Navigate to="/auth/login" replace />}></Route>
      </Route>
    </Routes>
  );
};
export default RoutesLogin;
