import React from "react";
import { useAtom } from "jotai";
import { tabsAtom } from "../../state/atoms";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Icon from "../ui/Icon";

const PageTab = ({ to, main, hide, iconName, exactMatch, ...rest }) => {
  const [, setTabs] = useAtom(tabsAtom);
  const location = useLocation();
  const navigate = useNavigate();

  const removeTab = (e, path) => {
    e.preventDefault();

    setTabs(d => {
      const index = d.findIndex(t => t.path === path);
      if (index !== -1) {
        d.splice(index, 1);
        if (location.pathname === path) navigate("/");
      }
    });
  };

  return (
    <NavLink end={exactMatch} to={to} className="tab max-w-[14rem] text-sm">
      {({ isActive }) => (
        <div className={`group flex items-center py-2 h-full transition-colors ease-in-out duration-400 border-b border-gray-300 dark:border-opacity-0 ${isActive ? "border-b-4 border-b-primary-500 dark:border-b-primary-500 cursor-default dark:text-opacity-80 dark:border-opacity-60 dark:text-primary-500 text-primary-700 " : "dark:hover:text-gray-50 hover:text-primary-500   "} ${hide && "hidden"}`}>
          <div className={`${isActive && "mt-[3px]"} items-center  flex h-full px-2 border-r border-opacity-30 dark:border-opacity-50 border-gray-500 dark:border-gray-500 flex-1 w-40 ${main && " justify-center ml-2"}`}>
            {iconName && (
              <div className="mr-2 w-4 h-4 flex flex-none items-center">
                <Icon icon={iconName} size={20}></Icon>
              </div>
            )}
            <div style={{ maxWidth: "12rem" }} className="overflow-hidden whitespace-nowrap text-ellipsis grow ">
              {rest.children}
            </div>
            {!main && (
              <div className="w-5 h-5 ml-1 items-center justify-center hidden group-hover:flex text-gray-500 hover:text-primary-500 dark:hover:text-gray-300  hover:cursor-pointer rounded-full hover:bg-primary-200  dark:hover:bg-gray-500 dark:hover:bg-opacity-30  flex-none " onClick={e => removeTab(e, to)}>
                <Icon className="flex-none " icon="clear" size={16}></Icon>
              </div>
            )}
          </div>
        </div>
      )}
    </NavLink>
  );
};

export default PageTab;
