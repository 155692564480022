import React, { useCallback, useEffect } from "react";
import Select from "./Select";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";

const NO_VALUE = -1;
const valuesAtom = atomWithImmer([NO_VALUE, NO_VALUE, NO_VALUE]);
const errorsAtom = atomWithImmer([null, null, null]);

const DependentSelect = ({ field, onChange }) => {
  const [values, setValues] = useAtom(valuesAtom);
  const [errors, setErrors] = useAtom(errorsAtom);

  const handleValueChange = (v, i) => {
    setValues(d => {
      d[i] = v;
      while (i < 2) d[++i] = NO_VALUE;
    });
  };

  const getLevelItems = useCallback(
    (index, v) => {
      let result = null;
      switch (index) {
        case 0:
          result = field?.data?.items;
          break;
        case 1:
          result = field?.data?.items?.find(i => i.id === v[0])?.items;
          break;

        default:
          result = field?.data?.items?.find(i => i.id === v[0])?.items?.find(i => i.id === v[1])?.items;
          break;
      }
      return result?.length > 0 ? result : null;
    },
    [field]
  );

  useEffect(() => {
    setValues(d => [NO_VALUE, NO_VALUE, NO_VALUE]);
    setErrors(d => []);
  }, [field, setErrors, setValues]);

  useEffect(() => {
    let hasErrors = false;
    const errMsg = ["Please select a value"];
    if (field.required) {
      setErrors(d => {
        d[0] = values[0] === NO_VALUE ? errMsg : null;
        d[1] = values[1] === NO_VALUE && getLevelItems(1, values) ? errMsg : null;
        d[2] = values[2] === NO_VALUE && getLevelItems(2, values) ? errMsg : null;
        hasErrors = d[0] || d[1] || d[2];
      });
    }

    onChange && onChange(values, hasErrors);
  }, [values, onChange, setErrors, field.required, getLevelItems]);

  const getSelect = (index, label) => {
    let items = getLevelItems(index, values);
    return (
      <Select required={field.required && items} disabled={(index > 0 && values[index - 1] === NO_VALUE) || !items} label={label} value={values[index]} onChange={value => handleValueChange(parseInt(value), index)} danger={errors[index]}>
        <option className="hidden" />
        {items &&
          items.map(i => (
            <option key={i.id} value={i.id}>
              {i.name}
            </option>
          ))}
      </Select>
    );
  };

  return (
    <React.Fragment>
      <div className="">{getSelect(0, field.label)}</div>
      <div className="">{getSelect(1, field.data.label2)}</div>
      <div className="">{getSelect(2, field.data.label3)}</div>
    </React.Fragment>
  );
};

export default DependentSelect;
