import React, { useState } from "react";
import ItemPicker from "../ui/ItemPicker";
import useSearchAccount from "../../queries/useSearchAccount";
import { useDebounce } from "use-debounce";

const AccountPicker = ({ label, value, onChange, placeholder = "Pick an account.", required, description, messages, ...rest }) => {
  const [query, setQuery] = useState("");
  const [debounceQuery] = useDebounce(query, 250);
  const { isLoading, data, isError } = useSearchAccount(debounceQuery, 43);
  return (
    <React.Fragment>
      <ItemPicker pickerItems={data} label={label || "Account"} value={value} labelKey="name" idKey="id" placeholder={placeholder} description={description} messages={messages} isLoading={isLoading} required={required} isError={isError} onQueryChange={q => setQuery(q)} onChange={onChange} {...rest} />
    </React.Fragment>
  );
};

export default AccountPicker;
