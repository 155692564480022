import React, { useEffect, useState } from "react";
import useModalSaveCancel from "../../../../modals/useModalSaveCancel";
import Modal from "../../../../modals/Modal";
import TextInput from "../../../../ui/forms/TextInput";
import Select from "../../../../ui/forms/Select";
import Checkbox from "../../../../ui/forms/CheckBox";
import TextArea from "../../../../ui/forms/TextArea";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";

const dataAtom = atomWithImmer({ id: -1, name: "", parentId: null, isPeerShare: false, description: "" });
const EditRoleModal = ({ isOpen, onClose, role, nodes, roles }) => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useState([]);
  const mutation = useServiceMutation(salesService.roles.updateRole, QUERY_KEY.ROLES);

  const handleSave = setSaving => {
    if (data?.name?.length < 1) {
      setErrors(["Please enter a name for the role."]);
      return;
    }
    const dupe = roles.find(r => r.name.toLowerCase() === data.name.toLowerCase() && r.id !== role.id);
    if (dupe) {
      setErrors(["There is already a role by the name of " + data.name]);
      return;
    }
    setErrors([]);

    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
    };
    mutation.mutate(data, { onSettled: handleSaved });
  };
  const handleCancel = () => {
    onClose && onClose();
  };

  useEffect(() => {
    setData(d => {
      d.parentId = role.parentId;
      d.name = role.name;
      d.description = role.description;
      d.isPeerShare = role.isPeerShare;
      d.id = role.id;
    });
  }, [role, setData]);

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);
  const getRoles = () => {
    const treeToList = (l, n) => {
      if (n.id === role.id) return;
      const { nodes, ...o } = n;
      l.push(o);
      n.nodes.forEach(node => treeToList(l, node));
    };
    let ar = [];
    treeToList(ar, nodes);
    return ar;
  };

  return (
    <React.Fragment>
      {role && (
        <Modal isOpen={isOpen} icon="create" iconColor="primary" title={"Edit " + role.name} buttons={buttons} onCloseRequest={handleCancel} wClass="lg:max-w-lg">
          <div className="flex px-4 py-4 w-full ">
            <form className="flex w-full flex-col gap-5" autoComplete="off">
              <TextInput
                label="Name"
                value={data.name}
                messages={errors}
                onChange={e =>
                  setData(d => {
                    d.name = e;
                  })
                }></TextInput>
              {role.parentId && (
                <Select
                  label="Reports To"
                  value={data.parentId}
                  onChange={v =>
                    setData(d => {
                      d.parentId = v;
                    })
                  }>
                  {nodes &&
                    getRoles().map(r => (
                      <option key={r.id} value={r.id}>
                        {r.name}
                      </option>
                    ))}
                </Select>
              )}
              <Checkbox
                value={data.isPeerShare}
                onChange={v =>
                  setData(d => {
                    d.isPeerShare = v;
                  })
                }>
                Share data with peers
              </Checkbox>
              <TextArea
                label="Description"
                value={data.description}
                onChange={v =>
                  setData(d => {
                    d.description = v;
                  })
                }></TextArea>
            </form>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default EditRoleModal;
