import React, { useCallback } from "react";
import EmailLink from "../../ui/anchors/EmailLink";
import PhoneLink from "../../ui/anchors/PhoneLink";
import Avatar from "../../ui/Avatar";
import ContactLink from "../../tabLinks/ContactLink";
import OrganizationLink from "../../tabLinks/OrganizationLink";
import { useImmerAtom, withImmer } from "jotai-immer";
import { useSetAtom } from "jotai";
import { contactsAtom } from "../../../state/atoms";
import { COLOR_TYPE } from "../../../constants";

const ContactsListItem = ({ contactAtom }) => {
  const [contact] = useImmerAtom(contactAtom);
  const setContacts = useSetAtom(withImmer(contactsAtom));

  const handleSelect = useCallback(
    (e, id, value) => {
      if (e.shiftKey && window.lastContactSelectedId && window.lastContactSelectedId !== id) {
        let flag = false;
        setContacts(d => {
          for (let i = 0; i < d.length; i++) {
            const c = d[i];
            if (c.id === id || c.id === window.lastContactSelectedId) {
              if (flag) {
                c.checked = value;
                break;
              } else {
                flag = true;
                c.checked = value;
              }
            } else if (flag) {
              c.checked = value;
            }
          }
        });
      } else {
        setContacts(d => {
          const c = d.find(c => c.id === id);
          c.checked = value;
        });
      }
      window.lastContactSelectedId = id;
    },
    [setContacts]
  );

  return (
    <div className={"grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 mt-3 dark:border rounded-md px-3 py-1 snap-start shadow " + (contact.checked ? " bg-primary-500 border-primary-500 bg-opacity-10 border-opacity-30 dark:bg-primary-500 dark:border-primary-700 dark:bg-opacity-10 dark:border-opacity-40" : " bg-white dark:bg-gray-800   border-gray-300  dark:border-gray-700")}>
      <div className="flex items-center flex-nowrap col-span-2 ">
        <div className="flex">
          <Avatar firstName={contact.firstName} lastName={contact.lastName} checked={contact.checked} checkColor={COLOR_TYPE.TERTIARY} itemId={contact.id} onClick={handleSelect}></Avatar>
        </div>
        <div className="flex flex-col overflow-hidden ml-4">
          <div className="font-semibold py-1">
            <ContactLink id={contact.id} name={contact.firstName + " " + contact.lastName}></ContactLink>
          </div>
          <div className="font-thin py-1">
            <OrganizationLink name={contact.organizationName} id={contact.organizationId}></OrganizationLink>
          </div>
          <div className="md:hidden py-1 items-center overflow-hidden">
            <EmailLink email={contact.email}></EmailLink>
          </div>
          <div className="md:hidden py-1 items-center overflow-hidden">
            {contact.mobilePhone && <PhoneLink number={contact.mobilePhone}>{contact.mobilePhone} (Mobile)</PhoneLink>}
            {contact.workPhone && <PhoneLink number={contact.workPhone}>{contact.workPhone} (Work)</PhoneLink>}
          </div>
        </div>
      </div>
      <div className="hidden xl:flex items-center overflow-hidden">{contact.title}</div>
      <div className="hidden lg:flex items-center overflow-hidden">
        <EmailLink email={contact.email}></EmailLink>
      </div>
      <div className="hidden 2xl:flex items-center overflow-hidden flex-col">
        {contact.mobilePhone && <PhoneLink number={contact.mobilePhone}>{contact.mobilePhone} (Mobile)</PhoneLink>}
        {contact.workPhone && <PhoneLink number={contact.workPhone}>{contact.workPhone} (Work)</PhoneLink>}
      </div>
    </div>
  );
};

export default ContactsListItem;
