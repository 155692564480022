import React from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";

const Admin = () => (
  <React.Fragment>
    <div className="flex overflow-hidden bg-white dark:bg-gray-800 h-full relative w-full">
      <div className="flex flex-none h-full relative">
        <AdminSidebar />
      </div>
      <div className="flex-1 overflow-hidden h-full relative">
        <Outlet />
      </div>
    </div>
  </React.Fragment>
);

export default Admin;

//https://dribbble.com/shots/16878702/attachments/11941813?mode=media
