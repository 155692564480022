import React from "react";
import { Navigate } from "react-router-dom";
import { STORAGE_KEY } from "../../constants";

const AuthenticatedRoute = ({ user, children }) => {
  const token = localStorage.getItem(STORAGE_KEY.ACCESSTOKEN);
  return !token ? <Navigate to="/auth" replace /> : children;
};

export default AuthenticatedRoute;
