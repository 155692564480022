import React from "react";
import Button from "../ui/buttons/Button";
import Modal from "./Modal";

const AlertModal = ({ isOpen, onClose, children, title, buttonText = "Ok", buttonColor = "primary", icon = "info", iconColor = "primary" }) => {
  const buttons = [
    <Button key={buttonText} color={buttonColor} onClick={onClose}>
      {buttonText}
    </Button>,
  ];

  return (
    <Modal isOpen={isOpen} zIndex="z-50" icon={icon} iconColor={iconColor} title={title} buttons={buttons} onCloseRequest={onClose} wClass="lg:max-w-lg">
      <div className="flex px-4 py-4 w-full ">{children}</div>
    </Modal>
  );
};

export default AlertModal;
