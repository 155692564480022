import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../ui/Icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/ToolTip";

const Sidebar = ({ navItems }) => {
  const MenuItem = ({ to, icon, title, matchExact = true }) => {
    return (
      <li className="flex w-14 pb-3 pt-1 items-center justify-center  ">
        <NavLink
          end={matchExact}
          to={to}
          className={({ isActive }) => `flex justify-center border-2 rounded-lg transition-all ease-in-out duration-500 
          ${isActive ? "dark:text-primary-500 dark:text-opacity-80 dark:border-opacity-60  text-primary-50 border-primary-200 dark:border-primary-500 cursor-default" : "text-primary-100 hover:text-primary-300 hover:bg-black hover:bg-opacity-20 dark:hover:bg-gray-950 dark:text-gray-300 dark:hover:text-primary-300 border-transparent hover:scale-125x"}`}>
          <Tooltip placement="right">
            <TooltipTrigger onClick={() => {}}>
              <div className="p-1">
                <Icon icon={icon} size={24} />
              </div>
            </TooltipTrigger>
            <TooltipContent>{title}</TooltipContent>
          </Tooltip>
        </NavLink>
      </li>
    );
  };
  return (
    <ul>
      {navItems.map(item => (
        <MenuItem to={item.path} icon={item.icon} key={item.title} title={item.title} matchExact={!item.childrenActive} />
      ))}
    </ul>
  );
};

export default Sidebar;
