import React, { forwardRef, useState } from "react";
import BaseInput from "./BaseInput";

const NumberInput = forwardRef(function ({ className, onChange, value = "", min, max, isDecimal, label, description, messages, ...rest }, ref) {
  const [innerMessages, setInnerMessages] = useState([]);

  const handleChange = e => {
    let msgs = [];
    let value = e.target?.value;
    value = value.replace(isDecimal ? /[^\d.-]/gm : /[^\d-]/gm, "");
    if (value) {
      const isNumber = isDecimal ? /^-?\d*.?\d+$/gm.test(value) : /^-?\d*$/gm.test(value);

      if (!isNumber) msgs.push({ message: "Invalid number.", type: "danger" });
      else {
        if (min && Number(value) <= Number(min)) msgs.push({ message: "Number needs to be above " + min, type: "danger" });
        if (max && Number(value) >= Number(max)) msgs.push({ message: "Number needs to be below " + max, type: "danger" });
      }
      setInnerMessages(msgs);
      if (isDecimal) value = isNumber ? parseFloat(value) : value;
      else value = isNumber ? parseInt(value) : value;
    }
    onChange && onChange(value, msgs.filter(m => m.type === "danger").length > 0, msgs);
  };

  return (
    <BaseInput className={className} label={label} description={description} messages={messages ? [...messages, ...innerMessages] : innerMessages} {...rest}>
      <input type="text" {...rest} value={value} onChange={handleChange} ref={ref} />
    </BaseInput>
  );
});

export default NumberInput;
