import React from "react";
import { Outlet } from "react-router-dom";
import Logo from "../ui/Logo";

const Auth = () => (
  <React.Fragment>
    <div className="my-0 mx-auto flex flex-col items-center justify-center dark:bg-gray-950 bg-gray-50  h-full  w-full overflow-auto">
      <div className="w-full  shadow bg-white dark:bg-gray-800 dark:text-gray-100 rounded-lg py-8 px-8 lg:max-w-md">
        <div className="flex items-center w-full justify-center pb-8">
          <div className="w-32">
            <Logo></Logo>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  </React.Fragment>
);

export default Auth;
