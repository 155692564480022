import React, { useEffect, useState } from "react";
import ThemePicker from "../../../ui/theme/ThemePicker";
import { defaultTheme, setPageTheme } from "../../../ui/theme/theme";
import useGetClientSetting from "../../../../queries/useGetClientSetting";
import { CLIENT_SETTINGS, QUERY_KEY } from "../../../../constants";
import PageSpinner from "../../../ui/PageSpinner";
import { atomWithImmer } from "jotai-immer";
import { useAtom, useSetAtom } from "jotai";
import Header from "../../../ui/Header";
import Button from "../../../ui/buttons/Button";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { blockerAtom } from "../../../../state/atoms";
import Panel from "../../../ui/Panel";

const themeAtom = atomWithImmer(defaultTheme);

const DefaultTheme = () => {
  const [theme, setTheme] = useAtom(themeAtom);
  const { data, isLoading } = useGetClientSetting(
    CLIENT_SETTINGS.DEFAULT_THEME,
  );
  const [isDirty, setIsDirty] = useState(false);
  const setBlocker = useSetAtom(blockerAtom);
  const mutation = useServiceMutation(
    salesService.clientSettings.updateSetting,
    [QUERY_KEY.CLIENTSETTINGS, CLIENT_SETTINGS.DEFAULT_THEME],
  );

  const handleChange = (k, v) => {
    setTheme((d) => {
      d[k] = v;
    });
    setIsDirty(true);
  };

  useEffect(() => {
    setBlocker(
      isDirty
        ? "You have unsaved changes.  Are you sure you would like to leave this page?"
        : "",
    );
  }, [isDirty, setBlocker]);

  const handleSave = () => {
    mutation.mutate({ key: CLIENT_SETTINGS.DEFAULT_THEME, value: theme });
    setIsDirty(false);
  };

  const handleCancel = () => {
    setTheme((d) => data || defaultTheme);
    setIsDirty(false);
  };

  useEffect(() => {
    setPageTheme(theme);
    return () => {
      setPageTheme();
    };
  }, [theme]);

  useEffect(() => {
    setTheme((d) => data || defaultTheme);
  }, [data, setTheme]);

  return (
    <div className="flex flex-col h-full bg-gray-200 dark:bg-gray-800">
      <div className="">
        <Header>
          <div className="flex">
            <div className="flex grow">Default Theme</div>
            {isDirty && (
              <div className="flex gap-4 flex-none font-normal text-base">
                <Button outline onClick={handleCancel}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
              </div>
            )}
          </div>
        </Header>
      </div>

      {isLoading && <PageSpinner />}
      {!isLoading && (
        <div className="grow h-full overflow-auto p-8">
          <Panel>
            <div className="p-8">
              <ThemePicker theme={theme} onChange={handleChange}></ThemePicker>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default DefaultTheme;
