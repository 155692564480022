import React, { useEffect, useState } from "react";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import enums from "../../enums";
import AccountPicker from "../pickers/AccountPicker";
import TextInput from "../ui/forms/TextInput";
import NumberInput from "../ui/forms/NumberInput";
import UrlInput from "../ui/forms/UrlInput";
import DateInput from "../ui/forms/DateInput";
import TimeInput from "../ui/forms/TimeInput";
import EmailInput from "../ui/forms/EmailInput";
import PhoneInput from "../ui/forms/PhoneInput";
import Checkbox from "../ui/forms/CheckBox";
import Select from "../ui/forms/Select";
import DependentSelect from "../ui/forms/DependentSelect";
import TextArea from "../ui/forms/TextArea";
import Spinner from "../ui/Spinner";
import RolePicker from "../pickers/RolePicker";
import useGetFields from "../../queries/useGetFields";

const dataAtom = atomWithImmer({});
const errorsAtom = atomWithImmer({});

function NewObjectForm({ initialData, isNew, onChange, entity, showRequiredErrors = false }) {
  const [data, setData] = useAtom(dataAtom);
  const [result, setResult] = useState({});
  const [errors, setErrors] = useAtom(errorsAtom);
  const { data: fields, isFetched: isFieldsFetched } = useGetFields(entity.id);

  const handleChange = (f, val, hasError) => {
    const value = val?.target?.value || val;
    if (f.required && !value) hasError = true;
    if (hasError && !errors[f.name]) {
      setErrors(d => {
        d[f.name] = true;
      });
    }
    if (!hasError && errors[f.name]) {
      setErrors(d => {
        delete d[f.name];
      });
    }
    setData(d => {
      d[f.name] = value;
    });
  };

  const getMessages = f => {
    if (showRequiredErrors && f.required && !data[f.fieldName]) return ["This field is required."];
    else return [];
  };

  useEffect(() => {
    setData({});
    if (!fields) return;
    setErrors(d => {
      fields.schema.forEach(f => {
        if (f.required && f.type !== enums.fieldTypes.Section) d[f.fieldName] = true;
      });
    });
  }, [fields, setErrors, setData]);

  useEffect(() => {
    onChange && onChange(result, Object.keys(errors).length > 0);
  }, [result, errors, onChange]);

  useEffect(() => {
    if (!fields?.schema) return;
    let data = { fields: {} };
    const snakeToCamel = str => str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));
    fields.schema.forEach(f => {
      if (f.isSystem) {
        data[snakeToCamel(f.fieldName)] = setData[f.fieldName];
      } else {
        data.fields[f.fieldName] = setData[f.fieldName];
      }
    });
    setResult(data);
  }, [setResult, setData, fields]);

  const getField = f => {
    const onChange = (val, errs) => handleChange(f, val, errs);
    let commonProps = { key: f.name, required: f.required, description: f.description, name: f.name, placeholder: f.placeholder, label: f.label, value: data[f.fieldName], onChange, messages: getMessages(f) };
    let result = null;

    switch (f.type) {
      case enums.fieldTypes.Text:
        result = <TextInput {...commonProps}></TextInput>;
        break;
      case enums.fieldTypes.Number:
        result = <NumberInput min={f.data.min} max={f.data.max} isDecimal={f.data.isDecimals} {...commonProps} />;
        break;
      case enums.fieldTypes.Url:
        result = <UrlInput requireSecure={f.data.requireSecure} {...commonProps} />;
        break;
      case enums.fieldTypes.Date:
        result = <DateInput {...commonProps}></DateInput>;
        break;
      case enums.fieldTypes.Time:
        result = <TimeInput {...commonProps}></TimeInput>;
        break;
      case enums.fieldTypes.Email:
        result = <EmailInput {...commonProps} />;
        break;
      case enums.fieldTypes.Phone:
        result = <PhoneInput {...commonProps} />;
        break;
      case enums.fieldTypes.Checkbox:
        result = (
          <div className="col-span-full flex justify-start  items-center">
            <Checkbox value={f.data.defaultValue}>{f.label}</Checkbox>
          </div>
        );
        break;
      case enums.fieldTypes.DropDown:
        result = (
          <Select {...commonProps}>
            <option>{f.placeholder || "--"}</option>
            {f.data?.items && f.data.items.map(i => <option key={i.id}>{i.label}</option>)}
          </Select>
        );
        break;
      case enums.fieldTypes.DependantField:
        result = (
          <div key={f.fieldName} className="grid col-span-full gap-4 lg:grid-flow-dense grid-cols-1  md:grid-cols-3 ">
            <DependentSelect onChange={val => handleChange(f, val)} field={f} />
          </div>
        );
        break;
      case enums.fieldTypes.Memo:
        result = (
          <div key={f.fieldName} className="lg:col-span-2 row-span-2">
            <TextArea {...commonProps} autoGrow={true}></TextArea>
          </div>
        );
        break;
      case enums.fieldTypes.Lookup:
        switch (f.data.lookUpType) {
          case "account":
            return <AccountPicker {...commonProps}></AccountPicker>;
          case "role":
            return <RolePicker {...commonProps}></RolePicker>;
          default:
            return (
              <div>
                No field available for "{enums.toText(enums.fieldTypes, f.type)}" Data: {f.data && JSON.stringify(f.data)}
              </div>
            );
        }
      default:
        return (
          <div>
            No field available for "{enums.toText(enums.fieldTypes, f.type)}" Data: {f.data && JSON.stringify(f.data)}
          </div>
        );
    }

    return result;
  };

  const getFields = flds => {
    if (flds.length < 1) return <div>No Fields to Display.</div>;
    let sections = [];

    flds.forEach(f => {
      if (f.type === enums.fieldTypes.Section) {
        sections.push({ label: f.fieldName, items: [] });
      } else {
        if (sections.length < 1) sections.push({ label: "", items: [] });
        let s = sections[sections.length - 1];
        s.items.push(f);
      }
    });

    const getSection = section => {
      return (
        <div className="w-full flex  flex-col" key={section.label}>
          {section.label && <div className="text-2xl font-thin border-gray-400 dark:border-gray-700 border-b py-2 my-2">{section.label}</div>}
          <div className="grid w-full lg:grid-flow-dense grid-cols-1  md:grid-cols-2 gap-4 px-1 py-2 box-border">{section.items.map(f => getField(f))}</div>
        </div>
      );
    };

    return <div className="w-full flex  flex-col">{sections.map(s => getSection(s))}</div>;
  };

  return (
    <React.Fragment>
      {!isFieldsFetched && (
        <div className="flex items-center justify-center py-8">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {fields && isFieldsFetched && <form autoComplete="off">{getFields(fields.schema)}</form>}
    </React.Fragment>
  );
}
export default NewObjectForm;
