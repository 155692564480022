import React from "react";
import { NavLink } from "react-router-dom";
import Icon from "../ui/Icon";

const DrawerNav = ({ navItems, onClick }) => {
  const DrawerItem = ({ to, icon, title }) => {
    let activeClass = "text-primary-500 dark:text-primary-500";
    let normalClass = "text-gray-600 dark:text-gray-300";
    let commonClass = "flex justify-center ";

    return (
      <li className="flex h-12 items-center text-gray-500 dark:text-gray-500">
        <NavLink to={to} className={({ isActive }) => (isActive ? commonClass + activeClass : commonClass + normalClass)}>
          <div className="flex justify-center items-center" onClick={onClick}>
            <div className="flex-none">
              <Icon icon={icon} size={24} />
            </div>
            <div className="flex-auto pl-5">{title}</div>
          </div>
        </NavLink>
      </li>
    );
  };

  return (
    <ul className="m-6">
      {navItems.map(item => (
        <DrawerItem to={item.path} icon={item.icon} title={item.title} key={item.title}></DrawerItem>
      ))}
    </ul>
  );
};

export default DrawerNav;
