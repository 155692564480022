import React from "react";
import Avatar from "../../ui/Avatar";

const Accounts = ({ accounts, accountHandler }) => {
  return (
    <div className="flex flex-col ">
      <div className="text-lg pb-4">Please choose your account</div>
      {accounts &&
        accounts.map(a => (
          <div className="flex items-center border dark:border-gray-600 dark:hover:bg-gray-950 hover:cursor-pointer rounded-md my-2 p-3" onClick={() => accountHandler(a)} key={a.accountId}>
            <div className="pr-4">
              <Avatar firstName={a.name}></Avatar>
            </div>
            <div>{a.name}</div>
          </div>
        ))}
    </div>
  );
};

export default Accounts;
