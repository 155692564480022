import { useAtom } from "jotai";
import React, { useState } from "react";
import { contactsAtom } from "../../../state/atoms";
import fetchData from "../../../services/fetchData";
import DeleteModalOld from "../../modals/DeleteModalOld";

function DeleteConctactsModal({ requestClose }) {
  const [contacts] = useAtom(contactsAtom);
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    const payload = contacts.filter(c => c.checked).map(c => c.id);
    fetchData("contacts", payload, "DELETE")
      .then(() => requestClose(true))
      .catch(() => {
        requestClose(false);
        alert("There was an error deleting your contacts");
      })
      .finally(() => setLoading(false));
  };

  return (
    <DeleteModalOld onDelete={handleDelete} loading={loading} title="The following contacts will be deleted:" requestClose={() => requestClose(false)}>
      <div className="overflow-auto flex flex-col ">
        {contacts
          .filter(c => c.checked)
          .map(c => (
            <div className="leading-loose" key={c.id}>
              {c.firstName} {c.lastName}
            </div>
          ))}
      </div>
    </DeleteModalOld>
  );
}
export default DeleteConctactsModal;
