import React, { forwardRef } from "react";
import BaseInput from "./BaseInput";

const TextInput = forwardRef(function ({ className, placeholder, onChange, value = "", label, description, messages, ...rest }, ref) {
  const handleChange = e => {
    onChange && onChange(e.target.value);
  };

  return (
    <BaseInput className={className} type="text" label={label} description={description} messages={messages} {...rest}>
      <input onChange={handleChange} placeholder={placeholder} value={value} {...rest} ref={ref} />
    </BaseInput>
  );
});

export default TextInput;
