import React from "react";
import { teamPageAtom } from "../../../state/atoms";
import { useAtom } from "jotai";
import Tabs, { Tab } from "../../ui/Tabs";

const Team = ({ team }) => {
  const [teamPage, setTeamPage] = useAtom(teamPageAtom);

  return (
    <React.Fragment>
      {team && (
        <div className="flex flex-col overflow-hidden h-full relative bg-white dark:bg-gray-950">
          <div className="bg-white dark:bg-gray-950 px-2">
            <div className="text-xl font-medium pt-4 pb-2 dark:text-gray-500 text-gray-500">{team.name}</div>
            <div className="pb-4">
              <Tabs
                onChange={value =>
                  setTeamPage(d => {
                    d.tab = value;
                  })
                }
                selected={teamPage.tab}>
                <Tab value="overview">Overview</Tab>
                <Tab value="properties"> Properties</Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}
      {!team && <div>No team selected</div>}
    </React.Fragment>
  );
};

export default Team;
