import React, { useEffect, useState } from "react";
import Spinner from "../../../../ui/Spinner";
import Header from "../../../../ui/Header";
import GroupDetail from "./GroupDetail";
import useGetGroups from "../../../../../queries/useGetGroups";
import SelectableItem from "../../../../ui/SelectableItem";
import Button from "../../../../ui/buttons/Button";
import NewGroupModal from "./NewGroupModal";
import { focusAtom } from "jotai-optics";
import { adminAtom } from "../../../../../state/atoms";
import { useAtom } from "jotai";

const selectedAtom = focusAtom(adminAtom, (o) => o.prop("selectedGroup"));

const Groups = () => {
  const { data, isLoading } = useGetGroups();
  const [selected, setSelected] = useAtom(selectedAtom);
  const [isNewOpen, setIsNewOpen] = useState(false);

  useEffect(() => {
    setSelected((prev) => {
      if (data?.length > 0) {
        if (!prev) return data[0];
        const group = data.find((g) => g.id === prev.id);
        if (!group) return data[0];
        return group;
      }
      return null;
    });
  }, [data, setSelected]);

  return (
    <div className="h-full">
      <Header>
        <div className="flex">
          <div className="flex grow">Groups</div>
          <div className="flex flex-none text-base">
            <Button onClick={() => setIsNewOpen(true)}>Create Group</Button>
          </div>
        </div>
      </Header>
      {isLoading && (
        <div className="flex items-center justify-center py-8 h-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!isLoading && data && (
        <div className="flex h-full">
          <div className="flex h-full border-r dark:border-opacity-0 border-gray-300 shadow-lg p-4 overflow-auto">
            {!data ||
              (data.length < 1 && (
                <div className="flex flex-col gap-5">
                  You do not have any groups yet.
                  <Button onClick={() => setIsNewOpen(true)}>
                    Create a Group
                  </Button>
                </div>
              ))}
            <div className="flex flex-col gap-2 min-w-[12rem]">
              {data?.length > 0 &&
                data.map((g) => (
                  <SelectableItem
                    selected={selected && selected.id === g.id}
                    onClick={() => setSelected(g)}
                    key={g.id}
                  >
                    {g.name}
                  </SelectableItem>
                ))}
            </div>
          </div>
          <div className="bg-gray-200 dark:bg-gray-800 grow h-full overflow-auto">
            <div className="flex justify-center py-8 px-8 dark:pl-0">
              {((data.length > 0 && selected) || data.length < 1) && (
                <GroupDetail group={selected} groups={data}></GroupDetail>
              )}
            </div>
          </div>
        </div>
      )}

      <NewGroupModal
        isOpen={isNewOpen}
        onClose={() => setIsNewOpen(false)}
        groups={data}
      />
    </div>
  );
};

export default Groups;
