import React from "react";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { QUERY_KEY } from "../../../../constants";
import DeleteModal from "../../../modals/DeleteModal";

const DeleteObjectModal = ({ isOpen, onClose, entity }) => {
  const mutation = useServiceMutation(salesService.entities.deleteEntity, QUERY_KEY.ENTITIES);

  const handleDelete = result => {
    if (!result) {
      onClose();
      return;
    }
    result.setIsLoading(true);
    const handleDeleted = () => {
      result.setIsLoading(false);
      onClose && onClose();
    };
    mutation.mutate(entity, { onSettled: handleDeleted });
  };

  return (
    <React.Fragment>
      {entity && (
        <DeleteModal isOpen={isOpen} onClose={handleDelete} title={"Delete " + entity.name}>
          <div className="flex px-4 py-4 w-full ">Are you sure you would like to delete the object "{entity.name}"?</div>
        </DeleteModal>
      )}
    </React.Fragment>
  );
};

export default DeleteObjectModal;
