import React from "react";

const SelectableItem = ({ children, selected, className, ...rest }) => {
  return (
    <div className="flex">
      <div className={`${className} flex items-center py-1 -my-1 px-2 rounded-md dark:bg-opacity-20 bg-opacity-20 hover:cursor-pointer transition-all ease-in-out duration-150 select-none  hover:text-primary-500  dark:hover:text-primary-300 border-transparent ${selected && "bg-primary-500 text-primary-600 dark:text-primary-500 dark:bg-primary-500"}`} {...rest}>
        {children}
      </div>
    </div>
  );
};

export default SelectableItem;
