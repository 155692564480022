import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY, STALETIME } from "../constants";
import salesService from "../services/salesService";

const fetch = async params => {
  return await salesService.entities.getFields(params);
};

export default function useGetFields(entityId, isArchived = false, staleTime = STALETIME.LONG) {
  const queryKey = [QUERY_KEY.ENTITIES, entityId, isArchived];
  const queryFn = () => fetch({ entityId, isArchived });

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime,
  });
}
