import React from "react";
import { NavLink } from "react-router-dom";
import SelectableItem from "../../ui/SelectableItem";

const AdminSidebar = () => {
  const MenuItem = ({ to, children }) => {
    return (
      <div className="flex">
        <NavLink end to={to} className="w-full">
          {({ isActive }) => (
            <SelectableItem selected={isActive}>{children}</SelectableItem>
          )}
        </NavLink>
      </div>
    );
  };

  const MenuSection = ({ children, title }) => (
    <React.Fragment>
      <div className="font-mono font-thin text-primary-900 text-opacity-70 dark:text-gray-500  mt-2 ">
        {title}
      </div>
      <div className="font-sans pl-4 flex flex-col gap-2 ">{children}</div>
    </React.Fragment>
  );

  return (
    <div className="flex min-w-[12rem] flex-col gap-2 py-4 px-4 w-full bg-gray-100 dark:bg-gray-900 overflow-auto nice-scrollbar border-r border-gray-300 dark:border-opacity-0">
      <MenuSection title="SECURITY & DATA">
        <MenuItem to="users">Users</MenuItem>
        <MenuItem to="groups">Groups</MenuItem>
        <MenuItem to="roles">Roles</MenuItem>
        <MenuItem to="y">Data Sharing</MenuItem>
        <MenuItem to="profiles">Profiles</MenuItem>
        <MenuItem to="permissions">Permissions</MenuItem>
        <MenuItem to="objects">Objects</MenuItem>
        <MenuItem to="z">Licenses</MenuItem>
      </MenuSection>
      <MenuSection title="APPEARANCE">
        <MenuItem to="layouts">Layouts</MenuItem>
        <MenuItem to="order">Nav Item Order</MenuItem>
        <MenuItem to="theme">Default Theme</MenuItem>
      </MenuSection>
    </div>
  );
};

export default AdminSidebar;
//                {brands && brands.map(brand => (<option value={brand.id}>{brand.name}</option>) )}
