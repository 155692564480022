import React from "react";
import useGetEntities from "../../../../../queries/useGetEntities";
import ObjectModal from "../../../../forms/ObjectModal";
import enums from "../../../../../enums";

const UserModal = ({ user, isNew, isOpen, onClose }) => {
  const { data: entities } = useGetEntities();
  return (
    <>
      {entities && (
        <ObjectModal
          isOpen={isOpen}
          onClose={onClose}
          isNew={isNew}
          initalData={user}
          entityId={
            entities.find((e) => e.entityType === enums.entityTypes.User).id
          }
        />
      )}
    </>
  );
};

export default UserModal;
