import React from "react";
import { Route, Routes } from "react-router-dom";
import Admin from "../pages/admin/Admin";
import Contacts from "../pages/contacts/Contacts";
import Contact from "../pages/contacts/Contact";
import Dashboard from "../pages/dashboard/Dashboard";
import Reports from "../pages/reports/Reports";
import Report from "../pages/reports/Report";
import Users from "../pages/users/Users";
import Teams from "../pages/teams/Teams";
import Policies from "../pages/admin/security/policies/Policies";
import AdminNotFound from "../pages/admin/AdminNotFound";
import Roles from "../pages/admin/security/roles/Roles";
import Groups from "../pages/admin/security/groups/Groups";
import UsersAdmin from "../pages/admin/security/users/Users";
import Objects from "../pages/admin/objects/Objects";
import Layouts from "../pages/admin/appearance/Layouts";
import NavItemOrder from "../pages/admin/appearance/NavItemOrder";
import DefaultTheme from "../pages/admin/appearance/DefaultTheme";

const RoutesPages = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/contacts" element={<Contacts />}></Route>
      <Route path="/contacts/:id" element={<Contact />}></Route>
      <Route path="/teams" element={<Teams />}></Route>
      <Route path="/teams/:id" element={<Teams />}></Route>
      <Route path="/users" element={<Users />}></Route>
      <Route path="/users/:id" element={<Users />}></Route>
      <Route path="/reports" element={<Reports />}></Route>
      <Route path="/reports/:id" element={<Report />}></Route>
      <Route path="/setup/*" element={<Admin />}>
        <Route path="profiles" element={<Policies />} />
        <Route path="roles" element={<Roles />} />
        <Route path="groups" element={<Groups />} />
        <Route path="users" element={<UsersAdmin />} />
        <Route path="objects" element={<Objects />}></Route>
        <Route path="layouts" element={<Layouts />}></Route>
        <Route path="order" element={<NavItemOrder />}></Route>
        <Route path="theme" element={<DefaultTheme />}></Route>
        <Route path="*" element={<AdminNotFound />}></Route>
      </Route>
      <Route path="*" element={<Dashboard />}></Route>
    </Routes>
  );
};

export default RoutesPages;

/*



*/
