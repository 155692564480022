import React, { useState } from "react";
import { showErrorMessage } from "../../state/modalStore";
import Button from "../ui/buttons/Button";
import ObjectForm from "./ObjectForm";
import Modal from "../modals/Modal";
import {
  useInsertMutation,
  useUpdateMutation,
} from "../../mutations/useObjectMutation";
import useGetEntity from "../../queries/useGetEntity";

const ObjectModal = ({ initalData, entityId, isNew, isOpen, onClose }) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showRequiredErrors, setShowRequiredErrors] = useState(false);
  const { data: entity } = useGetEntity(entityId);
  const insertMutation = useInsertMutation(entity);
  const updateMutation = useUpdateMutation(entity, initalData);

  const handleSave = () => {
    setShowRequiredErrors(true);
    if (errors) return;
    setIsSaving(true);
    const handleSaved = () => {
      setIsSaving(false);
      onClose && onClose();
    };
    const handleError = () => {
      setIsSaving(false);
      showErrorMessage("There was an error saving the user.");
    };
    isNew
      ? insertMutation.mutate(data, {
          onError: handleError,
          onSuccess: handleSaved,
        })
      : updateMutation.mutate(data, {
          onError: handleError,
          onSuccess: handleSaved,
        });
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const handleDataChange = (formData, hasErrors) => {
    setData(formData);
    setErrors(hasErrors);
  };

  const buttons = [
    <Button
      key="cancel"
      disabled={isSaving}
      outline={true}
      onClick={handleCancel}
    >
      Cancel
    </Button>,
    <Button
      key="save"
      color={errors && showRequiredErrors ? "danger" : "success"}
      loading={isSaving}
      loadingText="Saving..."
      onClick={handleSave}
    >
      Save
    </Button>,
  ];

  return (
    <React.Fragment>
      {entity && (
        <Modal
          isOpen={isOpen}
          icon={entity.iconName}
          iconColor="primary"
          title={`Create a New ${entity.name}`}
          buttons={buttons}
          onCloseRequest={handleCancel}
        >
          <div className="px-4 py-4x w-full ">
            <ObjectForm
              initialData={initalData}
              isNew={isNew}
              showRequiredErrors={showRequiredErrors}
              onChange={handleDataChange}
              entity={entity}
            ></ObjectForm>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ObjectModal;
