import React from "react";

const Inactive = () => (
  <React.Fragment>
    <div className="py-8">
      <div className="text-lg pb-5">Deactivated Account</div>
      <div>Your account has been deactivated. Please contact your system administrator, or HighFive Crm support.</div>
    </div>
  </React.Fragment>
);

export default Inactive;
