import { useAtom } from "jotai";
import React from "react";
import { NavLink } from "react-router-dom";
import { tabsAtom } from "../../state/atoms";
import Icon from "../ui/Icon";

const DrawerTabs = ({ onClick }) => {
  const [tabs, setTabs] = useAtom(tabsAtom);

  const removeTab = (e, path) => {
    e.preventDefault();
    setTabs(d => {
      const index = d.findIndex(t => t.path === path);
      if (index !== -1) d.splice(index, 1);
    });
  };

  const DrawerItem = ({ to, icon, title }) => {
    let activeClass = "text-primary-500 dark:text-primary-500  overflow-hidden";
    let normalClass = "text-gray-600 dark:text-gray-300 overflow-hidden";

    return (
      <li className="mt-4 hover:animate-pulse hover:text-gray-800 hover:dark:text-white text-gray-500 dark:text-gray-500">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start overflow-hidden">
            <NavLink to={to} className={({ isActive }) => (isActive ? activeClass : normalClass)} onClick={onClick}>
              <div className="flex overflow-hidden">
                <div className="flex-none mr-2">
                  <Icon icon={icon} size={24} />
                </div>
                <div className="text-ellipsis overflow-hidden whitespace-nowrap">{title}</div>
              </div>
            </NavLink>
          </div>
          <div className="flex-none ml-2" onClick={e => removeTab(e, to)}>
            <Icon icon="clear" size={16}></Icon>
          </div>
        </div>
      </li>
    );
  };

  return (
    <ul className="mx-4 my-6">
      {tabs.map(t => (
        <DrawerItem to={t.path} icon={t.iconName} title={t.name} key={t.name}></DrawerItem>
      ))}
    </ul>
  );
};

export default DrawerTabs;
