import React, { useEffect, useState } from "react";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import enums from "../../enums";
import AccountPicker from "../pickers/AccountPicker";
import TextInput from "../ui/forms/TextInput";
import NumberInput from "../ui/forms/NumberInput";
import UrlInput from "../ui/forms/UrlInput";
import DateInput from "../ui/forms/DateInput";
import TimeInput from "../ui/forms/TimeInput";
import EmailInput from "../ui/forms/EmailInput";
import PhoneInput from "../ui/forms/PhoneInput";
import Checkbox from "../ui/forms/CheckBox";
import Select from "../ui/forms/Select";
import DependentSelect from "../ui/forms/DependentSelect";
import TextArea from "../ui/forms/TextArea";
import Spinner from "../ui/Spinner";
import RolePicker from "../pickers/RolePicker";
import useGetFields from "../../queries/useGetFields";

const dataAtom = atomWithImmer({});
const errorsAtom = atomWithImmer({});

function ObjectForm({ initialData, isNew, onChange, entity, showRequiredErrors = false }) {
  const [data, setData] = useAtom(dataAtom);
  const [result, setResult] = useState({});
  const [errors, setErrors] = useAtom(errorsAtom);
  const { data: fields, isFetched: isFieldsFetched } = useGetFields(entity.id);

  const handleChange = (f, val, hasError) => {
    const value = val?.target?.value || val;
    
    if (f.isRequired && !value) hasError = true;
    if (hasError && !errors[f.name]) {
      setErrors(d => {
        d[f.name] = true;
      });
    }
    if (!hasError && errors[f.name]) {
      setErrors(d => {
        delete d[f.name];
      });
    }
    setData(d => {
      d[f.name] = value;
    });
  };

  useEffect(() => {
    setData(d => {
      if (!initialData) return {}
      let x = JSON.parse(JSON.stringify(initialData));
      let f = x.fields || {}
      delete x.fields
      return { ...x, ...f}
    });
  }, [initialData, setData, isNew]);

  const getMessages = f => {
    if (showRequiredErrors && f.isRequired && !data[f.name]) return ["This field is required."];
    else return [];
  };

  useEffect(() => {
    if (!fields) return;
    let result = {}
    fields.forEach(f => {
      if (f.isRequired && f.fieldType !== enums.fieldTypes.Section && !data[f.name]) result[f.name] = true;
    });
    setErrors(result);

  }, [fields, data, setErrors]);

  useEffect(() => {
    onChange && onChange(result, Object.keys(errors).length > 0);
  }, [result, fields, errors, onChange]);

  useEffect(() => {
    if (!fields) return;
    let res = { fields: {} };
    fields.forEach(f => {
      if (f.fieldType !== enums.fieldTypes.Section) {
        if (f.isSystem) {
          res[f.name] = data[f.name];
        } else {
          res.fields[f.name] = data[f.name];
        }
      }
    });
    res.id = data.id;
    setResult(res);
  }, [setResult, data, fields]);

  const getField = f => {
    const onChange = (val, errs) => handleChange(f, val, errs);
    let commonProps = { key: f.id, required: f.isRequired, description: f.description, name: f.name, placeholder: f.placeholder, label: f.label, value: data[f.name], onChange, messages: getMessages(f) };
    let result = null;

    switch (f.fieldType) {
      case enums.fieldTypes.Text:
        result = <TextInput {...commonProps}></TextInput>;
        break;
      case enums.fieldTypes.Number:
        result = <NumberInput min={f.data.min} max={f.data.max} isDecimal={f.data.isDecimal} {...commonProps} />;
        break;
      case enums.fieldTypes.Url:
        result = <UrlInput requireSecure={f.data.requireSecure} {...commonProps} />;
        break;
      case enums.fieldTypes.Date:
        result = <DateInput {...commonProps}></DateInput>;
        break;
      case enums.fieldTypes.Time:
        result = <TimeInput {...commonProps}></TimeInput>;
        break;
      case enums.fieldTypes.Email:
        result = <EmailInput {...commonProps} />;
        break;
      case enums.fieldTypes.Phone:
        result = <PhoneInput {...commonProps} />;
        break;
      case enums.fieldTypes.Checkbox:
        result = (
          <div key={f.id} className="col-span-full flex justify-start  items-center">
            <Checkbox {...commonProps} value={data[f.name] === undefined && isNew ? f.data.defaultValue : data[f.name]}>
              {f.label}
            </Checkbox>
          </div>
        );
        break;
      case enums.fieldTypes.DropDown:
        result = (
          <Select {...commonProps}>
            <option className="hidden">{f.placeholder && f.placeholder}</option>
            {f.data?.items &&
              f.data.items.map(i => (
                <option key={i.id} value={i.id}>
                  {i.name}
                </option>
              ))}
          </Select>
        );
        break;
      case enums.fieldTypes.DependantField:
        result = (
          <div key={f.id} className="grid col-span-full gap-4 lg:grid-flow-dense grid-cols-1  md:grid-cols-3 ">
            <DependentSelect onChange={val => handleChange(f, val)} field={f} />
          </div>
        );
        break;
      case enums.fieldTypes.Memo:
        result = (
          <div key={f.id} className="lg:col-span-2 row-span-2">
            <TextArea {...commonProps} autoGrow={true}></TextArea>
          </div>
        );
        break;
      case enums.fieldTypes.Lookup:
        if (!f.data.entityType) {
        } else {
          switch (f.data.entityType) {
            case "account":
              result = <AccountPicker {...commonProps}></AccountPicker>;
              break;
            case enums.entityTypes.Role:
              result = <RolePicker {...commonProps}></RolePicker>;
              break;
            default:
              result = (
                <div key={f.id}>
                  No field available for "{enums.toText(enums.fieldTypes, f.fieldType)}" Data: {f.data && JSON.stringify(f.data)}
                </div>
              );
              break;
          }
        }
        break;
      default:
        result = (
          <div key={f.id}>
            No field available for "{enums.toText(enums.fieldTypes, f.fieldType)}" Data: {f.data && JSON.stringify(f.data)}
          </div>
        );
        break;
    }

    return result;
  };

  const getFields = flds => {
    if (flds.length < 1) return <div>No Fields to Display.</div>;
    let sections = [];

    flds.forEach(f => {
      if (f.fieldType === enums.fieldTypes.Section) {
        sections.push({ id: f.id, label: f.label, items: [] });
      } else {
        if (sections.length < 1) sections.push({ id: -1, label: "", items: [] });
        let s = sections[sections.length - 1];
        s.items.push(f);
      }
    });

    const getSection = section => {
      return (
        <div className="w-full flex  flex-col" key={"section" + section.id + section.label}>
          {section.label && <div className="text-2xl font-thin border-gray-400 dark:border-gray-700 border-b py-2 my-2">{section.label}</div>}
          <div className="grid w-full lg:grid-flow-dense grid-cols-1  md:grid-cols-2 gap-4 px-1 py-2 box-border">{section.items.map(f => getField(f))}</div>
        </div>
      );
    };

    return <div className="w-full flex  flex-col">{sections.map(s => getSection(s))}</div>;
  };

  return (
    <React.Fragment>
      {!isFieldsFetched && (
        <div className="flex items-center justify-center py-8">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {fields && isFieldsFetched && <form autoComplete="off">{getFields(fields)}</form>}
    </React.Fragment>
  );
}
export default ObjectForm;
