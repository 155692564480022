import React from "react";
import Button from "../ui/buttons/Button";

function DeleteModalOld({ requestClose, children, onDelete, loading }) {
  const handleDelete = () => {
    if (onDelete) onDelete();
  };

  return (
    <React.Fragment>
      <div className="lg:overflow-auto">{children}</div>
      <div className="flex items-center justify-center lg:justify-end my-4 mx-4">
        <div className="flex-none">
          <Button color="danger" outline={true} onClick={() => requestClose(false)}>
            Cancel
          </Button>
        </div>
        <div className="flex-none pl-4">
          <Button loading={loading} loadingText="Deleting..." color="danger" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DeleteModalOld;
