import React, { useEffect, useState } from "react";
import Button from "../../ui/buttons/Button";
import PhoneInput from "../../ui/forms/PhoneInput";
import fetchData from "../../../services/fetchData";

const New2faPhone = ({ response, resultHandler }) => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const clickHandler = e => {
    e.preventDefault();
    const payload = {
      userId: response.userId,
      token: response.accessToken,
      phone: phone,
    };
    setLoading(true);
    fetchData(`https://${process.env.REACT_APP_AUTH_URL}/auth/update-phone`, payload)
      .then(res => setData(res.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (data) resultHandler(data);
  }, [data, resultHandler]);

  return (
    <React.Fragment>
      <div className="pb-3 text-2xl items-center w-full text-center">Mobile phone number</div>
      <div className="pb-8 text items-center w-full text-center">Your account now requires two factor authentication. Please enter your mobile phone number to receive login verification codes.</div>
      <div className="pb-8 w-full">
        <PhoneInput className="py-3" autoFocus placeholder="Enter mobile phone number" value={phone} onChange={value => setPhone(value)}></PhoneInput>
      </div>
      <div className="flex justify-end items-center">
        <div>
          <Button loading={loading} loadingText="Saving..." onClick={clickHandler}>
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default New2faPhone;
