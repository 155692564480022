import React from 'react';

const Logo = ({ cls, height, width }) => {
    let h = height || null;
    let w = width || null;
    return (
        <svg className={cls} width={w} height={h} viewBox={"0 0 1024 1024"}>
            <path fill="#3B82F6" d="M516.125,129.154l152.48,13.594L490.066,489.937L516.125,129.154z" />
            <path fill="#22C55E" d="M174.746,190.042L298.423,85.006l160.041,412.105L174.746,190.042z" />
            <path fill="#DC2626" d="M153.042,564.997l30.374-150.121l250.901,109.12L153.042,564.997z" />
            <path fill="#EAB308" d="M138.206,781.189l-62.251-68.038L437.321,558.91L138.206,781.189z" />
            <path fill="#3B82F6" d="M508.364,949.231l-190.028-30.138l151.957-340.821L508.364,949.231z" />
            <path fill="#22C55E" d="M931.062,882.538L789.95,955.409L503.244,572.095L931.062,882.538z" />
            <path fill="#EAB308" d="M862.076,518.827l-26.697,150.122L520.085,544.997L862.076,518.827z" />
            <path fill="#DC2626" d="M738.868,265.633l65.717,95.566l-284.5,144.449L738.868,265.633z" />        
            </svg>
    )
};

export default Logo;