import React from "react";
import Button from "../ui/buttons/Button";

function CreateModalOld({ requestClose, children, onSave, loading }) {
  const handleSave = () => {
    if (onSave) onSave();
  };

  return (
    <React.Fragment>
      <div className="overflow-auto">{children}</div>
      <div className="flex items-center justify-center lg:justify-end my-4 mx-4">
        <div className="flex-none">
          <Button color="warn" outline={true} onClick={() => requestClose(false)}>
            Cancel
          </Button>
        </div>
        <div className="flex-none pl-4">
          <Button loading={loading} loadingText="Saving..." onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
export default CreateModalOld;
