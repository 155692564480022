import { useAtom } from "jotai";
import React from "react";
import { contactsSelectedCountAtom } from "../../../state/atoms";

const ContactsListStatus = () => {
  const [selectedCount] = useAtom(contactsSelectedCountAtom);

  const getMessage = () => {
    if (selectedCount > 0) {
      return `${selectedCount} contacts selected`;
    }

    return "";
  };

  return <div className="">{getMessage()}</div>;
};

export default ContactsListStatus;
