import React, { useEffect, useState } from "react";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { QUERY_KEY } from "../../../../constants";
import useModalSaveCancel from "../../../modals/useModalSaveCancel";
import TextInput from "../../../ui/forms/TextInput";
import Modal from "../../../modals/Modal";
import enums from "../../../../enums";
import ColorPicker from "../../../ui/ColorPicker";
import FontModal from "../../../modals/FontModal";
import Button from "../../../ui/buttons/Button";
import Icon from "../../../ui/Icon";
import Label from "../../../ui/forms/Label";

const dataAtom = atomWithImmer({ id: -1, name: "", description: "" });
const errorAtom = atomWithImmer({ name: [], plural: [] });

const EditObjectModal = ({ isOpen, onClose, entity, entities }) => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useAtom(errorAtom);
  const mutation = useServiceMutation(salesService.entities.updateEntity, QUERY_KEY.ENTITIES);
  const [fontOpen, setFontOpen] = useState(false);

  const checkErrors = () => {
    let errFlag = false;

    if (data?.name?.length < 1) {
      setErrors(d => {
        d.name = ["Please enter a name for the object."];
      });
      errFlag = true;
    } else
      setErrors(d => {
        d.name = [];
      });

    if (data?.plural?.length < 1) {
      setErrors(d => {
        d.plural = ["Please enter a plural name for the object."];
      });
      errFlag = true;
    } else
      setErrors(d => {
        d.plural = [];
      });

    return errFlag;
  };

  const handleSave = setSaving => {
    if (checkErrors()) return;
    setErrors(d => {
      d.name = [];
      d.plural = [];
    });

    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
    };
    mutation.mutate(data, { onSettled: handleSaved });
  };
  const handleCancel = () => {
    onClose && onClose();
  };

  useEffect(() => {
    setData(d => {
      d.name = entity.name;
      d.id = entity.id;
      d.plural = entity.plural;
      d.iconName = entity.iconName;
      d.color = entity.color;
    });
  }, [entity, setData]);

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);

  const handleFontClose = name => {
    if (name)
      setData(d => {
        d.iconName = name;
      });
    setFontOpen(false);
  };

  return (
    <React.Fragment>
      {entity && (
        <Modal isOpen={isOpen} icon="create" title={"Edit " + entity.name + " Object"} buttons={buttons} onCloseRequest={handleCancel} wClass="lg:max-w-3xl">
          <div className="flex px-4 py-4 w-full ">
            <form className="grid grid-cols-2 w-full gap-5" autoComplete="off">
              <TextInput
                label="Plural Name"
                disabled={entity.entityType !== enums.entityTypes.Object}
                value={data.plural}
                messages={errors.pural}
                onChange={e =>
                  setData(d => {
                    d.plural = e;
                  })
                }></TextInput>
              <TextInput
                label="Name"
                disabled={entity.entityType !== enums.entityTypes.Object}
                value={data.name}
                messages={errors.name}
                onChange={e =>
                  setData(d => {
                    d.name = e;
                  })
                }></TextInput>

              <ColorPicker
                value={data.color}
                onChange={e =>
                  setData(d => {
                    d.color = e;
                  })
                }></ColorPicker>

              <div className="flex-col items-center">
                <Label>{entity.name} Icon</Label>
                <Button color={data.color} outline className="mt-2 dark:opacity-70" onClick={() => setFontOpen(true)}>
                  <Icon icon={data.iconName} size={40}></Icon>
                </Button>
              </div>
            </form>
          </div>
          {fontOpen && <FontModal isOpen={fontOpen} onClose={handleFontClose}></FontModal>}
        </Modal>
      )}
    </React.Fragment>
  );
};

export default EditObjectModal;
