import React, { useState } from "react";
import ContactsSearch from "./ContactsSearch";
import ContactsSidebar from "./ContactsSidebar";
import InfiniteScroll from "../../infiniteScroll/InfiniteScroll";
import ContactsList from "./ContactsList";
import { contactFilterAtom, contactsAtom, contactsTotalAtom } from "../../../state/atoms";
import { useAtom } from "jotai";
import Drawer from "../../ui/Drawer";
import ContactToolbar from "./ContactToolbar";
import ContactsListStatus from "./ContactsListStatus";

const Contacts = () => {
  const [filter] = useAtom(contactFilterAtom);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <Drawer opened={menuOpen} onClose={() => setMenuOpen(false)} widthFull>
        <div>
          <ContactsSidebar onClose={() => setMenuOpen(false)}></ContactsSidebar>
        </div>
      </Drawer>
      <div className="flex overflow-hidden h-full relative w-full bg-white dark:bg-gray-950">
        <div className="hidden md:flex flex-none w-64">
          <ContactsSidebar></ContactsSidebar>
        </div>
        <div className="flex-1  overflow-hidden h-full relative ml-4">
          <div className="flex flex-col  overflow-hidden h-full relative bg-gray-200 dark:bg-gray-950">
            <div className="flex items-center pt-4 pl-4 pr-2 md:pr-4 bg-white dark:bg-gray-950">
              <div className="flex flex-col w-full pr-4">
                <div className="flex">
                  <ContactToolbar onMenuOpen={() => setMenuOpen(true)}></ContactToolbar>
                  <div className="flex-1">
                    <ContactsSearch></ContactsSearch>
                  </div>
                </div>
                <div className="flex justify-end pt-2">
                  <ContactsListStatus />
                </div>
              </div>
            </div>
            <div className="flex-1 overflow-hidden h-full relative pl-4 mt-4 ">
              <InfiniteScroll url="contacts" pageSize="50" filter={filter} itemsAtom={contactsAtom} totalAtom={contactsTotalAtom}>
                <ContactsList />
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contacts;
//https://play.tailwindcss.com/uOnWQzR9tl
//https://dev.to/codeply/helpful-page-layouts-using-tailwind-css-1a3k
