import { useAtom } from "jotai";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { contactFilterAtom } from "../../../state/atoms";
import TextInput from "../../ui/forms/TextInput";

const ContactsSearch = () => {
  const [filter, setFilter] = useAtom(contactFilterAtom);
  const [query, setQuery] = useState("");
  const delayedQuery = useRef(
    debounce(
      q =>
        setFilter(d => {
          d.search = q;
        }),
      500
    ),
    [setFilter]
  );

  const handleSearchChange = value => {
    setQuery(value);
    delayedQuery.current(value);
  };

  useEffect(() => {
    setQuery(filter.search);
  }, [filter.search]);

  return (
    <div className="flex items-center">
      <div className="grow">
        <TextInput onChange={value => handleSearchChange(value)} value={query} placeholder="Search for a contact or organization..."></TextInput>
      </div>
    </div>
  );
};

export default ContactsSearch;
