import React, { useState } from "react";
import { showErrorMessage } from "../../../../../state/modalStore";
import enums from "../../../../../enums";
import fetchData from "../../../../../services/fetchData";
import Button from "../../../../ui/buttons/Button";
import Select from "../../../../ui/forms/Select";
import TextInput from "../../../../ui/forms/TextInput";

function NewPolicyModal({ requestClose, policies }) {
  const [loading, setLoading] = useState(false);
  const [clone, setClone] = useState(-2);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = result => {
    setClone(-2);
    setName("");
    setLoading(false);
    requestClose(result);
  };

  const handleCreate = () => {
    if (name.trim() === "") {
      setMessage("Please enter a new name.");
      return;
    }

    setLoading(true);
    fetchData("policies", { name, cloneFrom: clone })
      .then(res => handleClose(res.data))
      .catch(() => {
        handleClose();
        showErrorMessage("There was an error creating your new policy");
      });
  };

  return (
    <div className="flex flex-col h-full gap-4 overflow-hidden w-96">
      <div className="overflow-hidden h-full">
        <div className="overflow-auto h-full flex flex-col gap-4 px-2 py-2 mx-2 my-2">
          <div>
            <TextInput label="Policy Name" danger={message && !loading ? true : false} value={name} onChange={value => setName(value)}></TextInput>
            {message && !loading && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
          </div>

          <Select label="Copy permissions from..." value={clone} onChange={value => setClone(value)}>
            {Object.keys(enums.roles).map(r => (
              <option key={r} value={enums.roles[r] * -1}>
                {r}
              </option>
            ))}
            {policies.map(p => (
              <option key={p.id} value={p.id}>
                {p.name}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex items-center justify-center lg:justify-end m-4">
        <div className="flex-none">
          <Button outline={true} onClick={() => handleClose()}>
            Cancel
          </Button>
        </div>
        <div className="flex-none pl-4">
          <Button loading={loading} loadingText="Saving..." color="danger" onClick={handleCreate}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
export default NewPolicyModal;
