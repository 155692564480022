import React, { useState } from "react";
import ThemePicker from "../../ui/theme/ThemePicker";
import colors from "../../ui/theme/colors";
import { hslContrast, hslTextToObject, isHslContrastBlack } from "../../ui/theme/colorUtils";
import Tabs, { Tab } from "../../ui/Tabs";
import ColorPicker from "../../ui/ColorPicker";
import setThemeType from "../../ui/theme/theme";
import { COLOR_TYPE } from "../../../constants";
import Play from "../../Play";

const Dashboard = () => {
  const [tab, setTab] = useState("tests");
  return (
    <div className="overflow-auto bg-white dark:bg-gray-800 relative w-full h-full  text-center">
      <div className="flex flex-col h-full">
        <div className="bg-white dark:bg-gray-900">
          <Tabs selected={tab} onChange={t => setTab(t)}>
            <Tab value="tests">Tests</Tab>
            <Tab value="theme">Theme</Tab>
            <Tab value="swatch">Swatch</Tab>
            <Tab value="shades">Shades</Tab>
          </Tabs>
        </div>
        {tab === "tests" && (
          <div className="">
            <Play></Play>
          </div>
        )}
        {tab === "swatch" && <Swatch />}
        {tab === "shades" && <Shades />}
        {tab === "theme" && (
          <div className="flex items-center justify-center w-full h-full bg-gray-200 dark:bg-gray-800">
            <div className="w-full max-w-lg shadow-lg bg-white  dark:bg-gray-900 rounded-lg p-8">
              <ThemePicker />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Shades = () => {
  const [color, setColor] = useState("coffee");
  let shades = Object.keys(colors[color]);
  if (document.documentElement.classList.contains("dark")) shades = shades.reverse();
  const getPanel = i => {
    //if (i > shades.length - 1) return;
    if (i > 6 - 1) return;
    return (
      <div className={`flex flex-col w-full h-full p-4 items-center justify-top shadow-lg rounded-xl bg-gray-${shades[i]}`}>
        <div>{shades[i]}</div>
        <div className="flex w-full h-full">{getPanel(++i)}</div>
      </div>
    );
  };

  const handleColorChange = c => {
    setColor(c);
    setThemeType(COLOR_TYPE.BG, c);
  };

  return (
    <div className="flex h-full flex-col  justify-center items-center w-full dark:bg-gray-800 bg-white">
      <div className="flex w-full m-4 justify-center">
        <div className="max-w-md w-full">
          <ColorPicker label="" onChange={handleColorChange} value={color} includeGrays={true}></ColorPicker>
        </div>
      </div>
      <div className="flex h-full w-full p-5  dark:bg-black bg-white">{getPanel(0)}</div>
    </div>
  );
};

const Swatch = () => {
  const getColorRow = clr =>
    Object.keys(colors[clr]).map(s => {
      const hsl = hslTextToObject(colors[clr][s]);
      return (
        <div key={clr + s} className={`flex flex-col w-full p-2 overflow-hidden text-sm m-2 rounded justify-center items-center bg-${clr}-${s} ${isHslContrastBlack(hsl) ? "text-black" : "text-white"}`}>
          <div>{s}</div>
          <div>{clr}</div>
          <div>White {hslContrast(hsl, { h: 0, s: 100, l: 100 }).toFixed(1)}</div>
          <div>Black {hslContrast(hsl, { h: 0, s: 100, l: 0 }).toFixed(1)}</div>
        </div>
      );
    });

  return (
    <div className="flex flex-col p-5">
      {Object.keys(colors).map(c => (
        <div className="flex" key={c}>
          {getColorRow(c)}
        </div>
      ))}
    </div>
  );
};

export default Dashboard;

/*

const fruits = ["🍒 Cherry", "🍓 Strawberry", "🍇 Grape", "🍎 Apple", "🍉 Watermelon", "🍑 Peach", "🍊 Orange", "🍋 Lemon", "🍍 Pineapple", "🍌 Banana", "🥑 Avocado", "🍏 Green Apple", "🍈 Melon", "🍐 Pear", "🥝 Kiwifruit", "🥭 Mango", "🥥 Coconut", "🍅 Tomato"];
const items = fruits.map((f, i) => ({ id: i, name: f }));


  const handleFontClose = name => {
    if (name) alert(name);
    setFontOpen(false);
  };


  
     <div className="flex m-5 gap-4">
        <div className="w-96 flex flex-col gap-4">
          <ThemePicker></ThemePicker>
          <AccountPicker placeholder="Pick an account" value={item} onChange={handleChange} description="This is an account" messages={[]}></AccountPicker>
          <TextInput label="kevin" required></TextInput>
          <TextInput label="kevin" required></TextInput>
          <RolePicker ></RolePicker>
        </div>
        <div className="w-96 flex flex-col gap-4">
          <Select label="sample">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
          </Select>
          <CenterSelect items={items} renderItem={i => <div className="">{i.name}</div>}></CenterSelect>
          <ColorPicker onChange={v => setColor(v)} value={color}></ColorPicker>
          <Button onClick={() => setFontOpen(true)}>Pick a font</Button>
        </div>
        <div className="flex">
          colors

        </div>
        <div className="w-20 h-20 flex items-center justify-center font-semibold dark:bg-primary-600 dark:text-primarytext-600 border-4 dark:border-primary-900">kevin</div>
      </div>

      <FontModal isOpen={fontOpen} onClose={handleFontClose}></FontModal>
      */
