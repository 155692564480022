import React, { useState } from "react";
import CreateModalOld from "../../modals/CreateModalOld";
import enums from "../../../enums";
import NewObjectForm from "../../forms/NewObjectForm";
import useServiceMutation from "../../../mutations/useServiceMutation";
import salesService from "../../../services/salesService";
import { QUERY_KEY } from "../../../constants";

function CreateContactModal({ requestClose }) {
  const [data, setData] = useState({});

  const mutation = useServiceMutation(salesService.contacts.insertContact, QUERY_KEY.CONTACTS);

  const handleSave = () => {
    mutation.mutate(data);
  };

  const handleChange = val => {
    setData(val);
  };

  return (
    <CreateModalOld onSave={handleSave} title="Add a New Contact" requestClose={() => requestClose(false)}>
      <NewObjectForm onChange={handleChange} entity={enums.entityTypes.Contact}></NewObjectForm>
    </CreateModalOld>
  );
}
export default CreateContactModal;
