import React, { useCallback, useState } from "react";
import Icon from "../../ui/Icon";
import { useAtom } from "jotai";
import { contactFilterAtom, contactsSelectedCountAtom, modalCreateContactAtom } from "../../../state/atoms";
import OldModal from "../../modals/OldModal";
import DeleteConctactsModal from "./DeleteContactsModal";

const ContactToolbar = ({ onMenuOpen }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [, setFilter] = useAtom(contactFilterAtom);
  const [selectedCount] = useAtom(contactsSelectedCountAtom);
  const [, setCreateContact] = useAtom(modalCreateContactAtom);
  const handleDeleteOpen = useCallback(() => {
    setDeleteOpen(true);
  }, []);

  const handleDelete = res => {
    setDeleteOpen(false);
    if (res) {
      setFilter(d => {
        d.page !== 0 ? (d.page = 0) : (d.page = 1);
      });
    }
    // cause infinite scroll to reset, TODO:need to find a better way
  };

  return (
    <div className="flex items-center justify-center text-gray-600 dark:text-gray-500">
      <div className="md:hidden cursor-pointer hover:text-primary-500 dark:hover:text-primary-500 pl-2 pr-2" onClick={onMenuOpen}>
        <Icon icon="tune" size={20} />
      </div>
      {selectedCount > 0 && (
        <div className="cursor-pointer hover:text-primary-500 dark:hover:text-primary-500 pr-2" onClick={handleDeleteOpen}>
          <Icon icon="delete_outline" size={20} />
        </div>
      )}
      <div
        className="cursor-pointer hover:text-primary-500 dark:hover:text-primary-500 pr-4"
        onClick={() =>
          setCreateContact(d => {
            d.visible = true;
          })
        }>
        <Icon icon="add" size={20} />
      </div>

      <OldModal title="Confirm Deletion" handleClose={res => handleDelete(res)} isOpen={deleteOpen}>
        <DeleteConctactsModal> This is Modal Content!</DeleteConctactsModal>
      </OldModal>
    </div>
  );
};

export default ContactToolbar;
