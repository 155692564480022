import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { teamPageAtom } from "../../../state/atoms";
import Team from "./Team";
import TeamsSidebar from "./TeamsSidebar";
import { systemAtom } from "../../../state/systemStore";

const Teams = () => {
  const params = useParams();
  const [system] = useAtom(systemAtom);
  const [teamPage, setTeamPage] = useAtom(teamPageAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setTeamPage(d => {
        d.id = Number(params.id);
      });
    } else if (teamPage.id) {
      navigate(`/teams/${teamPage.id}`);
    }
  }, [params, setTeamPage, teamPage, navigate]);

  return (
    <React.Fragment>
      <div className="flex overflow-hidden h-full relative w-full ">
        <div className="hidden lg:flex flex-none ">
          <TeamsSidebar teams={system.teams} />
        </div>
        <div className="flex-1 overflow-hidden h-full relative">
          <Team team={system.teams.find(t => t.id === teamPage.id)}></Team>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Teams;
