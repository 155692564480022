import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY, STALETIME } from "../constants";
import salesService from "../services/salesService";

const fetch = async () => {
  return await salesService.entities.getEntities();
};

export default function useGetEntities() {
  const queryKey = [QUERY_KEY.ENTITIES];
  const queryFn = () => fetch();

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime: STALETIME.LONG,
  });
}
