import React, { useEffect, useState } from "react";
import Button from "../../ui/buttons/Button";
import NumberInput from "../../ui/forms/NumberInput";
import fetchData from "../../../services/fetchData";

const Verify2fa = ({ response, resultHandler }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  const handleClick = e => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      userId: response.userId,
      code,
      token: response.accessToken,
    };

    fetchData(`https://${process.env.REACT_APP_AUTH_URL}/auth/confirm-2fa`, payload)
      .then(res => setData(res.data))
      .finally(setLoading(false));
  };

  useEffect(() => {
    if (data) {
      if (data.result === 1) setMessage("Incorrect verification code");
      else resultHandler(data);
    }
  }, [data, resultHandler]);

  return (
    <React.Fragment>
      <div className="pb-3 text-xl items-center w-full text-center">Check your device</div>
      <div className="pb-8 items-center w-full text-center">We have sent you a code, please enter it below.</div>
      <div className="pb-8 w-full">
        <div>
          <NumberInput value={code} onChange={value => setCode(value)}></NumberInput>
          {message && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
        </div>
      </div>
      <div className="flex justify-end items-center">
        <div>
          <Button loading={loading} loadingText="Verifying..." onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Verify2fa;
