import React, { useEffect, useState } from "react";
import fetchData from "../../../../../services/fetchData";
import Toggle from "../../../../ui/forms/Toggle";
import Spinner from "../../../../ui/Spinner";
import NewPolicyModal from "./NewPolicyModal";
import OldModal from "../../../../modals/OldModal";
import Button from "../../../../ui/buttons/Button";
import DeletePolicyModal from "./DeletePolicyModal";
import { produce } from "immer";
import RenamePolicyModal from "./RenamePolicyModal";

const Policies = () => {
  const [defs, setDefs] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(true);

  const [createOpen, setCreateOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);

  const handleCreate = policy => {
    setCreateOpen(false);
    if (policy) {
      setPolicies(
        produce(d => {
          d.push(policy);
        })
      );
      setPolicy(policy);
    }
  };

  const handleRename = name => {
    setRenameOpen(false);
    if (!name) return;
    setPolicies(
      produce(d => {
        let p = d.find(p => p.id === policy.id);
        p.name = name;
      })
    );

    setPolicy(
      produce(d => {
        d.name = name;
      })
    );
  };

  const handlePolicyChange = p => {
    setDefs(
      produce(draft => {
        for (let i = 0; i < draft.length; i++) {
          draft[i].loading = false;
        }
      })
    );
    setPolicy(p);
  };

  const handleDelete = policy => {
    setDeleteOpen(false);
    if (policy) {
      setPolicies(
        produce(d => {
          const i = d.findIndex(p => p.id === policy.id);
          d.splice(i, 1);
        })
      );

      let p = null;
      if (policies.length > 0) {
        p = policies[0];
        if (p.id === policy.id) {
          if (policies.length > 1) p = policies[1];
          else p = null;
        }
      }
      setPolicy(p);
    }
  };

  useEffect(() => {
    fetchData("policies/definitions", null, "GET").then(res => setDefs(res.data.filter(d => d.defaultRole !== 0)));
    fetchData("policies", null, "GET")
      .then(res => {
        setPolicies(res.data);
        if (res.data[0]) setPolicy(res.data[0]);
      })
      .finally(() => setLoading(false));
  }, []);

  const handlePermissionChange = (def, newValue) => {
    let updatedPolicy = JSON.parse(JSON.stringify(policy));

    let p = updatedPolicy.permissions.find(x => x.type === def.type);
    if (p) p.granted = newValue;
    else updatedPolicy.permissions.push({ type: def.type, granted: newValue });

    setDefs(
      produce(draft => {
        const el = draft.find(x => x.type === def.type);
        el.loading = true;
      })
    );

    fetchData(`policies/${updatedPolicy.id}`, { permissions: updatedPolicy.permissions }, "PATCH")
      .then(res => {
        setPolicy(
          produce(draft => {
            const p = draft.permissions.find(x => x.type === def.type);
            if (p) p.granted = newValue;
          })
        );

        setPolicies(
          produce(draft => {
            const pol = draft.find(x => x.id === policy.id);
            if (pol) {
              const p = pol.permissions.find(x => x.type === def.type);
              if (p) p.granted = newValue;
            }
          })
        );
      })
      .catch(err => {
      })
      .finally(() => {
        setDefs(
          produce(draft => {
            const el = draft.find(x => x.type === def.type);
            el.loading = false;
          })
        );
      });
  };

  const Policy = ({ permissions }) => {
    let cat = "";

    const Definition = ({ def }) => (
      <div className="flex items-center">
        <div className="flex-none w-72">{def.name}</div>
        <div className="flex-none">
          <Toggle checked={isChecked(def)} onChange={e => handlePermissionChange(def, e.target.checked)}></Toggle>
        </div>
        <div className="flex-none w-8">
          {def.loading && (
            <div className="flex-none h-6 w-6 mr-4">
              <Spinner></Spinner>
            </div>
          )}
        </div>

        <div className="">{def.description}</div>
        <div className=""></div>
      </div>
    );

    const isChecked = def => {
      if (policy?.permissions) {
        let p = policy.permissions.find(p => p.type === def.type);
        if (!p) return def.defaultGrant;
        return p.granted;
      } else {
        return def.defaultGrant;
      }
    };

    const isNewCat = newCat => {
      if (newCat === cat) return false;
      cat = newCat;
      return true;
    };

    return (
      <div className="px-4 ">
        {defs.map(d => (
          <div key={d.name} className="">
            {isNewCat(d.category) && <div className="font-mono text-gray-500 pt-4 pb-2">{d.category}</div>}
            <div className="flex py-2 px-4 border-t border-gray-500 border-opacity-20 ">
              <Definition def={d}></Definition>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const PolicyList = () => {
    return (
      <div className="overflow-auto w-full px-4">
        <div className="text-3xl font-thin pt-4  text-gray-500 border-b border-gray-500 border-opacity-10 mb-4 py-4">Policies</div>
        {policies.map(p => (
          <div key={p.id} className={"my-2 px-4 py-1 cursor-pointer " + (p.id === policy?.id ? " text-primary-500 dark:text-primary-500 " : " text-gray-500 dark:text-gray-300 ")} onClick={() => handlePolicyChange(p)}>
            {p.name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      {loading && (
        <div className="flex items-center justify-center py-8 h-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!loading && (
        <div className="flex  bg-white dark:bg-gray-950 overflow-hidden h-full relative">
          <div className="flex h-full relative overflow-hidden mx-4 w-72 bg-gray-50 dark:bg-gray-950">
            <PolicyList></PolicyList>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-end py-4 mx-4 mb-4 border-b border-gray-500 border-opacity-10 gap-4">
              <div className="grow text-3xl font-thin text-gray-500  ">Policy Permissions</div>
              <div className="flex-none">
                <Button color="danger" outline onClick={() => setDeleteOpen(true)}>
                  Delete Policy
                </Button>
              </div>
              <div className="flex-none">
                <Button onClick={() => setRenameOpen(true)}>Rename Policy</Button>
              </div>
              <div className="flex-none">
                <Button onClick={() => setCreateOpen(true)}>Add Policy</Button>
              </div>
            </div>

            <div className="flex overflow-auto ">
              <div className="flex-1 ">
                {policy && <Policy></Policy>}
                {!policy && <div className="flex items-center justify-center text-3xl text-gray-500 pt-20">There are no policies to display.</div>}
              </div>
            </div>
          </div>
        </div>
      )}
      <OldModal title="New Policy" handleClose={handleCreate} isOpen={createOpen}>
        <NewPolicyModal policies={policies}></NewPolicyModal>
      </OldModal>
      <OldModal title="Delete Policy" handleClose={handleDelete} isOpen={deleteOpen}>
        <DeletePolicyModal policy={policy}></DeletePolicyModal>
      </OldModal>
      <OldModal title="Rename Policy" handleClose={handleRename} isOpen={renameOpen}>
        <RenamePolicyModal policy={policy}></RenamePolicyModal>
      </OldModal>
    </React.Fragment>
  );
};

export default Policies;
