import { useEffect, useState } from "react";
import useGetClientSetting from "./useGetClientSetting";
import { CLIENT_SETTINGS } from "../constants";
import useGetEntities from "./useGetEntities";
import enums, { moduleTypeProps } from "../enums";

const useGetNavItems = () => {
  const [navItems, setNavItems] = useState([]);
  const { data, isLoading: isNavLoading } = useGetClientSetting(
    CLIENT_SETTINGS.NAV_ITEM_ORDER,
  );
  const { data: entities, isLoading: isEntitiesLoading } = useGetEntities();

  useEffect(() => {
    const getDefaultMenuItems = () => {
      let res = [];

      const addItem = (value, type) => {
        if (type !== enums.moduleTypes.Object) {
          res.push({ id: type*-1, type, ...value });
        } else {
          if (entities) {
            entities.forEach((e) => {
              res.push({
                type: enums.moduleTypes.Object,
                id: e.id,
                icon: e.iconName,
                title: e.plural,
                color: e.color,
                path: e.apiName,
                visible: true,
              });
            });
          }
        }
      };
      moduleTypeProps.forEach(addItem);

      return res;
    };
    setNavItems((d) => {
      return data || getDefaultMenuItems();
    });
  }, [data, entities, setNavItems]);

  return {
    navItems,
    isNavLoading: isNavLoading || isEntitiesLoading,
  };
};

export default useGetNavItems;
