import React, { useState } from "react";
import NavItemOrder from "./pages/admin/appearance/NavItemOrder";
import { SortableList } from "./ui/sortable-list/SortableList";

function createRange(length, initializer) {
  return [...new Array(length)].map((_, index) => initializer(index));
}

function getMockItems() {
  return createRange(5, (index) => ({
    id: index + 1,
    name: (index + 1).toString(),
  }));
}

const Play = () => {
  const [items] = useState(getMockItems);

  return (
    <>
      <div style={{ maxWidth: 400, margin: "30px auto" }}>
        <SortableList
          items={items}
          onChange={() => {}}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <div className="flex flex-col w-20 h-20 bg-white border border-gray-200 shadow-lg rounded-lg">
                {item.id}
                <SortableList.DragHandle className="flex bg-red-500">
                  X
                </SortableList.DragHandle>
              </div>
            </SortableList.Item>
          )}
        />
      </div>
      <NavItemOrder></NavItemOrder>
    </>
  );
};

export default Play;
