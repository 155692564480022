import React, { useEffect, useState } from "react";
import { showErrorMessage } from "../../../../../state/modalStore";
import fetchData from "../../../../../services/fetchData";
import Button from "../../../../ui/buttons/Button";
import TextInput from "../../../../ui/forms/TextInput";

function RenamePolicyModal({ requestClose, policy }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = result => {
    setLoading(false);
    requestClose(result);
  };

  const handleRename = () => {
    if (name.trim() === "") {
      setMessage("Please enter a new name.");
      return;
    }

    setLoading(true);
    fetchData(`policies/${policy.id}`, { name }, "PATCH")
      .then(res => handleClose(name))
      .catch(err => {
        handleClose();
        showErrorMessage("There was a problem saving your new policy name.");
      });
  };

  useEffect(() => {
    if (policy) setName(policy.name);
  }, [policy]);

  return (
    <div className="flex flex-col h-full gap-4 overflow-hidden w-96">
      <div className="overflow-hidden h-full">
        <div className="overflow-auto h-full flex flex-col gap-4 px-2 py-2 mx-2 my-2">
          <TextInput label="Policy Name" danger={message && !loading ? true : false} value={name} onChange={value => setName(value)}></TextInput>
          {message && !loading && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
        </div>
      </div>

      <div className="flex items-center justify-center lg:justify-end m-4">
        <div className="flex-none">
          <Button color="primary" outline={true} onClick={() => handleClose()}>
            Cancel
          </Button>
        </div>
        <div className="flex-none pl-4">
          <Button loading={loading} loadingText="Saving..." color="danger" onClick={handleRename}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
export default RenamePolicyModal;
