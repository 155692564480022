import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { userPageAtom } from "../../../state/atoms";
import UsersSidebar from "./UsersSidebar";
import { systemAtom } from "../../../state/systemStore";

const Users = () => {
  const params = useParams();
  const [system] = useAtom(systemAtom);
  const [usersPage, setUsersPage] = useAtom(userPageAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      setUsersPage(d => {
        d.id = Number(params.id);
      });
    } else if (usersPage?.id) {
      navigate(`/users/${usersPage.id}`);
    }
  }, [params, setUsersPage, usersPage, navigate]);

  return (
    <React.Fragment>
      <div className="flex overflow-hidden h-full relative w-full ">
        <div className="hidden lg:flex flex-none ">
          <UsersSidebar users={system.users} />
        </div>
        <div className="flex-1 overflow-hidden h-full relative">{/* <User user={system.users.find(a => a.id === usersPage.id)}></User> */}</div>
      </div>
    </React.Fragment>
  );
};

export default Users;
