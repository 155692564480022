import enums from "../enums";
import fetchQuery from "../services/fetchQuery";
import useServiceMutation from "./useServiceMutation";

const getBaseUrl = (entity) =>
  entity?.entityType === enums.entityTypes.Object
    ? `objects/${entity?.id}`
    : `${entity?.apiName}`;

export function useDeleteMutation(entity, id) {
  const url = (params) => `${getBaseUrl(entity)}/${params.id}`;
  const service = (params) => fetchQuery(url(params), null, "DELETE");
  return useServiceMutation(service, [entity?.id, id]);
}

export function useInsertMutation(entity) {
  const url = () => `${getBaseUrl(entity)}`;
  const service = (params) => fetchQuery(url(), params, "POST");
  return useServiceMutation(service, entity?.id);
}

export function useUpdateMutation(entity, id) {
  const url = (params) => `${getBaseUrl(entity)}/${params.id}`;
  const service = (params) => fetchQuery(url(params), params, "PUT");
  return useServiceMutation(service, [entity?.id, id]);
}
