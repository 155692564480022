import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY, STALETIME } from "../constants";
import salesService from "../services/salesService";

const fetch = async params => {
  return await salesService.entities.getEntity(params);
};

export default function useGetEntity(id) {
  const queryKey = [QUERY_KEY.ENTITIES, id];
  const queryFn = () => fetch({ id });

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime: STALETIME.LONG,
  });
}
