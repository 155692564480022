const enums = {
  toText: (type, value) => {
    for (const key in type) {
      if (type[key] === value) return key.replace(/([A-Z])/g, " $1").trim();
    }
  },
  roles: {
    Owner: 0,
    Admin: 1,
    User: 2,
  },
  planTypes: {
    Team: 0,
    Pro: 1,
    Enterprise: 2,
  },
  entityTypes: {
    Object: 0,
    Account: 1,
    Contact: 2,
    User: 3,
    Role: 4,
  },
  fieldTypes: {
    Section: -1,
    Text: 0,
    Checkbox: 1,
    DropDown: 2,
    Number: 3,
    Url: 5,
    Date: 6,
    Time: 7,
    DependantField: 8,
    Memo: 9,
    Email: 10,
    Phone: 11,
    Lookup: 12,
  },
  moduleTypes: {
    Object: 1,
    Dashboard: 2,
    Analytics: 3,
    Setup: 4,
  },
};

export const fieldTypeProps = new Map([
  [enums.fieldTypes.Section, { icon: "storage", title: "Section" }],
  [enums.fieldTypes.Text, { icon: "title", title: "Text" }],
  [enums.fieldTypes.Checkbox, { icon: "check_box", title: "Checkbox" }],
  [
    enums.fieldTypes.DropDown,
    { icon: "keyboard_arrow_down", title: "Drop Down" },
  ],
  [enums.fieldTypes.Number, { icon: "tag", title: "Number" }],
  [enums.fieldTypes.Url, { icon: "link", title: "Url" }],
  [enums.fieldTypes.Date, { icon: "calendar_today", title: "Date" }],
  [enums.fieldTypes.Time, { icon: "access_time", title: "Time" }],
  [
    enums.fieldTypes.DependantField,
    { icon: "filter_list", title: "Dependant Field" },
  ],
  [enums.fieldTypes.Memo, { icon: "text_snippet", title: "Multi-line Text" }],
  [enums.fieldTypes.Email, { icon: "alternate_email", title: "Email" }],
  [enums.fieldTypes.Phone, { icon: "phone", title: "Phone" }],
  [enums.fieldTypes.Lookup, { icon: "keyboard_arrow_down", title: "Lookup" }],
]);

export const moduleTypeProps = new Map([
  [
    enums.moduleTypes.Dashboard,
    {
      icon: "dashboard",
      title: "Dashboard",
      path: "dashboard",
      color: "primary",
    },
  ],
  [
    enums.moduleTypes.Analytics,
    {
      icon: "reports",
      title: "Analytics",
      path: "analytics",
      color: "primary",
    },
  ],
  [enums.moduleTypes.Object, { icon: "", title: "" }],
  [
    enums.moduleTypes.Setup,
    {
      icon: "admin",
      title: "Setup",
      path: "setup",
      mobileOnly: true,
      color: "primary",
    },
  ],
]);

export default enums;
