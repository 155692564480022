import React, { useEffect, useState } from "react";
import PageSpinner from "../../../ui/PageSpinner";
import useGetNavItems from "../../../../queries/useGetNavItems";
import { SortableList } from "../../../ui/sortable-list/SortableList";
import Icon from "../../../ui/Icon";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { CLIENT_SETTINGS, QUERY_KEY } from "../../../../constants";
import Header from "../../../ui/Header";
import Panel from "../../../ui/Panel";

const NavItemOrder = () => {
  const { navItems, isNavLoading } = useGetNavItems();
  const [items, setItems] = useState([]);
  const mutation = useServiceMutation(
    salesService.clientSettings.updateSetting,
    [QUERY_KEY.CLIENTSETTINGS, CLIENT_SETTINGS.NAV_ITEM_ORDER],
  );

  useEffect(() => {
    setItems(navItems || []);
  }, [navItems, setItems]);

  const ListItem = ({ item, draghandle }) => {
    return (
      <div className="flex items-center px-4 my-1 py-2 border dark:border-gray-500/20 rounded-lg border-gray-200  gap-3">
        {draghandle}
        <Icon icon={item.icon} size={20} className={`text-${item.color}-500`} />
        {item.title}
      </div>
    );
  };

  function onDragEnd(active, index, items) {
    setItems(items);
    mutation.mutate({ key: CLIENT_SETTINGS.NAV_ITEM_ORDER, value: items });
  }

  return (
    <div className="h-full flex flex-col">
      <Header>
        <div className="flex">
          <div className="flex grow">Navigation Item Order</div>
          <div className="flex flex-none font-normal text-base">
            {/*<Button onClick={() => setIsNewOpen(true)}>Create Object</Button>*/}
          </div>
        </div>
      </Header>
      <div className="bg-gray-200 dark:bg-gray-800 grow h-full overflow-auto">
        {isNavLoading && <PageSpinner />}
        {!isNavLoading && (
          <>
            {items && (
              <div className="flex justify-center py-8 px-8 dark:pl-0">
                <Panel>
                  <div className="py-8 px-8">
                    <SortableList
                      className="flex flex-col  items-center w-full  "
                      items={items}
                      onChange={onDragEnd}
                      renderItem={(item, active, index) => (
                        <SortableList.Item
                          id={item.id}
                          className="w-full max-w-lgx"
                        >
                          <ListItem
                            item={item}
                            draghandle={
                              <SortableList.DragHandle
                                className={`flex cursor-grab mr-2`}
                              >
                                <Icon icon="drag_indicator" size={18} />
                              </SortableList.DragHandle>
                            }
                          />
                        </SortableList.Item>
                      )}
                    />
                  </div>
                </Panel>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NavItemOrder;
