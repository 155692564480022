import React, { useState } from "react";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { QUERY_KEY } from "../../../../constants";
import useModalSaveCancel from "../../../modals/useModalSaveCancel";
import Modal from "../../../modals/Modal";
import TextInput from "../../../ui/forms/TextInput";
import ColorPicker from "../../../ui/ColorPicker";
import Label from "../../../ui/forms/Label";
import Button from "../../../ui/buttons/Button";
import FontModal from "../../../modals/FontModal";
import Icon from "../../../ui/Icon";
import textHelper from "../../../../helpers/textHelper";

const dataAtom = atomWithImmer({ apiName: "", name: "", plural: "", iconName: "layers", color: "blue" });
const errorAtom = atomWithImmer({ name: [], apiName: [], plural: [] });

const NewObjectModal = ({ isOpen, onClose, entities }) => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useAtom(errorAtom);
  const [fontOpen, setFontOpen] = useState(false);

  const mutation = useServiceMutation(salesService.entities.insertEntity, QUERY_KEY.ENTITIES);

  const checkErrors = () => {
    let errFlag = false;

    if (data?.name?.length < 1) {
      setErrors(d => {
        d.name = ["Please enter a name for the object."];
      });
      errFlag = true;
    } else
      setErrors(d => {
        d.name = [];
      });

    if (data?.apiName?.length < 1) {
      setErrors(d => {
        d.apiName = ["Please enter an API name for the object."];
      });
      errFlag = true;
    } else
      setErrors(d => {
        d.apiName = [];
      });

    if (data?.plural?.length < 1) {
      setErrors(d => {
        d.plural = ["Please enter a plural name for the object."];
      });
      errFlag = true;
    } else
      setErrors(d => {
        d.plural = [];
      });

    const dupe = entities.find(e => e.apiName.toLowerCase() === data.apiName.toLowerCase() && e.id !== data.id);
    if (dupe) {
      setErrors(d => {
        d.apiName.push(["There is already a object by the name of " + data.apiName]);
      });
      errFlag = true;
    }

    return errFlag;
  };

  const handleSave = setSaving => {
    if (checkErrors()) return;

    setErrors(d => {
      d.name = [];
      d.apiName = [];
      d.plural = [];
    });

    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
      resetState();
    };
    mutation.mutate(data, { onSettled: handleSaved });
  };

  const resetState = () => {
    setData(d => {
      d.apiName = "";
      d.name = "";
      d.plural = "";
      d.iconName = "layers";
      d.color = "blue";
    });
  };

  const handleCancel = () => {
    resetState();

    onClose && onClose();
  };

  const handleFontClose = name => {
    if (name)
      setData(d => {
        d.iconName = name;
      });
    setFontOpen(false);
  };

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);

  const handleNameChange = (k, v) => {
    setData(d => {
      if (k === "name") {
        d.name = v;
      } else if (k === "apiName") {
        d.apiName = textHelper.toKebabCase(v);
      } else if (k === "plural") {
        if (d.apiName === "" || d.apiName === textHelper.toKebabCase(d.plural)) {
          d.apiName = textHelper.toKebabCase(v);
        }
        if (d.name === "" || d.plural === d.name) {
          d.name = v;
        }
        d.plural = v;
      }
    });
  };

  return (
    <Modal isOpen={isOpen} icon="add_circle" iconColor="primary" title={"Create a New Object"} buttons={buttons} onCloseRequest={handleCancel}>
      <div className="flex px-4 py-4 w-full ">
        <form className="grid grid-cols-2 w-full gap-5" autoComplete="off">
          <TextInput label="Plural Name" value={data.plural} messages={errors.plural} onChange={v => handleNameChange("plural", v)}></TextInput>
          <TextInput label="Name" value={data.name} messages={errors.name} onChange={v => handleNameChange("name", v)}></TextInput>

          <TextInput label="API Name" value={data.apiName} messages={errors.apiName} onChange={v => handleNameChange("apiName", v)}></TextInput>

          <ColorPicker
            value={data.color}
            onChange={e =>
              setData(d => {
                d.color = e;
              })
            }></ColorPicker>

          <div className="flex-col items-center">
            <Label>{data.name} Icon</Label>
            <Button color={data.color} outline className="mt-2" onClick={() => setFontOpen(true)}>
              <Icon icon={data.iconName} size={40}></Icon>
            </Button>
          </div>
        </form>
        {fontOpen && <FontModal isOpen={fontOpen} onClose={handleFontClose}></FontModal>}
      </div>
    </Modal>
  );
};

export default NewObjectModal;
