import React, { useEffect } from "react";
import Select from "../../../../ui/forms/Select";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";
import DeleteModal from "../../../../modals/DeleteModal";

const dataAtom = atomWithImmer({ id: -1, transferId: -1 });

const DeleteRoleModal = ({ isOpen, onClose, role, nodes }) => {
  const [data, setData] = useAtom(dataAtom);
  const mutation = useServiceMutation(salesService.roles.deleteRole, QUERY_KEY.ROLES);

  useEffect(() => {
    setData(d => {
      d.id = role.id;
      d.transferId = role.parentId;
    });
  }, [role, setData]);

  const handleDelete = result => {
    if (!result) {
      onClose();
      return;
    }
    result.setIsLoading(true);
    const handleDeleted = () => {
      result.setIsLoading(false);
      onClose && onClose();
    };
    mutation.mutate(data, { onSettled: handleDeleted });
  };

  const getRoles = () => {
    const treeToList = (l, n) => {
      if (n.id === role.id) return;
      const { nodes, ...o } = n;
      l.push(o);
      n.nodes.forEach(node => treeToList(l, node));
    };
    let ar = [];
    treeToList(ar, nodes);
    return ar;
  };

  return (
    <React.Fragment>
      {role && (
        <DeleteModal isOpen={isOpen} onClose={handleDelete} title={"Delete " + role.name}>
          <div className="flex px-4 py-4 w-full ">
            <form className="flex w-full flex-col gap-5" autoComplete="off">
              <Select
                label="Transfer Users and Subordinates To:"
                value={data.transferId}
                onChange={v =>
                  setData(d => {
                    d.transferId = v;
                  })
                }>
                {nodes &&
                  getRoles().map(r => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
              </Select>
            </form>
          </div>{" "}
        </DeleteModal>

        // <Modal isOpen={isOpen} icon="delete" iconColor="danger" title={"Delete " + role.name} buttons={buttons} onCloseRequest={handleCancel} wClass="lg:max-w-lg">

        // </Modal>
      )}
    </React.Fragment>
  );
};

export default DeleteRoleModal;
