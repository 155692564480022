import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorMessage } from "../state/modalStore";

export default function useBaseSeviceMutation({ mutationFn, queryKey }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: error => showErrorMessage(error),
  });
}
