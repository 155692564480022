import React, { useState } from "react";
import Button from "../ui/buttons/Button";

const useModalSaveCancel = (onSave, onCancel) => {
  const [isSaving, setIsSaving] = useState(false);

  const buttons = [
    <Button key="cancel" disabled={isSaving} outline={true} onClick={() => onCancel()}>
      Cancel
    </Button>,
    <Button key="save" loading={isSaving} loadingText="Saving..." onClick={() => onSave(setIsSaving)}>
      Save
    </Button>,
  ];

  return { buttons };
};

export default useModalSaveCancel;
