import React, { useState } from "react";

const Drawer = ({ children, opened, onClose, padTop, widthFull }) => {
  const [swipeStart, setSwipeStart] = useState(-1);
  const [swipeEnd, setSwipeEnd] = useState(-1);

  function handleSwipeStart(e) {
    setSwipeStart(e.targetTouches[0].pageX);
    setSwipeEnd(-1);
  }

  function handleSwipeMove(e) {
    setSwipeEnd(e.targetTouches[0].pageX);
  }

  function handleSwipeEnd() {
    if (swipeEnd > 0 && swipeStart - swipeEnd > 75) onClose();
    setSwipeStart(-1);
    setSwipeEnd(-1);
  }

  return (
    <div className="lg:hidden">
      <div className={"absolute flex-col flex-no-shrink z-30 h-full overflow-hidden bg-gray-50 dark:bg-gray-800 transition-all ease-out duration-200 " + (opened ? " left-0" : " -left-96 w-2") + (padTop || " top-0") + (widthFull ? " w-full" : " w-72")}>{children}</div>
      {opened && <div className="absolute top-0 left-0 w-full h-full z-10 bg-transparent" onClick={onClose} onTouchStart={handleSwipeStart} onTouchEnd={handleSwipeEnd} onTouchMove={handleSwipeMove}></div>}
    </div>
  );
};

export default Drawer;
