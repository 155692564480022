import React from "react";
import TreeView from "../../../../ui/treeView/TreeView";
import SelectableItem from "../../../../ui/SelectableItem";
import Icon from "../../../../ui/Icon";

const RolesTree = ({ selected, data, onChange, onEdit, onAdd, onDelete }) => {
  const getIcon = (icon, onClick) => (
    <div
      className="flex p-1 dark:border-gray-600 border-gray-400 hover:border-primary-300 text-gray-500 border -my-1 cursor-pointer rounded-full dark:text-gray-500 hover:text-primary-500 dark:hover:text-primary-300 dark:hover:border-primary-300 hover:bg-primary-500 dark:hover:bg-opacity-10 dark:hover:bg-primary-500  hover:bg-opacity-30  transition-all duration-400  ease-in-out"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}>
      <Icon size={16} icon={icon}></Icon>
    </div>
  );
  const getItem = node => (
    <div className="flex items-center group">
      <SelectableItem
        selected={selected?.id === node.id}
        onClick={e => {
          e.preventDefault();
          onChange && onChange(node);
        }}>
        {node.name}
      </SelectableItem>
      <div className="flex ml-2 gap-1 opacity-0 group-hover:opacity-100  transition-all duration-400 delay-200 ease-in-out">
        {getIcon("add", () => onAdd(node))}
        {getIcon("create", () => onEdit(node))}
        {node.parentId && getIcon("delete", () => onDelete(node))}
      </div>
    </div>
  );
  return <div className="flex">{data && <TreeView node={data} getItemFn={getItem}></TreeView>}</div>;
};

export default RolesTree;
