import React from "react";

const PhoneLink = props => {
  return (
    <a className="hover:text-primary-500 dark:hover:text-primary-500" href={"tel:" + props.number} target="_blank" rel="noopener noreferrer">
      {React.Children.count(props.children) < 1 ? props.number : props.children}
    </a>
  );
};

export default PhoneLink;
