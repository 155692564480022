import { useImmerAtom } from "jotai-immer";
import React from "react";
import { useNavigate } from "react-router-dom";
import { tabsAtom } from "../../state/atoms";
import useInsertRecentItem from "../../mutations/useInsertRecentItem";

const TabLink = ({ path, entity = -1, id, name, iconName, children }) => {
  const [tabs, setTabs] = useImmerAtom(tabsAtom);
  const navigate = useNavigate();

  const recenItemsMutation = useInsertRecentItem();

  const handleClick = e => {
    e.preventDefault();
    if (id && entity > -1) recenItemsMutation.mutate({ entity, recentId: id });

    let tab = { path, name, iconName, canClose: true, hide: false };
    let existingTab = tabs.find(t => t.path === tab.path);
    if (!existingTab)
      setTabs(d => {
        d.unshift(tab);
      });
    navigate(tab.path);
  };

  return (
    <div onClick={handleClick} className="hover:text-primary-500 dark:hover:text-primary-500 cursor-pointer">
      {children}
      {!children && <React.Fragment>{name}</React.Fragment>}
    </div>
  );
};

export default TabLink;
