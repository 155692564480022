import React from "react";

const EmailLink = props => {
  return (
    <a className="hover:text-primary-500 dark:hover:text-primary-500" href={"mailto:" + props.email} target="_blank" rel="noopener noreferrer">
      {React.Children.count(props.children) < 1 ? props.email : props.children}
    </a>
  );
};

export default EmailLink;
