import React, { useEffect, useState } from "react";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import ListBuilder from "../../../ui/list-builder/ListBuilder";

const errorsAtom = atomWithImmer({ level1: 0, level2: 0, level3: 0 });

const DependentFieldBuilder = ({ items = [], onChange, onErrorChange }) => {
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [errorCount, setErrorCount] = useAtom(errorsAtom);

  useEffect(() => {
    setLevel2(null);
  }, [level1, setLevel2]);

  const getLevel2Items = () => {
    if (!items || !level1) return [];
    let o = items.find(i => i.id === level1.id);
    return o?.items || [];
  };

  const getLevel3Items = () => {
    let lvl2 = getLevel2Items();
    if (!lvl2 || !level2) return [];
    let o = lvl2.find(i => i.id === level2.id);
    return o?.items || [];
  };

  const handleErrorChange = (lvl, cnt) => {
    setErrorCount(d => {
      d[lvl] = cnt;
    });
    onErrorChange && onErrorChange(errorCount.level1 + errorCount.level2 + errorCount.level3);
  };

  const getCopy = () => JSON.parse(JSON.stringify(items));

  const handleChange = (list, lvl) => {
    let result = getCopy();
    let i = -1;
    switch (lvl) {
      case 1:
        onChange(list);
        break;
      case 2:
        i = result.findIndex(i => i.id === level1.id);
        if (i > -1) {
          result[i].items = list;
          onChange(result);
        }
        break;
      case 3:
        i = result.findIndex(i => i.id === level1.id);
        if (i > -1) {
          const j = result[i].items.findIndex(i => i.id === level2.id);
          if (j > -1) {
            result[i].items[j].items = list;
            onChange(result);
          }
        }
        break;
      default:
        return;
    }
  };

  return (
    <div className="flex">
      <div className="flex-1 flex flex-col ">
        <div className="pl-8">Level 1</div>
        <ListBuilder items={items} onChange={v => handleChange(v, 1)} selected={level1} onSelectionChanged={setLevel1} onErrorChange={cnt => handleErrorChange("level1", cnt)} forceUnique={false}></ListBuilder>
      </div>
      <div className="flex-1 flex flex-col">
        {level1 && (
          <div>
            <div className="pl-8">Level 2</div>
            <ListBuilder items={getLevel2Items()} onChange={v => handleChange(v, 2)} selected={level2} onSelectionChanged={setLevel2} onErrorChange={cnt => handleErrorChange("level2", cnt)} forceUnique={false}></ListBuilder>
          </div>
        )}
      </div>
      <div className="flex-1 flex flex-col">
        {level1 && level2 && (
          <div>
            <div className="pl-8">Level 3</div>
            <ListBuilder items={getLevel3Items()} onChange={v => handleChange(v, 3)} onErrorChange={cnt => handleErrorChange("level13", cnt)} forceUnique={false}></ListBuilder>
          </div>
        )}
      </div>
    </div>
  );
};

export default DependentFieldBuilder;
