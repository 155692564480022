import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "../../ui/forms/Select";
import Spinner from "../../ui/Spinner";
import Tabs, { Tab } from "../../ui/Tabs";

const Contact = () => {
  const params = useParams();
  const [loading, setloading] = useState(true);
  const [tab, setTab] = useState("overview");

  const tabs = [
    { value: "overview", name: "Overview" },
    { value: "timeline", name: "Timeline" },
    { value: "tickets", name: "Tickets" },
    { value: "notes", name: "Notes" },
  ];

  useEffect(() => {
    setloading(true);
  }, [params]);

  return (
    <React.Fragment>
      {loading && (
        <div className="flex items-center justify-center py-8 h-full w-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!loading && (
        <div className="flex bg-white dark:bg-gray-950 w-full h-full relative overflow-hidden">
          <div className="hidden lg:flex h-full relative overflow-hidden ">
            <div className="max-w-[15em] w-60 "></div>
          </div>
          <div className="flex flex-col w-full ">
            <div className="hidden lg:flex flex-none py-4 ">
              <Tabs selected={tab} onChange={e => setTab(e)}>
                {tabs.map(t => (
                  <Tab value={t.value} key={"tab-" + t.value}>
                    {t.name}
                  </Tab>
                ))}
              </Tabs>
            </div>
            <div className="flex lg:hidden items-center justify-center w-full px-4 py-2">
              <Select className="w-full" value={tab} onChange={value => setTab(value)}>
                {tabs.map(t => (
                  <option value={t.value} key={"select-" + t.value}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex overflow-auto p-2">
              <div className="h-[5000px] ">{params.id}</div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Contact;
