const colors = {
  coffee: {
    50: "hsl(40, 14%, 96%)",
    100: "hsl(30, 16%, 93%)",
    200: "hsl(30, 16%, 89%)",
    300: "hsl(30, 15%, 83%)",
    400: "hsl(32, 12%, 70%)",
    500: "hsl(31, 10%, 45%)",
    600: "hsl(26, 12%, 30%)",
    700: "hsl(24, 14%, 25%)",
    800: "hsl(22, 16%, 19%)",
    900: "hsl(18, 18%, 15%)",
    950: "hsl(18, 20%, 10%)",
  },

  stone: {
    50: "hsl(60, 9%, 96%)",
    100: "hsl(60, 5%, 93%)",
    200: "hsl(20, 6%, 89%)",
    300: "hsl(24, 6%, 83%)",
    400: "hsl(24, 5%, 70%)",
    500: "hsl(25, 5%, 45%)",
    600: "hsl(28, 5%, 25%)",
    700: "hsl(23, 6%, 19%)",
    800: "hsl(29, 5%, 16%)",
    900: "hsl(24, 5%, 12%)",
    950: "hsl(20, 14%, 9%)",
  },

  slate: {
    50: "hsl(210, 40%, 96%)",
    100: "hsl(210, 38%, 93%)",
    200: "hsl(214, 32%, 89%)",
    300: "hsl(213, 30%, 83%)",
    400: "hsl(215, 25%, 70%)",
    500: "hsl(215, 16%, 47%)",
    600: "hsl(215, 22%, 29%)",
    700: "hsl(215, 28%, 22%)",
    800: "hsl(217, 36%, 16%)",
    900: "hsl(222, 41%, 11%)",
    950: "hsl(229, 45%,9%)",
  },

  moss: {
    50: "hsl(67, 40%, 96%)",
    100: "hsl(64, 39%, 93%)",
    200: "hsl(70, 32%, 89%)",
    300: "hsl(68, 26%, 83%)",
    400: "hsl(70, 23%, 70%)",
    500: "hsl(69, 11%, 47%)",
    600: "hsl(70, 15%, 29%)",
    700: "hsl(73, 18%, 22%)",
    800: "hsl(76, 23%, 16%)",
    900: "hsl(76, 28%, 11%)",
    950: "hsl(84, 34%, 7%)",
  },

  zinc: {
    50: "hsl(207, 20%, 96%)",
    100: "hsl(207, 25%, 93%)",
    200: "hsl(207, 20%, 89%)",
    300: "hsl(207, 20%, 83%)",
    400: "hsl(207, 12%, 70%)",
    500: "hsl(207, 10%, 46%)",
    600: "hsl(207, 15%, 29%)",
    700: "hsl(207, 20%, 22%)",
    800: "hsl(207, 22%, 16%)",
    900: "hsl(207, 25%, 11%)",
    950: "hsl(207, 30%, 9%)",
  },

  neutral: {
    50: "hsl(0, 0%, 96%)",
    100: "hsl(0, 0%, 93%)",
    200: "hsl(0, 0%, 89%)",
    300: "hsl(0, 0%, 83%)",
    400: "hsl(0, 0%, 70%)",
    500: "hsl(0, 0%, 45%)",
    600: "hsl(0, 0%, 28%)",
    700: "hsl(0, 0%, 20%)",
    800: "hsl(0, 0%, 16%)",
    900: "hsl(0, 0%, 11%)",
    950: "hsl(0, 0%, 9%)",
  },

  red: {
    50: "hsl(0, 86%, 97%)",
    100: "hsl(0, 93%, 94%)",
    200: "hsl(0, 96%, 89%)",
    300: "hsl(0, 94%, 82%)",
    400: "hsl(0, 91%, 71%)",
    500: "hsl(0, 84%, 60%)",
    600: "hsl(0, 72%, 51%)",
    700: "hsl(0, 74%, 42%)",
    800: "hsl(0, 70%, 35%)",
    900: "hsl(0, 63%, 31%)",
    950: "hsl(0, 75%, 15%)",
  },

  orange: {
    50: "hsl(33, 100%, 96%)",
    100: "hsl(34, 100%, 92%)",
    200: "hsl(32, 98%, 83%)",
    300: "hsl(31, 97%, 72%)",
    400: "hsl(27, 96%, 61%)",
    500: "hsl(25, 95%, 53%)",
    600: "hsl(21, 90%, 48%)",
    700: "hsl(17, 88%, 40%)",
    800: "hsl(15, 79%, 34%)",
    900: "hsl(15, 75%, 28%)",
    950: "hsl(13, 81%, 15%)",
  },

  amber: {
    50: "hsl(48, 100%, 96%)",
    100: "hsl(48, 96%, 89%)",
    200: "hsl(48, 97%, 77%)",
    300: "hsl(46, 97%, 65%)",
    400: "hsl(43, 96%, 56%)",
    500: "hsl(38, 92%, 50%)",
    600: "hsl(32, 95%, 44%)",
    700: "hsl(26, 90%, 37%)",
    800: "hsl(23, 83%, 31%)",
    900: "hsl(22, 78%, 26%)",
    950: "hsl(21, 92%, 14%)",
  },

  yellow: {
    50: "hsl(55, 92%, 95%)",
    100: "hsl(55, 97%, 88%)",
    200: "hsl(53, 98%, 77%)",
    300: "hsl(50, 98%, 64%)",
    400: "hsl(48, 96%, 53%)",
    500: "hsl(45, 93%, 47%)",
    600: "hsl(41, 96%, 40%)",
    700: "hsl(35, 92%, 33%)",
    800: "hsl(32, 81%, 29%)",
    900: "hsl(28, 73%, 26%)",
    950: "hsl(26, 83%, 14%)",
  },

  lime: {
    50: "hsl(78, 92%, 95%)",
    100: "hsl(80, 89%, 89%)",
    200: "hsl(81, 88%, 80%)",
    300: "hsl(82, 85%, 67%)",
    400: "hsl(83, 78%, 55%)",
    500: "hsl(84, 81%, 44%)",
    600: "hsl(85, 85%, 35%)",
    700: "hsl(86, 78%, 27%)",
    800: "hsl(86, 69%, 23%)",
    900: "hsl(88, 61%, 20%)",
    950: "hsl(89, 80%, 10%)",
  },

  green: {
    50: "hsl(138, 76%, 97%)",
    100: "hsl(141, 84%, 93%)",
    200: "hsl(141, 79%, 85%)",
    300: "hsl(142, 77%, 73%)",
    400: "hsl(142, 69%, 58%)",
    500: "hsl(142, 71%, 45%)",
    600: "hsl(142, 76%, 36%)",
    700: "hsl(142, 72%, 29%)",
    800: "hsl(143, 64%, 24%)",
    900: "hsl(144, 61%, 20%)",
    950: "hsl(145, 80%, 10%)",
  },

  emerald: {
    50: "hsl(152, 81%, 96%)",
    100: "hsl(149, 80%, 90%)",
    200: "hsl(152, 76%, 80%)",
    300: "hsl(156, 72%, 67%)",
    400: "hsl(158, 64%, 52%)",
    500: "hsl(160, 84%, 39%)",
    600: "hsl(161, 94%, 30%)",
    700: "hsl(163, 94%, 24%)",
    800: "hsl(163, 88%, 20%)",
    900: "hsl(164, 86%, 16%)",
    950: "hsl(166, 91%, 9%)",
  },

  teal: {
    50: "hsl(166, 76%, 97%)",
    100: "hsl(167, 85%, 89%)",
    200: "hsl(168, 84%, 78%)",
    300: "hsl(171, 77%, 64%)",
    400: "hsl(172, 66%, 50%)",
    500: "hsl(173, 80%, 40%)",
    600: "hsl(175, 84%, 32%)",
    700: "hsl(175, 77%, 26%)",
    800: "hsl(176, 69%, 22%)",
    900: "hsl(176, 61%, 19%)",
    950: "hsl(179, 84%, 10%)",
  },

  cyan: {
    50: "hsl(183, 100%, 96%)",
    100: "hsl(185, 96%, 90%)",
    200: "hsl(186, 94%, 82%)",
    300: "hsl(187, 92%, 69%)",
    400: "hsl(188, 86%, 53%)",
    500: "hsl(189, 94%, 43%)",
    600: "hsl(192, 91%, 36%)",
    700: "hsl(193, 82%, 31%)",
    800: "hsl(194, 70%, 27%)",
    900: "hsl(196, 64%, 24%)",
    950: "hsl(197, 79%, 15%)",
  },

  sky: {
    50: "hsl(204, 100%, 97%)",
    100: "hsl(204, 94%, 94%)",
    200: "hsl(201, 94%, 86%)",
    300: "hsl(199, 95%, 74%)",
    400: "hsl(198, 93%, 60%)",
    500: "hsl(199, 89%, 48%)",
    600: "hsl(200, 98%, 39%)",
    700: "hsl(201, 96%, 32%)",
    800: "hsl(201, 90%, 27%)",
    900: "hsl(202, 80%, 24%)",
    950: "hsl(204, 80%, 16%)",
  },

  blue: {
    50: "hsl(214, 100%, 97%)",
    100: "hsl(214, 95%, 93%)",
    200: "hsl(213, 97%, 87%)",
    300: "hsl(212, 96%, 78%)",
    400: "hsl(213, 94%, 68%)",
    500: "hsl(217, 91%, 60%)",
    600: "hsl(221, 83%, 53%)",
    700: "hsl(224, 76%, 48%)",
    800: "hsl(226, 71%, 40%)",
    900: "hsl(224, 64%, 33%)",
    950: "hsl(226, 57%, 21%)",
  },

  indigo: {
    50: "hsl(226, 100%, 97%)",
    100: "hsl(226, 100%, 94%)",
    200: "hsl(228, 96%, 89%)",
    300: "hsl(230, 94%, 82%)",
    400: "hsl(234, 89%, 74%)",
    500: "hsl(239, 84%, 67%)",
    600: "hsl(243, 75%, 59%)",
    700: "hsl(245, 58%, 51%)",
    800: "hsl(244, 55%, 41%)",
    900: "hsl(242, 47%, 34%)",
    950: "hsl(244, 47%, 20%)",
  },

  violet: {
    50: "hsl(250, 100%, 98%)",
    100: "hsl(251, 91%, 95%)",
    200: "hsl(251, 95%, 92%)",
    300: "hsl(252, 95%, 85%)",
    400: "hsl(255, 92%, 76%)",
    500: "hsl(258, 90%, 66%)",
    600: "hsl(262, 83%, 58%)",
    700: "hsl(263, 70%, 50%)",
    800: "hsl(263, 69%, 42%)",
    900: "hsl(264, 67%, 35%)",
    950: "hsl(261, 73%, 23%)",
  },

  purple: {
    50: "hsl(270, 100%, 98%)",
    100: "hsl(269, 100%, 95%)",
    200: "hsl(269, 100%, 92%)",
    300: "hsl(269, 97%, 85%)",
    400: "hsl(270, 95%, 75%)",
    500: "hsl(271, 91%, 65%)",
    600: "hsl(271, 81%, 56%)",
    700: "hsl(272, 72%, 47%)",
    800: "hsl(273, 67%, 39%)",
    900: "hsl(274, 66%, 32%)",
    950: "hsl(274, 87%, 21%)",
  },

  fuchsia: {
    50: "hsl(289, 100%, 98%)",
    100: "hsl(287, 100%, 95%)",
    200: "hsl(288, 96%, 91%)",
    300: "hsl(291, 93%, 83%)",
    400: "hsl(292, 91%, 73%)",
    500: "hsl(292, 84%, 61%)",
    600: "hsl(293, 69%, 49%)",
    700: "hsl(295, 72%, 40%)",
    800: "hsl(295, 70%, 33%)",
    900: "hsl(297, 64%, 28%)",
    950: "hsl(297, 90%, 16%)",
  },

  pink: {
    50: "hsl(327, 73%, 97%)",
    100: "hsl(326, 78%, 95%)",
    200: "hsl(326, 85%, 90%)",
    300: "hsl(327, 87%, 82%)",
    400: "hsl(329, 86%, 70%)",
    500: "hsl(330, 81%, 60%)",
    600: "hsl(333, 71%, 51%)",
    700: "hsl(335, 78%, 42%)",
    800: "hsl(336, 74%, 35%)",
    900: "hsl(336, 69%, 30%)",
    950: "hsl(336, 84%, 17%)",
  },

  rose: {
    50: "hsl(356, 100%, 97%)",
    100: "hsl(356, 100%, 95%)",
    200: "hsl(353, 96%, 90%)",
    300: "hsl(353, 96%, 82%)",
    400: "hsl(351, 95%, 71%)",
    500: "hsl(350, 89%, 60%)",
    600: "hsl(347, 77%, 50%)",
    700: "hsl(345, 83%, 41%)",
    800: "hsl(343, 80%, 35%)",
    900: "hsl(342, 75%, 30%)",
    950: "hsl(343, 88%, 16%)",
  },
};

export default colors;
