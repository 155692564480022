import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "jotai";
import React from "react";
import GlobalBlocker from "./components/Global/GlobalBlocker";
import RoutesRoot from "./components/routes/RoutesRoot";
import GlobalModals from "./components/modals/GlobalModals";
import { setPageTheme } from "./components/ui/theme/theme";

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 10000 } } });

export default function Root() {
  setPageTheme();
  return (
    <Provider>
      <QueryClientProvider client={queryClient}>
        <GlobalBlocker>
          <GlobalModals></GlobalModals>
          <RoutesRoot></RoutesRoot>
        </GlobalBlocker>
      </QueryClientProvider>
    </Provider>
  );
}
