import React from "react";

const Report = () => {
  return (
    <div className="overflow-auto relative w-full h-full">
      <div className="bg-danger-500 " style={{ height: "5000px", width: "5000px" }}></div>
    </div>
  );
};

export default Report;
