class textHelper {
  static toSnakeCase = s =>
    s
      .toLowerCase()
      .replace(/\W/gm, "_") // remove any not letter, digit or _
      .replace(/_+/gm, "_") // remove ____
      .replace(/^(\d|_)+|_+$/gm, ""); //remove _# from start and _ at end
  static toKebabCase = s =>
    s
      .toLowerCase()
      .replace(/\W|_/gm, "-") // remove any not letter, digit
      .replace(/-+/gm, "-") // remove ____
      .replace(/^(\d|-)+|-+$/gm, ""); //remove _# from start and _ at end

  static toCamelCase = s => this.snakeToCamel(this.toSnakeCase(s));

  static snakeToCamel = s => s.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));
  static kebabToCamel = s => this.snakeToCamel(s);
  static camelToSnake = s => s.replace(/(?!^)([A-Z])/g, group => "_" + group.toLowerCase());
  static camelToKebab = s => s.replace(/(?!^)([A-Z])/g, group => "-" + group.toLowerCase());
  static kebabToSnake = s => s.replace(/-+/g, "_");
  static snakeToKebab = s => s.replace(/_+/g, "-");
}

export default textHelper;
