import React, { forwardRef } from "react";
import TextInput from "../forms/TextInput";
import Icon from "../Icon";

const ListItem = forwardRef(function ({ item, onChange, dragHandle, onEnter, isSelected, onSelectionChanged, onDelete, isDupe }, ref) {
  const handleKeyDown = e => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };
  const handleFocus = () => {
    onSelectionChanged && onSelectionChanged(item);
  };

  return (
    <div className="!left-auto !top-auto flex flex-col w-full">
      <div className="flex items-center group mt-2">
        <div className="flex justify-start w-4">{dragHandle}</div>
        <div className={`flex w-full pl-2 ${onSelectionChanged && "border-l-4 border-primary-500 border-opacity-0"} ${isSelected && "border-opacity-100"}`}>
          <TextInput value={item.name} onChange={v => onChange(item.id, v)} onKeyDown={handleKeyDown} onFocus={() => handleFocus(item)} ref={ref} messages={isDupe && ["Each choice must be unique."]} />
        </div>
        <div className="flex w-6 justify-end">
          <div className="group-hover:opacity-100 opacity-0 cursor-pointer text-danger-500 transition-all ease-in-out duration-500 delay-100" onClick={() => onDelete(item.id)}>
            <Icon icon="delete_outline" size={18} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ListItem;
