import React from "react";
import TabLink from "./TabLink";
import enums from "../../enums";

const ContactLink = ({ id, name, children }) => {
  return (
    <TabLink name={name} id={id} entity={enums.entityTypes.Contact} iconName="person_outline" path={"/contacts/" + id}>
      {children}
    </TabLink>
  );
};

export default ContactLink;
