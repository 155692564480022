import React from "react";
import { useAtom } from "jotai";
import ContactsListItem from "./ContactsListItem";
import { contactAtomsAtom } from "../../../state/atoms";
import Spinner from "../../ui/Spinner";
import getNewId from "../../../helpers/newid";

const ContactsList = ({ loading }) => {
  const [contactAtoms] = useAtom(contactAtomsAtom);

  return (
    <div className="text-gray-600 dark:text-gray-300 mb-8 pr-4">
      {contactAtoms.map(a => (
        <ContactsListItem contactAtom={a} key={getNewId()}></ContactsListItem>
      ))}
      {loading && (
        <div className="flex items-center justify-center py-8">
          <div className="flex-none w-16">
            <Spinner></Spinner>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactsList;
