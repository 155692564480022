import React, { useEffect, useState } from "react";
import useModalSaveCancel from "../../../../modals/useModalSaveCancel";
import Modal from "../../../../modals/Modal";
import TextInput from "../../../../ui/forms/TextInput";
import Select from "../../../../ui/forms/Select";
import Checkbox from "../../../../ui/forms/CheckBox";
import TextArea from "../../../../ui/forms/TextArea";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";

const dataAtom = atomWithImmer({ name: "", parentId: null, isPeerShare: false, description: "" });
const NewRoleModal = ({ isOpen, onClose, parentId, roles }) => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useState([]);
  const mutation = useServiceMutation(salesService.roles.insertRole, QUERY_KEY.ROLES);
  const handleSave = setSaving => {
    if (data?.name?.length < 1) {
      setErrors(["Please enter a name for the role."]);
      return;
    }

    const dupe = roles.find(r => r.name.toLowerCase() === data.name.toLowerCase());
    if (dupe) {
      setErrors(["There is already a role by the name of " + data.name]);
      return;
    }
    setErrors([]);

    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
      setData(d => {
        d.parentId = parentId;
        d.name = "";
        d.description = "";
      });
    };
    mutation.mutate(data, { onSettled: handleSaved });
  };

  const handleCancel = () => {
    setData(d => {
      d.parentId = parentId;
      d.name = "";
      d.description = "";
    });

    onClose && onClose();
  };

  useEffect(() => {
    setData(d => {
      d.parentId = parentId;
      d.name = "";
      d.description = "";
    });
  }, [parentId, setData]);

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);

  return (
    <Modal isOpen={isOpen} icon="add_circle" iconColor="primary" title={"Create a New Role"} buttons={buttons} onCloseRequest={handleCancel} wClass="lg:max-w-lg">
      <div className="flex px-4 py-4 w-full ">
        <form className="flex w-full flex-col gap-5" autoComplete="off">
          <TextInput
            label="Name"
            messages={errors}
            value={data.name}
            e
            onChange={e =>
              setData(d => {
                d.name = e;
              })
            }></TextInput>

          <Select
            label="Reports To"
            value={data.parentId}
            onChange={v =>
              setData(d => {
                d.parentId = v;
              })
            }>
            {roles &&
              roles.map(r => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ))}
          </Select>
          <Checkbox
            value={data.isPeerShare}
            onChange={v =>
              setData(d => {
                d.isPeerShare = v;
              })
            }>
            Share data with peers
          </Checkbox>
          <TextArea
            label="Description"
            value={data.description}
            onChange={v =>
              setData(d => {
                d.description = v;
              })
            }></TextArea>
        </form>
      </div>
    </Modal>
  );
};

export default NewRoleModal;
