import React, { forwardRef } from "react";
import BaseInput from "./BaseInput";

const PasswordInput = forwardRef(function ({ className, placeholder, onChange, value = "", label, description, messages, ...rest }, ref) {
  const handleChange = e => {
    onChange && onChange(e.target.value);
  };

  return (
    <BaseInput className={className} label={label} description={description} messages={messages} {...rest}>
      <input onChange={handleChange} type="password" value={value} placeholder={placeholder} {...rest} ref={ref} />
    </BaseInput>
  );
});

export default PasswordInput;
