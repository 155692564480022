import React, { forwardRef } from "react";
import BaseInput from "./BaseInput";

const DateInput = forwardRef(function ({ className, value = "", name, onChange, label, description, messages, children, ...rest }, ref) {
  return (
    <BaseInput className={className} type="text" name={name} label={label} description={description} messages={messages} {...rest}>
      <input {...rest} type="date" value={value} onChange={onChange} patternxxx="\d{4}-\d{2}-\d{2}" ref={ref}></input>
    </BaseInput>
  );
});

export default DateInput;
