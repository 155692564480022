import React, { useEffect, useState } from "react";
import useGetRoles from "../../../../../queries/useGetRoles";
import Spinner from "../../../../ui/Spinner";
import RolesTree from "./RolesTree";
import Header from "../../../../ui/Header";
import RoleDetail from "./RoleDetail";
import NewRoleModal from "./NewRoleModal";
import EditRoleModal from "./EditRoleModal";
import DeleteRoleModal from "./DeleteRoleModal";
import { focusAtom } from "jotai-optics";
import { adminAtom } from "../../../../../state/atoms";
import { useAtom } from "jotai";

const selectedAtom = focusAtom(adminAtom, (o) => o.prop("selectedRole"));

const Roles = () => {
  const { data, isLoading } = useGetRoles();
  const [list, setList] = useState([]);
  const [selected, setSelected] = useAtom(selectedAtom);
  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  useEffect(() => {
    const treeToList = (l, n) => {
      const { nodes, ...o } = n;
      l.push(o);
      if (n?.nodes) n.nodes.forEach((node) => treeToList(l, node));
    };
    let ar = [];
    if (data) treeToList(ar, data);
    setList(ar);
  }, [data, setList]);

  useEffect(() => {
    if (!selected) {
      setSelected((prev) => {
        if (!prev) return list.find((i) => i.parentId === null);
        const n = list.find((i) => i.id === prev?.id);
        if (!n) return list.find((i) => i.parentId === null);
        return n;
      });
    }
  }, [list, setSelected, selected]);

  const handleAddRole = (node) => {
    setSelected(node);
    setIsNewOpen(true);
  };

  const handleEditRole = (node) => {
    setSelected(node);
    setIsEditOpen(true);
  };

  const handleDeletRole = (node) => {
    setSelected(node);
    setIsDeleteOpen(true);
  };
  return (
    <div className="h-full">
      <Header>Roles</Header>
      {isLoading && (
        <div className="flex items-center justify-center py-8 h-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!isLoading && data && (
        <div className="flex h-full">
          <div className="flex h-full border-r dark:border-opacity-0 border-gray-300  shadow-lg py-4 px-2 overflow-auto">
            <RolesTree
              data={data}
              selected={selected}
              onChange={(n) => setSelected(n)}
              onAdd={handleAddRole}
              onDelete={handleDeletRole}
              onEdit={handleEditRole}
            ></RolesTree>
          </div>
          <div className="bg-gray-200 dark:bg-gray-800 grow h-full overflow-auto">
            <div className="flex justify-center py-8 px-8 dark:pl-0">
              <RoleDetail role={selected} rolesList={list}></RoleDetail>
            </div>
          </div>
          {selected && (
            <NewRoleModal
              isOpen={isNewOpen}
              onClose={() => setIsNewOpen(false)}
              parentId={selected.id}
              roles={list}
            />
          )}
          {selected && (
            <EditRoleModal
              isOpen={isEditOpen}
              onClose={() => setIsEditOpen(false)}
              role={selected}
              nodes={data}
              roles={list}
            />
          )}
          {selected && (
            <DeleteRoleModal
              isOpen={isDeleteOpen}
              onClose={() => setIsDeleteOpen(false)}
              role={selected}
              nodes={data}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Roles;
