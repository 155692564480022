import React, { useEffect, useState } from "react";
import useGetEntities from "../../../../queries/useGetEntities";
import Header from "../../../ui/Header";
import Button from "../../../ui/buttons/Button";
import Spinner from "../../../ui/Spinner";
import SelectableItem from "../../../ui/SelectableItem";
import Tabs, { Tab } from "../../../ui/Tabs";
import Fields from "./Fields";
import ObjectDetails from "./ObjectDetails";
import NewObjectModal from "./NewObjectModal";
import { useAtom } from "jotai";
import { adminAtom } from "../../../../state/atoms";
import { focusAtom } from "jotai-optics";

const tabs = [
  { name: "Details", id: 0 },
  { name: "Fields", id: 1 },
  { name: "Permissions", id: 2 },
  { name: "Data Sharing", id: 3 },
  { name: "Layouts", id: 4 },
];
const selectedAtom = focusAtom(adminAtom, (o) => o.prop("selectedObject"));
const tabAtom = focusAtom(adminAtom, (o) => o.prop("selectedObjectTab"));

const Objects = () => {
  const { data, isLoading } = useGetEntities();
  const [selected, setSelected] = useAtom(selectedAtom);
  const [tab, setTab] = useAtom(tabAtom);
  const [isNewOpen, setIsNewOpen] = useState(false);

  useEffect(() => {
    setSelected((prev) => {
      if (data?.length > 0) {
        if (!prev) return data[0];
        const obj = data.find((o) => o.id === prev.id);
        if (!obj) return data[0];
        return obj;
      }
      return null;
    });
  }, [data, setSelected]);

  return (
    <div className="h-full">
      <Header>
        <div className="flex">
          <div className="flex grow">Objects</div>
          <div className="flex flex-none font-normal text-base">
            <Button onClick={() => setIsNewOpen(true)}>Create Object</Button>
          </div>
        </div>
      </Header>
      {isLoading && (
        <div className="flex items-center justify-center py-8 h-full">
          <div className="h-20 w-20">
            <Spinner></Spinner>
          </div>
        </div>
      )}
      {!isLoading && data && (
        <div className="flex h-full">
          <div className="flex h-full border-r dark:border-opacity-0 border-gray-300 p-4 overflow-auto">
            <div className="flex flex-col gap-2 min-w-[12rem]">
              {data?.length > 0 &&
                data.map((g) => (
                  <SelectableItem
                    selected={selected && selected.id === g.id}
                    onClick={() => setSelected(g)}
                    key={g.id}
                  >
                    {g.name}
                  </SelectableItem>
                ))}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex pt-1 border-gray-300 dark:border-b-gray-500 dark:border-opacity-30 border-b">
              <Tabs selected={tab} onChange={(v) => setTab(v)}>
                {tabs.map((t) => (
                  <Tab key={t.id} value={t.id}>
                    {t.name}
                  </Tab>
                ))}
              </Tabs>
            </div>

            {((data.length > 0 && selected) || data.length < 1) && (
              <div className="bg-gray-200 dark:bg-gray-800 grow h-full overflow-auto">
                {selected && tab === 0 && (
                  <ObjectDetails
                    entity={selected}
                    entities={data}
                  ></ObjectDetails>
                )}
                {selected && tab === 1 && (
                  <Fields entity={selected} entities={data}></Fields>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isNewOpen && (
        <NewObjectModal
          isOpen={isNewOpen}
          onClose={() => setIsNewOpen(false)}
          entities={data}
        />
      )}
    </div>
  );
};

export default Objects;
