import React from "react";
import ItemPicker from "../ui/ItemPicker";
import useGetRoleList from "../../queries/useGetRoleList";

const RolePicker = ({ label, value, onChange, placeholder = "Pick an role.", required, description, messages, ...rest }) => {
  const { isLoading, data } = useGetRoleList();
  return (
    <React.Fragment>
      <ItemPicker pickerItems={data} label={label || "Role"} value={value} placeholder={placeholder} description={description} messages={messages} isLoading={isLoading} required={required} onChange={onChange} {...rest} />
    </React.Fragment>
  );
};

export default RolePicker;
