import React, { forwardRef } from "react";
import BaseInput from "./BaseInput";

const Select = forwardRef(function ({ className, name, value, onChange, label, description, messages, children, ...rest }, ref) {
  return (
    <BaseInput className={className} type="text" value={value} name={name} label={label} description={description} messages={messages} {...rest}>
      <select {...rest} onChange={e => onChange(e.target.value)} ref={ref}>
        {children}
      </select>
    </BaseInput>
  );
});

export default Select;
