export const QUERY_KEY = {
  ACCOUNTSEARCH: "ACCOUNTSEARCH",
  CONTACTS: "CONTACTS",
  USERS: "USERS",
  AGENTPOLICY: "AGENTPOLICY",
  AGENTROLE: "AGENTROLE",
  POLICIES: "POLICIES",
  POLICYDEFS: "POLICYDEFS",
  ENTITIES: "ENTITIES",
  ROLES: "ROLES",
  GROUPS: "GROUPS",
  RECENTITEMS: "RECENTITEMS",
  USERSETTINGS: "USERSETTINGS",
  CLIENTSETTINGS: "CLIENTSETTTINGS",
};

export const COLOR_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  DANGER: "danger",
  WARN: "warn",
  SUCCESS: "success",
  BG: "gray",
};

export const COLOR = {
  COFFEE: "coffee",
  NEUTRAL: "neutral",
  STONE: "stone",
  ZINC: "zinc",
  SLATE: "slate",
  METAL: "moss",
  RED: "red",
  ORANGE: "orange",
  AMBER: "amber",
  YELLOW: "yellow",
  LIME: "lime",
  GREEN: "green",
  EMERALD: "emerald",
  TEAL: "teal",
  CYAN: "cyan",
  SKY: "sky",
  BLUE: "blue",
  INDIGO: "indigo",
  VIOLET: "violet",
  PURPLE: "purple",
  FUCHSIA: "fuchsia",
  PINK: "pink",
  ROSE: "rose",
};

export const STALETIME = {
  VERYLONG: 28800000, // 8 hours
  LONG: 3600000, // 1 hour
  MEDIUM: 1800000, // 30 min
  SHORT: 300000, // 5 min
  NONE: 0,
};

export const STORAGE_KEY = {
  DATACENTER: "dc",
  ACCESSTOKEN: "at",
  THEME: "theme",
  THEMEMODE: "mode",
  TABS: "tabs",
};

export const USER_SETTINGS = {
  THEME: "theme",
  ENTITIES: "entities",
};

export const CLIENT_SETTINGS = {
  DEFAULT_THEME: "default_theme",
  NAV_ITEM_ORDER: "nav_item_order",
};
