import { useAtom } from "jotai";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { tabsAtom } from "../../state/atoms";
import Icon from "../ui/Icon";
import AddTabMenu from "./AddTabMenu";
import PageTab from "./PageTab";
import { STORAGE_KEY } from "../../constants";
import MenuList, { MenuListItem } from "../ui/menu/MenuList";

const PageTabs = () => {
  const [mainTab, setMainTab] = useState({
    title: "",
    path: "",
    exactMatch: false,
  });
  const [showMore, setShowMore] = useState(false);
  const [tabs, setTabs] = useAtom(tabsAtom);
  const tabsRef = useRef();
  let location = useLocation();

  useLayoutEffect(() => {
    const ref = tabsRef.current;

    const adjustTabVis = (clientWidth, tabCount) => {
      const size = 175;
      let total = tabCount * size + 64; //add button
      let over = Math.ceil((total - clientWidth) / size);
      setShowMore(over > 0);
      setTabs(d => {
        for (let i = 0; i < d.length; i++) {
          const hidden = i >= d.length - over;
          if (d[i].hide !== hidden) d[i].hide = hidden;
        }
      });
    };

    const handleResize = (ref, clientWidth) => {
      const items = ref.querySelectorAll("a.tab");
      adjustTabVis(clientWidth, items.length);
    };

    const observer = new ResizeObserver(entries => {
      const rect = entries[0].contentRect;
      handleResize(ref, rect.width);
    });
    observer.observe(ref);

    return () => {
      observer.unobserve(ref);
    };
  }, [tabs, setTabs]);

  useEffect(() => {
    let segs = location.pathname.split("/");
    let tab = { title: "", path: "", exactMatch: false };

    if (segs.length > 1) {
      let tabName = segs[1].toLowerCase();
      tab.path = "/" + tabName;
      if (tabName.length < 1) {
        tabName = "Dashboard";
      }
      tab.title = tabName.charAt(0).toUpperCase() + tabName.slice(1);
      tab.exactMatch = tabName === "contacts" || tabName === "accounts";
      if (!tab.exactMatch || segs.length === 2) setMainTab(tab);
    }
  }, [location]);

  useEffect(() => {
    if (tabs) localStorage[STORAGE_KEY.TABS] = JSON.stringify(tabs);
  }, [tabs]);

  const getMainTabIcon = path => {
    switch (path.toLowerCase()) {
      case "/":
        return "dashboard";
      case "/contacts":
        return "contact";
      case "/accounts":
        return "business";
      case "/leads":
        return "filter_list";
      case "/opportunities":
        return "attach_money";
      case "/teams":
        return "team";
      case "/users":
        return "agent";
      case "/reports":
        return "reports";
      case "/setup":
        return "admin";
      default:
        return "pen";
    }
  };

  // const MoreTabs = () => {
  //   const navigate = useNavigate();
  //   const handleOpen = (e, path) => {
  //     e.preventDefault();
  //     setTabs(d => {
  //       const index = d.findIndex(t => t.path === path);
  //       if (index !== -1) {
  //         const temp = d[index];
  //         d.splice(index, 1);
  //         d.unshift(temp);
  //       }
  //     });
  //     navigate(path);
  //   };
  //
  //   const handleClose = (e, path) => {
  //     e.preventDefault();
  //     setTabs(d => {
  //       const index = d.findIndex(t => t.path === path);
  //       if (index !== -1) d.splice(index, 1);
  //     });
  //   };
  //
  //   return (
  //     <li className="py-2">
  //       {tabs &&
  //         tabs
  //           .filter(t => t.hide)
  //           .map(t => (
  //             <div className="flex items-center text-sm py-1 px-5 hover:text-primary-500 hover:bg-primary-100 dark:hover:text-primary-500 dark:hover:bg-primary-500 dark:hover:bg-opacity-10  transition-all ease-in-out duration-300 hover:cursor-pointer" onClick={e => handleOpen(e, t.path)}>
  //               <div className="flex-none h-6 w-6">
  //                 <Icon icon={t.iconName}></Icon>
  //               </div>
  //               <div className="grow pl-2">{t.name}</div>
  //               <div className="flex-none inline-flex h-4 w-4 rounded-full  hover:bg-primary-300 hover:text-primary-100 dark:hover:bg-primary-500 dark:hover:bg-opacity-10 dark:hover:text-primary-500  transition-all ease-in-out duration-300" onClick={e => handleClose(e, t.path)}>
  //                 <Icon icon="clear"></Icon>
  //               </div>
  //             </div>
  //           ))}
  //     </li>
  //   );
  // };

  return (
    <div ref={tabsRef} className=" hidden h-12 lg:flex ">
      <PageTab main to={mainTab.path} iconName={getMainTabIcon(mainTab.path)} exactMatch={mainTab.exactMatch}>
        {mainTab.title}
      </PageTab>
      {tabs.map(t => (
        <PageTab key={t.path} to={t.path} iconName={t.iconName} hide={t.hide}>
          {t.name}
        </PageTab>
      ))}
      <div className="flex border-b border-gray-300 dark:border-opacity-0 pl-2">
        <MenuList
          className={"flex flex-col h-full items-center justify-center px-3 w-16 cursor-pointer text-xs" + (showMore ? "" : " hidden")}
          renderItem={
            <div className="flex items-center justify-end">
              <span>More</span>
              <Icon icon="expand_more" size={24}></Icon>
            </div>
          }>
          <MenuListItem>Apple</MenuListItem>
          <MenuListItem>Blueberry</MenuListItem>
          <MenuListItem>Watermelon</MenuListItem>
          <MenuListItem>Banana</MenuListItem>
        </MenuList>
        {/* <a href="/#" onClick={toggleMoreMenuClick} className={"inline-flex flex-none h-full items-center justify-center px-3 text-xs w-16 " + (showMore ? "" : " hidden")}>
          More<Icon icon="expand_more" size={24}></Icon>
        </a>
        {moreMenuOpen && (
          <Menu onClick={toggleMoreMenuClick} className="-right-12 top-full w-80">
            <MoreTabs />
          </Menu>
        )} */}
      </div>
      <div className="grow flex items-center relative border-b border-gray-300 dark:border-opacity-0">
        <AddTabMenu
          renderItem={
            <div className="flex h-full items-center justify-center px-3 text-xs w-16">
              <Icon icon="add" size={24} />Add
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PageTabs;
