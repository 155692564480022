import React, { useEffect, useState } from "react";
import useModalSaveCancel from "../../../../modals/useModalSaveCancel";
import Modal from "../../../../modals/Modal";
import TextInput from "../../../../ui/forms/TextInput";
import TextArea from "../../../../ui/forms/TextArea";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";

const dataAtom = atomWithImmer({ id: -1, name: "", description: "" });
const EditGroupModal = ({ isOpen, onClose, group, groups }) => {
  const [data, setData] = useAtom(dataAtom);
  const [errors, setErrors] = useState([]);
  const mutation = useServiceMutation(salesService.groups.updateGroup, QUERY_KEY.GROUPS);

  const handleSave = setSaving => {
    if (data?.name?.length < 1) {
      setErrors(["Please enter a name for the group."]);
      return;
    }
    const dupe = groups.find(g => g.name.toLowerCase() === data.name.toLowerCase() && g.id !== group.id);
    if (dupe) {
      setErrors(["There is already a group by the name of " + data.name]);
      return;
    }
    setErrors([]);

    setSaving(true);
    const handleSaved = () => {
      setSaving(false);
      onClose && onClose();
    };
    mutation.mutate(data, { onSettled: handleSaved });
  };
  const handleCancel = () => {
    onClose && onClose();
  };

  useEffect(() => {
    setData(d => {
      d.name = group.name;
      d.description = group.description;
      d.id = group.id;
    });
  }, [group, setData]);

  const { buttons } = useModalSaveCancel(handleSave, handleCancel);

  return (
    <React.Fragment>
      {group && (
        <Modal isOpen={isOpen} icon="create" iconColor="primary" title={"Edit " + group.name} buttons={buttons} onCloseRequest={handleCancel} wClass="lg:max-w-lg">
          <div className="flex px-4 py-4 w-full ">
            <form className="flex w-full flex-col gap-5" autoComplete="off">
              <TextInput
                label="Name"
                value={data.name}
                messages={errors}
                onChange={e =>
                  setData(d => {
                    d.name = e;
                  })
                }></TextInput>
              <TextArea
                label="Description"
                value={data.description}
                onChange={v =>
                  setData(d => {
                    d.description = v;
                  })
                }></TextArea>
            </form>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default EditGroupModal;
