import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "../constants";
import salesService from "../services/salesService";

const fetch = async () => {
  return await salesService.roles.getRoleList();
};

export default function useGetRoleList() {
  const queryKey = [QUERY_KEY.ROLES];
  const queryFn = () => fetch();

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
  });
}
