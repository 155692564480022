import { useSetAtom } from "jotai";
import React, { useCallback, useEffect, useRef, useState } from "react";
import fetchData from "../../services/fetchData";

const InfiniteScroll = ({ url, pageSize, itemsAtom, filter, children, totalAtom }) => {
  const abortController = useRef(null);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null);
  const container = useRef(null);
  const fetching = useRef(false);
  const eod = useRef(false);
  const page = useRef(0);
  const filterRef = useRef(null);
  const firstRun = useRef(true);
  const setItems = useSetAtom(itemsAtom);
  const setTotal = useSetAtom(totalAtom);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const getData = useCallback(async () => {
    if (page.current === undefined || eod.current !== false) return;

    if (fetching.current !== false && abortController.current !== null) {
      abortController.current.abort();
      abortController.current = new AbortController();
    }

    if (!abortController.current) abortController.current = new AbortController();

    fetching.current = true;
    try {
      const signal = abortController.current.signal;
      const res = await fetchData(url, { page: page.current, pageSize, filter: filterRef.current }, "POST", signal);
      if (res?.data) setTotal(res.data.total);

      if (res?.data?.items) {
        if (res.data.items.length < pageSize) eod.current = true;
        if (page.current === 0) setItems(s => res.data.items);
        else
          setItems(s => {
            let arr = [...s, ...res.data.items];
            return arr;
          });
      }
    } finally {
      setLoading(false);
      fetching.current = false;
    }
  }, [pageSize, url, setItems, setTotal]);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    eod.current = false;
    page.current = 0;
    container.current.scrollTop = 0;
    filterRef.current = filter;
    setItems(s => []);
    setLoading(true);
    getData();
  }, [getData, filter, pageSize, url, setItems]);

  const handleObserver = useCallback(
    entries => {
      if (eod.current === true || fetching.current === true) return;
      if (entries[0].isIntersecting) {
        page.current++;
        setLoading(true);
        getData();
      }
    },
    [getData]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    });
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <div className="overflow-auto relative h-full snap-y snap-mandatory " ref={container}>
      {React.cloneElement(children, { loading: loading })}
      <div className="h-16 w-full mb-10" ref={loader} />
    </div>
  );
};

export default InfiniteScroll;
