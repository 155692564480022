import React, { useCallback, useEffect } from "react";
import { useBeforeUnload, unstable_useBlocker as useBlocker } from "react-router-dom";
import { useAtom } from "jotai";
import { blockerAtom } from "../../state/atoms";

//https://stackoverflow.com/questions/75135147/react-router-dom-v6-useblocker
const GlobalBlocker = ({ children }) => {
  const [message, setMessage] = useAtom(blockerAtom);

  const handleBlockAlert = useCallback(() => {
    if (message) {
      if (!window.confirm(message)) return true;
      setMessage("");
    }
    return false;
  }, [message, setMessage]);

  const blocker = useBlocker(handleBlockAlert);
  //const blocker = useBlocker(useCallback(() => (message ? !window.confirm(message) : false), [message]));

  useEffect(() => {
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      event => {
        if (message) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message]
    ),
    { capture: true }
  );

  return <React.Fragment>{children}</React.Fragment>;
};

export default GlobalBlocker;
