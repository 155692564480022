import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY } from "../constants";
import salesService from "../services/salesService";

const fetch = async params => {
  return await salesService.accounts.searchAccount(params);
};

export default function useSearchAccount(query, limit) {
  const queryKey = [QUERY_KEY.ACCOUNTSEARCH, query, limit];
  const queryFn = () => fetch({ query, limit });

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime: 30000,
  });
}
