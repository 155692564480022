import React from "react";
import useServiceMutation from "../../../../../mutations/useServiceMutation";
import salesService from "../../../../../services/salesService";
import { QUERY_KEY } from "../../../../../constants";
import DeleteModal from "../../../../modals/DeleteModal";

const DeleteGroupModal = ({ isOpen, onClose, group }) => {
  const mutation = useServiceMutation(salesService.groups.deleteGroup, QUERY_KEY.GROUPS);

  const handleDelete = result => {
    if (!result) {
      onClose();
      return;
    }
    result.setIsLoading(true);
    const handleDeleted = () => {
      result.setIsLoading(false);
      onClose && onClose();
    };
    mutation.mutate(group, { onSettled: handleDeleted });
  };

  return (
    <React.Fragment>
      {group && (
        <DeleteModal isOpen={isOpen} onClose={handleDelete} title={"Delete " + group.name}>
          <div className="flex px-4 py-4 w-full ">Are you sure you would like to delete the group "{group.name}"?</div>
        </DeleteModal>
      )}
    </React.Fragment>
  );
};

export default DeleteGroupModal;
