import React, { useEffect, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import iconSet from "../ui/icons.json";
import Icon from "../ui/Icon";
import TextInput from "../ui/forms/TextInput";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/ToolTip";

const FontModal = ({ isOpen, onClose, title = "Pick a font" }) => {
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    setSelected(null);
    setQuery("");
  }, [setSelected, setQuery]);

  const getIcon = icon => {
    return (
      <Tooltip key={icon.properties.name}>
        <TooltipTrigger>
          <div className={`flex flex-col items-center justify-center border p-2 rounded   ${selected === icon.properties.name ? "bg-primary-500 border-white text-white dark:bg-primary-500 dark:border-white dark:text-white" : "hover:text-primary-500  hover:border-primary-500 border-gray-400 text-gray-500 dark:hover:text-primary-500 hover:cursor-pointer dark:hover:border-primary-500 dark:border-gray-600"}`} onClick={() => setSelected(icon.properties.name)}>
            <Icon size={20} icon={icon.properties.name}></Icon>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <div>{icon.properties.name.replace(/_/g, " ")} </div>
          {icon.icon?.tags && icon.icon?.tags.map(t => (t !== icon.properties.name ? <div key={t + icon.properties.name}>{t.replace(/_/g, " ")}</div> : null))}
        </TooltipContent>
      </Tooltip>
    );
  };

  const iconIsMatch = icon => {
    const checkName = name => name.toLowerCase().includes(query.toLowerCase());
    if (checkName(icon.properties.name)) return true;
    if (icon.icon?.tags) {
      icon.icon?.tags.forEach(tag => {
        if (checkName(tag)) return true;
      });
    }
    return false;
  };

  return (
    <ConfirmModal isOpen={isOpen} title={title} icon="insert_emoticon" onClose={v => onClose(v ? selected : false)} affirmativeText="Ok" negativeText="Cancel" containerClass="h-full overflow-hidden relative" wClass="" hClass="">
      <div className="flex flex-col gap-4 h-full px-2 py-2 ">
        <div className="flex">
          <TextInput placeholder="Enter a description to find a font..." value={query} onChange={q => setQuery(q)}></TextInput>
        </div>
        <div className="overflow-auto h-full">
          <div className="flex flex-wrap gap-2">{iconSet.icons.filter(i => iconIsMatch(i)).map(i => getIcon(i))}</div>
        </div>
      </div>
    </ConfirmModal>
  );
};

export default FontModal;
