import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY, STALETIME } from "../constants";
import salesService from "../services/salesService";

const fetch = async params => {
  return await salesService.clientSettings.getSetting(params);
};

export default function useGetClientSetting(key) {
  const queryKey = [QUERY_KEY.CLIENTSETTINGS, key];
  const queryFn = () => fetch({ key });

  return useQuery({
    queryKey,
    queryFn,
    enabled: true,
    staleTime: STALETIME.VERYLONG,
  });
}
