import { Menu, MenuItem } from "../ui/menu/Menu";
import useGetNavItems from "../../queries/useGetNavItems";
import React, { useState } from "react";
import enums from "../../enums";
import ObjectModal from "../forms/ObjectModal";

const AddTabMenu = ({ renderItem, ...rest }) => {
  const { navItems } = useGetNavItems();
  const [entityId, setEntityId] = useState(-1);

 

  const handleClick = (entityId) => {
    setEntityId(entityId);
  };

  const handleClose = () => {
    setEntityId(-1);
  };
  return (
    <>
      <Menu renderItem={renderItem} {...rest}>
        <Menu icon="history" label="Recent Items">
          <MenuItem label="ooo" />
          <MenuItem label="Video" />
          <MenuItem label="Audio" />
        </Menu>
        {navItems &&
          navItems
            .filter((i) => i.type === enums.moduleTypes.Object)
            .map((i) => (
              <MenuItem
                key={i.id}
                icon={i.icon}
                iconColor={i.color}
                label={i.title}
                onClick={() => handleClick(i.id)}
              />
            ))}
      </Menu>
      
      {entityId > -1 && (
        <ObjectModal
          isOpen={entityId}
          onClose={handleClose}
          isNew
          entityId={entityId}
          
        />
      )}
    </>
  );
};

export default AddTabMenu;
