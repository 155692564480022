import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../../ui/buttons/Button";
import fetchData from "../../../services/fetchData";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  let email = searchParams.get("email");
  let userId = searchParams.get("id");

  const clickHandler = e => {
    e.preventDefault();
    setLoading(true);
    fetchData(`https://${process.env.REACT_APP_AUTH_URL}/auth/send-password-reset`, { userId, email })
      .then(res => setData(res.data))
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col ">
      <div className="text-xl pb-10 text-center">Account recovery</div>
      <div className="pb-10">Press the recover button below, and we will send you an email with a reset link.</div>
      <div className="flex justify-between items-center">
        <div>
          <Link to="/auth/login">
            <Button outline loading={false} className="">
              Login
            </Button>
          </Link>
        </div>
        <div>
          {data && <div className="text-primary-500 font-medium">Email Sent</div>}
          {!data && (
            <div>
              <Button loading={loading} loadingText="Sending email..." onClick={clickHandler} className="">
                Recover
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
