import Icon from "../../../ui/Icon";
import enums, { fieldTypeProps } from "../../../../enums";
import FieldDetails from "./FieldDetails";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../ui/ToolTip";

const Field = ({ isSelected, showArchived, field, dragHandle, onSelect, onEdit, onArchive }) => {
  const getIcon = (icon, onClick, toolTip) => (
    <Tooltip>
      <TooltipTrigger>
        <div
          className="flex items-center p-1 dark:border-gray-600 border-gray-400 hover:border-primary-300 text-gray-500 border -my-1 cursor-pointer rounded-full dark:text-gray-500 hover:text-primary-500 dark:hover:text-primary-300 dark:hover:border-primary-300 hover:bg-primary-500 dark:hover:bg-opacity-10 dark:hover:bg-primary-500  hover:bg-opacity-30  transition-all duration-400  ease-in-out"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
          }}>
          <Icon size={16} icon={icon}></Icon>
        </div>
      </TooltipTrigger>
      <TooltipContent>{toolTip}</TooltipContent>
    </Tooltip>
  );
  const isSection = field.fieldType === enums.fieldTypes.Section;

  return (
    <div className={`flex flex-col select-none ${isSection && !isSelected ? "text-2xl font-thin border-b" : "border rounded-lg"} bg-white dark:bg-gray-900  my-1 w-full ${!showArchived && field.trash && "hidden"} ${isSelected ? "ring-1 ring-primary-500 bg-opacity-0 dark:bg-opacity-0 ring-opacity-50 dark:ring-opacity-75 border-opacity-70 dark:shadow-none shadow-2xl  backdrop-blur shadow-gray-400  border-primary-500 " : field.trash ? "border-warn-500" : "dark:border-gray-700 border-gray-300"}`}>
      <div className={`group flex items-center py-2 px-2 rounded-t-lg ${isSelected && " dark:bg-gray-800 bg-gray-200"} cursor-pointer`} onClick={onSelect}>
        <div className="flex items-center">{dragHandle}</div>
        {!isSection && (
          <div className="flex items-center">
            <Icon className={`mr-2`} icon={fieldTypeProps.get(field.fieldType)?.icon || "layers"} size={18} />
          </div>
        )}
        <div className={`flex grow items-center ${field.isReadonly && "italic"}`}>
          {isSection && "Section:"} {field.label} {field.isRequired && <span className="pl-1 text-danger-500">*</span>}
        </div>
        <div className={`flex gap-3 ${isSelected || "invisible"} group-hover:visible`}>
          {getIcon("create", () => onEdit(field), "Edit " + field.label)}
          {!field.isSystem && getIcon(field.trash ? "unarchive" : "archive1", () => onArchive(field), (field.trash ? "Unarchive " : "Archive ") + field.label)}
        </div>
      </div>
      {isSelected && (
        <div className="p-4 select-text overflow-auto">
          <FieldDetails field={field} />
        </div>
      )}
    </div>
  );
};

export default Field;
