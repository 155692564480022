import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../ui/buttons/Button";
import PasswordInput from "../../ui/forms/PasswordInput";
import fetchData from "../../../services/fetchData";

const Password = ({ resultHandler, account }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [message, setMessage] = useState("");

  let navigate = useNavigate();

  const onClick = e => {
    e.preventDefault();

    if (!account) {
      navigate("/auth/login");
      return;
    }

    setLoading(true);
    fetchData(`https://${process.env.REACT_APP_AUTH_URL}/auth/authenticate`, { userId: account.userId, password })
      .then(res => setData(res.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (data && data.result === 1) {
      setMessage("Invalid password");
      return;
    } else if (data) {
      resultHandler(data);
    }
  }, [data, resultHandler]);

  return (
    <React.Fragment>
      <div className="pb-3 text-xl items-center w-full text-center">Please enter your password for</div>
      <div className="pb-3 items-center w-full text-center">{account ? account.name : ""}</div>
      <div className="pb-8 items-center w-full text-center">to continue to HighFive Crm</div>
      <div className="pb-8 w-full">
        <div>
          <PasswordInput autoFocus danger={message} className="py-3" placeholder="Enter your password" onChange={e => setPassword(e)} value={password}></PasswordInput>
          {message && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <Link to={`/auth/login/forgot-password?id=${account.userId}&email=${encodeURI(account.email)}`}>
            <Button link>Forgot password?</Button>
          </Link>
        </div>
        <div>
          {" "}
          <Button loading={loading} loadingText="Verifying..." onClick={onClick} className="">
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Password;
