import React from "react";
import getNewId from "../../../helpers/newid";

const Toggle = ({ className, color = "tertiary", onChange, label, ...rest }) => {
  const id = getNewId();

  return (
    <div className="flex items-center">
      <label htmlFor={id} className="inline-flex relative items-center cursor-pointer">
        <input type="checkbox" value="" id={id} className="sr-only peer" onChange={e => onChange(e)} {...rest} />
        <div className={`w-11 h-6 bg-gray-300 rounded-full peer peer-focus:ring-4 peer-focus:ring-${color}-500 peer-focus:ring-opacity-50 dark:peer-focus:ring-${color}-500 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-${color}-500`}></div>
        <span className="ml-3 text-sm font-medium text-gray-950 dark:text-gray-400">{label}</span>
      </label>
    </div>
  );
};

export default Toggle;
