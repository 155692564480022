import React, { useState } from "react";
import Button from "../../ui/buttons/Button";
import TextInput from "../../ui/forms/TextInput";
import fetchData from "../../../services/fetchData";

const Email = ({ accountsHandler }) => {
  const [email, setEmail] = useState("@highfivecrm.com");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (email.trim() === "") {
      setMessage("Please enter your email address");
      return;
    }
    setLoading(true);
    fetchData(`https://${process.env.REACT_APP_AUTH_URL}/clients/user-clients?email=${encodeURI(email)}`, null, "GET")
      .then(res => {
        if (res?.data?.length > 0) accountsHandler(res.data);
        else setMessage("We could not find your email address.");
      })
      .catch(() => setMessage("There was an error looking up your email address."))
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <div className="pb-3 text-2xl items-center w-full text-center">Sign in</div>
      <div className="pb-8 text items-center w-full text-center">to continue to HighFive Crm</div>
      <div className="pb-8 w-full">
        <TextInput className="py-3" autoFocus danger={message && !loading ? true : false} placeholder="Email" onChange={value => setEmail(value)} value={email}></TextInput>
        {message && !loading && <div className="text-danger-500 pt-2 pl-2">{message}</div>}
      </div>
      <div className="flex justify-between items-center">
        <div>
          <a className="text-primary-500 font-medium" target="_blank" rel="noreferrer" href="https://highfivecrm.com/signup">
            Create account
          </a>
        </div>
        <div>
          {" "}
          <Button onClick={handleSubmit} loading={loading} loadingText="Looking up account..." className="">
            Next
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Email;
