import React from "react";
import Divider from "../../../../ui/Divider";
import Icon from "../../../../ui/Icon";
import Panel from "../../../../ui/Panel";

const RoleDetail = ({ role, rolesList }) => {
  const getParentRoleName = () => {
    if (!role && rolesList) return null;
    const r = rolesList.find(r => r.id === role.parentId);
    if (r?.name) return r.name;
    return null;
  };

  const parentRoleName = getParentRoleName();

  return (
    <Panel>
      <div className="flex flex-col">
        {role && (
          <div className="flex flex-col px-4 w-full">
            <Divider className="py-4 px-4">
              <div className="flex items-center gap-4">
                <Icon className="rounded-xl p-1 text-white bg-primary-500 dark:bg-gray-500 dark:bg-opacity-50" icon="person_outline" size={40}></Icon>
                <div className="flex flex-col">
                  <div className="flex font-semibold items-center">{role.name}</div>
                  {role.description && <div className="flex text-sm items-center">{role.description}</div>}
                </div>
              </div>
            </Divider>
            <div className="flex flex-col gap-2 px-4 py-4 w-full">
              <div className="flex gap-2">
                Reports to: <div className="flex font-semibold">{parentRoleName || "No parent"}</div>
              </div>
              <div className="flex gap-2">
                Data shared with peers: <div className="flex font-semibold">{role.isPeerShare ? "Yes" : "No"}</div>
              </div>
              <div className="flex w-full gap-8 justify-center pt-2">
                <div className="flex flex-col w-full">
                  <Divider>Users</Divider>
                </div>
                <div className="flex flex-col w-full">
                  <Divider>Groups</Divider>
                </div>
              </div>
            </div>
          </div>
        )}
        {!role && <div className="p-12">Please select a role</div>}
      </div>
    </Panel>
  );
};

export default RoleDetail;
