import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../modals/Modal";
import TextInput from "../../../ui/forms/TextInput";
import FieldSelect from "./FieldSelect";
import Checkbox from "../../../ui/forms/CheckBox";
import { atomWithImmer } from "jotai-immer";
import { useAtom } from "jotai";
import enums, { fieldTypeProps } from "../../../../enums";
import Button from "../../../ui/buttons/Button";
import NumberInput from "../../../ui/forms/NumberInput";
import ListBuilder from "../../../ui/list-builder/ListBuilder";
import DependentFieldBuilder from "./DependentFieldBuilder";
import Select from "../../../ui/forms/Select";
import Divider from "../../../ui/Divider";
import useServiceMutation from "../../../../mutations/useServiceMutation";
import salesService from "../../../../services/salesService";
import { QUERY_KEY } from "../../../../constants";
import textHelper from "../../../../helpers/textHelper";

const initValue = {
  id: 0,
  fieldType: 0,
  isRequired: false,
  isReadonly: false,
  apiName: "",
  name: "",
  label: "",
  placeholder: "",
  description: "",
  data: {},
};

const dataAtom = atomWithImmer(initValue);
const errorsAtom = atomWithImmer({});

const FieldModal = ({ isOpen, isNew = false, isSection = false, field, fields, onClose, entityId, entities = [] }) => {
  const [data, setData] = useAtom(dataAtom);
  const [isSaving, setIsSaving] = useState(false);
  const [dataErrorCount, setDataErrorCount] = useState(0);
  const [errors, setErrors] = useAtom(errorsAtom);
  const updateMutation = useServiceMutation(salesService.entities.updateField, QUERY_KEY.ENTITIES);
  const insertMutation = useServiceMutation(salesService.entities.insertField, QUERY_KEY.ENTITIES);
  const handleClose = () => !isSaving && onClose && onClose();

  const handleSave = keepOpen => {
    const handleSaved = (data, error, variables, context) => {
      setIsSaving(false);
      if (error) return;
      if (!keepOpen) handleClose();
      setData(initValue);
    };
    if (checkFieldErrors(data, isNew, true)) return;
    setIsSaving(true);
    if (isNew) {
      insertMutation.mutate(data, { onSettled: handleSaved });
    } else {
      updateMutation.mutate(data, { onSettled: handleSaved });
    }
  };
  const buttons = [<Button key="cancel" disabled={isSaving} outline onClick={handleClose} children="Cancel" />];
  if (isNew) {
    buttons.push(
      <Button key="savenew" disabled={isSaving} outline onClick={() => handleSave(true)}>
        Save and New
      </Button>
    );
  }
  buttons.push(
    <Button key="save" loading={isSaving} loadingText="Saving..." onClick={() => handleSave(false)}>
      Save
    </Button>
  );

  const checkFieldErrors = useCallback(
    (f, n, final) => {
      const findDupes = (field, isNew, final) => {
        if (!field) return;
        const findLabel = (label, apiName, final) => {
          if (!label) return false;
          const l = label.toLowerCase();
          for (let i = 0; i < fields.length; i++) {
            const f = fields[i];
            if (!f.trash) {
              if (f.apiName !== apiName) {
                if (f.label.toLowerCase() === l) return true;
                if (f.data?.label2?.toLowerCase() === l) return true;
                if (f.data?.label3?.toLowerCase() === l) return true;
              } else if (final) {
                let cnt = 0;
                if (f.label.toLowerCase() === l) cnt++;
                if (f.data?.label2?.toLowerCase() === l) cnt++;
                if (f.data?.label3?.toLowerCase() === l) cnt++;
                if (cnt > 1) return true;
              }
            }
          }
          return false;
        };

        let result = { apiName: [], label: [], label2: [], label3: [] };

        if (field.fieldType === enums.fieldTypes.Section) {
          if (final && !field.label) result.label.push("You must specify a section name.");
          const sections = fields.filter(f => f.fieldType === enums.fieldTypes.Section && f.id !== field.id && f.label.toLowerCase() === field.label.toLowerCase());
          if (sections.length > 0) result.label.push("This section name already exists.");
        } else {
          if (final && !field.apiName) result.apiName.push("You must have specify a unique API Name.");
          if (isNew && field.apiName && fields.findIndex(f => f.apiName.toLowerCase() === field.apiName.toLowerCase()) > -1) result.apiName.push("This field name already exists.  It must be unique.");

          if (final && !field.label) result.label.push("You must specify a Label.");
          if (findLabel(field.label, field.apiName, final)) result.label.push("This Label already exists.");

          if (field.fieldType === enums.fieldTypes.DependantField) {
            if (final && !field.data?.label2) result.label2.push("You must specify a Label for level 2.");
            if (findLabel(field.data?.label2, field.apiName, final)) result.label2.push("This Label already exists.");
            if (findLabel(field.data?.label3, field.apiName, final)) result.label3.push("This Label already exists.");
          }

          if (field.fieldType === enums.fieldTypes.Lookup) {
            if (final && !field.data?.lookupId) result.lookupId = ["You must specify a lookup object."];
            if (final && !field.data?.relName) result.relName = ["You must specify a relationship name"];
          }
        }
        return result;
      };

      const dupeErrors = findDupes(f, n, final);
      let count = 0;
      setErrors(d => {
        for (const key in dupeErrors) {
          d[key] = dupeErrors[key];
          count += dupeErrors[key].length;
        }
      });
      return count + dataErrorCount;
    },
    [dataErrorCount, fields, setErrors]
  );

  useEffect(() => {
    setErrors({});
    checkFieldErrors(data, isNew);
  }, [data, isNew, setErrors, dataErrorCount, fields, checkFieldErrors]);

  useEffect(() => {
    setData(() => {
      return field ? { ...field } : { ...initValue };
    });
  }, [isOpen, field, setData]);

  const handleFieldChange = (k, v) => {
    const keys = k.includes(".") && k.split(".");
    const dataKey = keys[1];
    setData(d => {
      if (!d.data) d.data = {};
      if (!d.entityId) d.entityId = entityId;
      if (!d.pos) d.pos = fields.reduce((a, b) => (a > b ? a : b)).pos + 1;
      if (isSection) {
        d.fieldType = enums.fieldTypes.Section;
        d.apiName = "SYSTEM_SECTION_NAME";
        d.name = textHelper.snakeToCamel(d.apiName);
      }

      if (!keys) {
        if (k === "fieldType") d.data = {};
        if (k === "apiName" || (k === "label" && isNew && d.apiName === textHelper.toSnakeCase(d.label))) {
          d.apiName = textHelper.toSnakeCase(v);
          d.name = textHelper.snakeToCamel(d.apiName);
        }
        d[k] = v;
        return;
      }
      d.data[dataKey] = v;
    });
  };

  const getNumberSettings = () => {
    return (
      <React.Fragment>
        <div className="flex flex-col gap-4">
          <Checkbox onChange={e => handleFieldChange("data.isDecimal", e)} value={data.data?.isDecimal || false}>
            Allow Decimals
          </Checkbox>
          <NumberInput isDecimal={data.data?.isDecimal} label="Minimum Value" value={data.data?.min || ""} onChange={e => handleFieldChange("data.min", e)}></NumberInput>
          <NumberInput isDecimal={data.data?.isDecimal} label="Maximum Value" value={data.data?.max || ""} onChange={e => handleFieldChange("data.max", e)}></NumberInput>
        </div>
      </React.Fragment>
    );
  };

  const getCheckboxSettings = () => {
    return (
      <React.Fragment>
        <div className="flex flex-col gap-4">
          <Checkbox onChange={v => handleFieldChange("data.defaultValue", v)} value={data?.data?.defaultValue || false}>
            The default value of the checkbox.
          </Checkbox>
        </div>
      </React.Fragment>
    );
  };

  const getUrlSettings = () => {
    return (
      <React.Fragment>
        <div className="flex flex-col gap-4">
          <Checkbox onChange={e => handleFieldChange("data.requireSecure", e)} value={data.data?.requireSecure || false}>
            Require the URL to be secure. <span className="font-thin italic ml-3 dark:text-gray-500">"Requires https://"</span>
          </Checkbox>
        </div>
      </React.Fragment>
    );
  };

  const getLookupSettings = () => {
    return (
      <div className="grid grid-cols-2 gap-3">
        <Select messages={errors.lookupId} required label="Object to Lookup" value={data.data.lookupId || -1} onChange={v => handleFieldChange("data.lookupId", v)}>
          <option className="hidden" disabled value={-1}></option>
          {entities.map(e => (
            <option key={e.id} value={e.id}>
              {e.name}
            </option>
          ))}
        </Select>
        <TextInput messages={errors.relName} required label="Relationship Name" value={data.data.relName} onChange={v => handleFieldChange("data.relName", v)}></TextInput>
      </div>
    );
  };
  const getListNextId = () => {
    const id = data?.data?.id || 1;
    handleFieldChange("data.data.nextId", id + 1);
    return id;
  };

  const getDataInput = type => {
    let result = null;
    switch (type) {
      case enums.fieldTypes.DropDown:
        result = <ListBuilder items={data.data.items} onChange={v => handleFieldChange("data.items", v)} onErrorChange={count => setDataErrorCount(count)} getNextId={getListNextId} />;
        break;
      case enums.fieldTypes.DependantField:
        result = (
          <div className="col-span-3">
            <DependentFieldBuilder items={data.data.items} onChange={v => handleFieldChange("data.items", v)} onErrorChange={count => setDataErrorCount(count)} />
          </div>
        );
        break;
      case enums.fieldTypes.Number:
        result = getNumberSettings();
        break;
      case enums.fieldTypes.Checkbox:
        result = getCheckboxSettings();
        break;
      case enums.fieldTypes.Url:
        result = getUrlSettings();
        break;
      case enums.fieldTypes.Lookup:
        result = getLookupSettings();
        break;
      default:
        result = null;
    }
    return result ? (
      <div className="col-span-3">
        <Divider>{fieldTypeProps.get(type).title + " Properties"}</Divider>
        <div className="my-4">{result}</div>
      </div>
    ) : null;
  };

  const getIsDependant = () => data.fieldType === enums.fieldTypes.DependantField;
  const hasPlaceholder = () => data.fieldType !== enums.fieldTypes.Checkbox && data.fieldType !== enums.fieldTypes.DependantField;

  return (
    <Modal isOpen={isOpen} icon={isNew ? "add_circle" : "create"} iconColor="primary" title={isNew ? (isSection ? "Create a New Section" : "Create a New Field") : `Edit ${data.label}`} buttons={buttons} onCloseRequest={handleClose} wClass={isSection ? "lg:max-w-lg" : "lg:max-w-7xl"}>
      <div className="flex px-2 py-2 w-full">
        {!isSection && (
          <form className="grid grid-cols-3 grid-flow-row-dense w-full gap-3" autoComplete="off">
            <TextInput required messages={errors.label} value={data.label} onChange={v => handleFieldChange("label", v)} label={getIsDependant() ? "Label 1" : "Label"}></TextInput>
            {getIsDependant() && <TextInput messages={errors.label2} required value={data.data.label2} onChange={v => handleFieldChange("data.label2", v)} label="Label 2"></TextInput>}
            {getIsDependant() && <TextInput messages={errors.label3} value={data.data.label3} onChange={v => handleFieldChange("data.label3", v)} label="Label 3"></TextInput>}
            {isNew && <TextInput messages={errors.apiName} required value={data.apiName} onChange={v => handleFieldChange("apiName", v)} label="API Name"></TextInput>}
            {isNew && <FieldSelect required value={data.fieldType} onChange={v => handleFieldChange("fieldType", v)}></FieldSelect>}
            {hasPlaceholder() && <TextInput value={data.placeholder} onChange={v => handleFieldChange("placeholder", v)} label="Placeholder" placeholder="Enter your placeholder here."></TextInput>}
            <div className="flex col-span-2">
              <TextInput description={data.description || "Your description will appear here."} value={data.description} onChange={v => handleFieldChange("description", v)} label="Description"></TextInput>
            </div>
            {!data.isSystem && data.fieldType !== enums.fieldTypes.Checkbox && (
              <div className="flex col-span-1 items-end pb-1">
                <Checkbox value={data.isRequired} onChange={v => handleFieldChange("isRequired", v)}>
                  Required
                </Checkbox>
              </div>
            )}
            {getDataInput(data.fieldType)}
          </form>
        )}
        {isSection && (
          <form className="w-full" autoComplete="off">
            <TextInput required messages={errors.label} value={data.label} onChange={v => handleFieldChange("label", v)} label="Section Label"></TextInput>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default FieldModal;
