import React from "react";
import Divider from "./Divider";

const Header = ({ children, className }) => {
  return (
    <Divider
      className={`${
        className ||
        "px-4 py-2 text-3xl font-thin dark:text-gray-400 bg-white dark:bg-gray-800"
      }`}
    >
      <div>{children}</div>
    </Divider>
  );
};

export default Header;
