import React, { useState } from "react";
import Divider from "../../../ui/Divider";
import Button from "../../../ui/buttons/Button";
import enums from "../../../../enums";
import EditObjectModal from "./EditObjectModal";
import DeleteObjectModal from "./DeleteObjectModal";
import HeadingIcon from "../../../ui/HeadingIcon";
import Panel from "../../../ui/Panel";

const ObjectDetails = ({ entity, entities }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <div className="flex justify-center py-8 px-8 dark:pl-0">
      <Panel>
        <div className="flex flex-col">
          {entity && (
            <div className="flex flex-col px-4 w-full">
              <Divider>
                <div className="flex items-center gap-4 pb-2 pt-4">
                  <HeadingIcon icon={entity.iconName} color={entity.color} />
                  <div className="flex flex-col grow">
                    <div className="flex items-center">{entity.name}</div>
                    {entity.description && <div className="flex text-sm items-center">{entity.description}</div>}
                  </div>
                  <div className="flex gap-3 flex-none text-base font-normal">
                    {entity.entityType === enums.entityTypes.Object && (
                      <Button onClick={() => setIsDeleteOpen(true)} outline={true} color="danger">
                        Delete
                      </Button>
                    )}
                    <Button onClick={() => setIsEditOpen(true)}>Edit</Button>
                  </div>
                </div>
              </Divider>
              <div className="flex flex-col gap-2 px-4 pt-4 pb-6 w-full">
                <div className="flex gap-2">
                  Name: <div className="flex font-semibold">{entity.name}</div>
                </div>
                <div className="flex gap-2">
                  Plural Name: <div className="flex font-semibold">{entity.plural}</div>
                </div>
                <div className="flex gap-2">
                  API Name: <div className="flex font-semibold">{entity.apiName}</div>
                </div>
                <div className="flex gap-2">
                  Object Type: <div className="flex font-semibold">{entity.entityType === enums.entityTypes.Object ? "Custom" : "System"}</div>
                </div>
              </div>
              {isEditOpen && <EditObjectModal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} entity={entity} entities={entities} />}
              {isDeleteOpen && <DeleteObjectModal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} entity={entity} />}
            </div>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default ObjectDetails;
